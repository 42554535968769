import * as babel from "@babel/core";
import { Card, Col, Layout, Row, Typography, Button, Collapse, Form, InputNumber, Input, Space, Radio, Divider, Skeleton, message } from "antd";
import { AxiosResponse } from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import { School } from "./StudentPaymentsPDFTable";
import i18n from 'i18next';

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

export const PaymentHelp = (props: any) => {
    const { t } = useTranslation();
    const [deposit, setDeposit] = useState(null as any);
    const [student, setStudent] = useState(null as any);
    const [loaded, setLoaded] = useState(false);
    const [paymentType, setPaymentType] = useState("");
    const [paymentOps, setPaymentOps] = useState({} as any);
    const history = useHistory();
    const [form] = Form.useForm();
    const [bank, setBank] = useState("");
    const [school, setSchool] = useState<School>({
        name: null,
        registration_nr: null,
        legal_address: null,
        bank: null,
        swift: null,
        bank_account: null
    });
    const [giftcard, setGiftcard] = useState("");
    const [loading, setLoading] = useState(false);

    const getRequisites = () => {
        http.get(`global-settings-by-scope/requisites`).then((res: AxiosResponse) => {
            console.log(res.data.data)
            const { data } = res.data;
            setSchool((prev: School): School => {
                const copy = { ...prev };
                Object.keys(copy).forEach(key => {
                    copy[key] = data.find((item: any) => {
                        return item.key === key
                    })?.value ?? null
                })
                return copy
            })
        })
    }

    useEffect(() => {
        getRequisites();
        const p1 = http.get('self')
            .then((result: any) => { setStudent(result.data.data) });

        const p2 = http.get(`/payment-methods/LV`, { params: { language: i18n.language } })
            .then((result: any) => { setPaymentOps(result.data) });

        Promise.all([p1, p2])
            .then(() => { setLoaded(true) });
    }, [i18n.language]);

    const AddVal = () => {
        const buttons = []
        for (let i = 25; i <= 200; i += 25) {
            i === 175 ? i = 200 : i = i;
            buttons.push(<Button onClick={() => setDeposit(deposit + i)} key={i} value={i}>+{i}</Button>)
        }

        return <span className="add-value-container">
            {buttons}
        </span>;
    }

    const BankImg = (props: any) => {
        return (
            <div className="bank-ic">
                <img src={props.imgLink} alt="" />
            </div>
        )
    }

    const submitGiftCard = () => {
        setLoading(true);
        http.post(`get-giftcard`, { code: giftcard }).then((res: AxiosResponse) => {
            setGiftcard("");
            if (res.data.data) {
                props.refreshBalance();
                message.success("Dāvanu karte ir aktivizēta")
            }
            setLoading(false);
        }).catch((err: any) => {
            setGiftcard("");
            message.error(err.response.data.message ?? "Kļūda")
            setLoading(false);
        });
    }

    useEffect(() => {
        form.setFieldsValue({
            deposit: deposit,
        })
    }, [deposit])

    useEffect(() => {
        setBank("");
    }, [paymentType])

    const submitPayment = () => {
        form.validateFields().then((value: any) => {
            const values =
            {
                amount: value.deposit,
                payment_method: paymentType !== "bank_payment" ? paymentType : bank,
                payment_language: "lv"
            }
            //console.log(values);
            http.post(`/payment-create/${student.id}`, values).then((response) => {
                window.location.replace(response.data);
                //console.log(response);
            });
        })
    }

    return (
        <Layout className="main-content">
            <Card style={{ marginBottom: 24 }}>
                <Row gutter={20} justify="space-between">
                    <Col>
                        <Title level={3} style={{ marginLeft: 30, marginBottom: 0 }}>
                            {t('payments.payment')}
                        </Title>
                    </Col>
                    <Col>
                        <Link to={`/planner`}><Button type="primary">{t('payments.return')}</Button></Link>
                    </Col>
                </Row>
            </Card>
            {loaded ?
                <Row>
                    <Col className="payment-col" xl={16}>
                        <Card style={{ marginBottom: 20 }} title={<Title level={5}>{t('payments.account_replenishment')}</Title>}>
                            {/* <Button onClick={() => console.log()}>!</Button> */}
                            <Row>
                                {/* <Button onClick={() => console.log(paymentOps)}>!</Button> */}
                                <Form
                                    form={form}
                                    layout="horizontal"
                                    style={{ width: "100%" }}
                                >
                                    <p>{t('payments.specify_ammount_for_balance')} *</p>
                                    <Form.Item
                                        style={{ marginBottom: 0, display: "inline-block" }}
                                        name="deposit"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('payments.input_ammount'),
                                            },
                                        ]}
                                        initialValue={deposit}
                                    >
                                        <Input
                                            className="money-input"
                                            type="number"
                                            style={{ maxWidth: 200, marginRight: 20 }}
                                            min={0.00}
                                            step={0.01}
                                            addonAfter="EUR"
                                            placeholder="0.00"
                                            onChange={(value: any) => setDeposit(Number(value.target.value))}
                                        />
                                    </Form.Item>
                                    <AddVal />
                                    <Divider style={{ marginTop: 9 }} />
                                    {paymentType === "bank_payment"
                                        ? <Card title={t('payments.choose_bank')}
                                            style={{ marginBottom: 24 }}
                                        >
                                            <Radio.Group style={{ width: "100%" }} value={bank}>
                                                <Row justify="space-between" gutter={20} >
                                                    {Object.keys(paymentOps).filter((key: any) => {
                                                        return paymentOps[key].bank;
                                                    }).map((key: any) => {
                                                        if (paymentOps[key].bank) {
                                                            return (<Col md={6} span={24}>
                                                                <Radio.Button
                                                                    className="bank-btn"
                                                                    value={key}
                                                                    onChange={(event: any) => setBank(event.target.value)}
                                                                >
                                                                    <div style={{ height: "100%", width: "100%" }}>
                                                                        <img style={{ height: "100%", width: "100%", objectFit: "contain" }} src={paymentOps[key].appendix[0]} alt={paymentOps[key].title} />
                                                                    </div>
                                                                </Radio.Button>
                                                            </Col>)
                                                        }
                                                    })}
                                                </Row>
                                            </Radio.Group>
                                        </Card>
                                        : <></>}
                                    <p style={{ marginBottom: 10 }}>{t('payments.how_you_want_to_pay')} *</p>
                                    <Radio.Group className="payment-radio" value={paymentType}>
                                        {Object.keys(paymentOps).filter((key: any) => {
                                            return paymentOps[key].bank;
                                        }).length > 0
                                            ? <Radio.Button
                                                value="bank_payment"
                                                onChange={(value: any) => setPaymentType(value.target.value)}
                                            >
                                                <Row justify="space-between">
                                                    <Col>
                                                        {t('payments.online_payment')}
                                                    </Col>
                                                    <Col style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                        <Row align="middle">
                                                            {Object.keys(paymentOps).filter((key: any) => {
                                                                return paymentOps[key].bank;
                                                            }).map((bank: any) => {
                                                                return <BankImg imgLink={paymentOps[bank].appendix[0]} />
                                                            })}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Radio.Button>
                                            : <></>
                                        }
                                        {Object.keys(paymentOps).filter((key: any) => {
                                            return !paymentOps[key].bank;
                                        }).map((bank: any) => {
                                            return <Radio.Button
                                                value={bank}
                                                onChange={(value: any) => setPaymentType(value.target.value)}
                                            >
                                                <Row justify="space-between">
                                                    <Col>
                                                        {paymentOps[bank].title}
                                                    </Col>
                                                    <Col style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                        <Row align="middle">
                                                            <BankImg imgLink={paymentOps[bank].appendix[0]} />
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Text style={{ fontWeight: "normal", color: "#575757", lineHeight: .22, fontSize: 14 }}>
                                                    {paymentOps[bank].subtitle}
                                                </Text>
                                            </Radio.Button>
                                        })}
                                    </Radio.Group>
                                    <Divider />
                                    <Button
                                        className="custom-btn"
                                        onClick={submitPayment} type="primary"
                                        style={{ width: "100%" }}
                                        disabled={paymentType !== "" && deposit > 0 && paymentType !== "bank_payment" && paymentType !== "klix_pay_later"
                                            ? false : paymentType === "bank_payment" && bank !== "" && deposit > 0
                                                ? false : paymentType === "klix_pay_later" && deposit >= 300
                                                    ? false : true
                                        }
                                    >
                                        {t('payments.continue')}
                                    </Button>
                                </Form>
                            </Row>
                        </Card>
                        <Collapse style={{ marginBottom: 20 }} onChange={() => setPaymentType("")}>
                            <Panel header={<Title style={{ margin: 0 }} level={5}>{t('payments.account_replenishment_bank_transfer')}</Title>} key="2">
                                <Card>
                                    <Row gutter={20}>
                                        <Col span={24}>
                                            {/* <Title level={5}>{t('payments.hello')}</Title>
                                            <Paragraph>
                                                {t('payments.to_start_practical_you_need_pay')} <Text strong>{t('payments.deposit')}</Text>{" "} {t('payments.to_this_requisites')}:
                                            </Paragraph>
                                            <Paragraph>
                                                {t('payments.info.level_1')}
                                                {t('payments.info.level_2')}
                                            </Paragraph>
                                            <Paragraph>
                                                <Text strong>{t('payments.info.level_3')}</Text><br />
                                                {t('payments.info.level_4')}<br />
                                                <Text strong>{t('payments.example')} </Text><Text type="danger" strong>{t('payments.info.level_5')}</Text>
                                            </Paragraph>
                                            <Text type="danger" strong>
                                                {t('payments.attention')}<br />
                                            </Text>
                                            <Paragraph underline>
                                                {t('payments.pay_attention')}{" "}
                                                <Text type="danger" strong>{t('payments.account_number')}</Text>
                                                {t('payments.info.level_6')}
                                            </Paragraph> */}
                                            {/* <Title level={5}>Sveicināti!</Title>
                                            <Paragraph>
                                                Lai pieteiktos uz praktisko apmācību, Jums vispirms ir nepieciešams iemaksāt <Text strong>DEPOZĪTU</Text>{" "} uz zemāk norādītajiem rekvizītiem.
                                            </Paragraph>
                                            <Paragraph>
                                                Viena akadēmiskā stunda notiek 45 min. Viena nodarbība notiek 90 min. (2 x 45 min.).
                                                Rekomendējam apmaksu veikt par tik nodarbībām, cik plānojat pieteikt uz priekšu (attiecīgi, cik liela summa būs iemaksāta/atlikusi Jūsu praktiskās apmācības kontā, tik nodarbības varēsiet saplānot).
                                            </Paragraph>
                                            <Paragraph>
                                                <Text strong>Veicot maksājumu PIEZĪMĒS norādiet sekojošu informāciju -</Text><br />
                                                (Depozīts par Jūsu Vārds Uzvārds, personas kods, kategorija, izvēlētā instruktora vārds, pilsēta, kurā veiksiet praktisko apmācību).<br />
                                                <Text strong>Piemērs: </Text><Text type="danger" strong>Depozīts par  Vārds Uzvārds, 000000-11111, B kategorija (instruktora vārds), Rīga</Text>
                                            </Paragraph>
                                            <Text type="danger" strong>
                                                ! UZMANĪBU !<br />
                                            </Text>
                                            <Paragraph underline>
                                                Pievērsiet uzmanību{" "}
                                                <Text type="danger" strong>konta numuram</Text>, jo tas
                                                atšķiras no konta, kurā veicāt maksājumu par
                                                teorijas apmācību. Ja pārskaitījums tiks veikts uz
                                                citu kontu, tad tā summa netiks atzīta par spēkā
                                                esošu!
                                            </Paragraph> */}
                                            <div dangerouslySetInnerHTML={{ __html: t('payments.info.full_info_text') }}>


                                            </div>
                                            <Card
                                                size="small"
                                                title={t('payments.payment_need_to_send_to_requisites') + ":"}
                                                style={{ display: "inline-block" }}
                                            >
                                                <p>{school.name}</p>
                                                <p>{t('payments.requisites.registration_nr')}.: {school.registration_nr}</p>
                                                <p>
                                                    {t('payments.requisites.legal_address')}: {school.legal_address}
                                                </p>
                                                <p>{t('payments.requisites.bank')}: {school.bank}</p>
                                                <p>{t('payments.requisites.swift')}: {school.swift}</p>
                                                <p>{t('payments.requisites.account_nr')}.: <span style={{ fontSize: "20px", color: "red", fontWeight: "bold" }}>{school.bank_account}</span></p>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card>
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col style={{}} className="deposit-info-col" xl={8}>
                        <Card style={{ borderTop: "2px solid #079DC1", marginLeft: 20 }}>
                            <Row>
                                <Col>
                                    <Title level={5}>{t('payments.hi')}, {student?.first_name} {student?.last_name}</Title>
                                    <small>{t('payments.your_account_balance')}</small>
                                    <Title level={3}>{props.balance} EUR</Title>
                                    {/* <Button onClick={() => console.log(props)}>!</Button> */}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    {
                        !!!student?.has_scholarship
                        && <Col span={24}>
                            <Card
                                title="Izmantot dāvanu karti"
                            >
                                <p><b>Ievadiet dāvanu kartes kodu</b></p>
                                <Input
                                    placeholder="Kods"
                                    style={{ width: 300 }}
                                    value={giftcard}
                                    onChange={(event: any) => setGiftcard(event.target.value)}
                                />
                                <Button
                                    type="primary"
                                    style={{ marginLeft: 20 }}
                                    disabled={giftcard.length > 0 ? false : true}
                                    onClick={submitGiftCard}
                                    loading={loading}
                                >
                                    Aktivizēt
                                </Button>
                            </Card>
                        </Col>
                    }
                </Row>
                : <><Skeleton /></>}
        </Layout>
    );
};
