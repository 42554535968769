import { Button, Checkbox, Col, Divider, message, Modal, Row, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as IcCalendar } from "../../../img/ic-calendar-blue.svg";
import { ReactComponent as IcClock } from "../../../img/ic-clock-blue.svg";
import { ReactComponent as IcEuro } from "../../../img/ic-euro-circle-blue.svg";
import { ReactComponent as IcApply } from "../../../img/ic-apply-blue.svg";
import moment from "moment";
import { http } from "../../../helpers/http";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { AxiosError, AxiosResponse } from "axios";
import { BonusAdjustForm } from "../../Payment/BonusAdjustForm";

const styles = {
    amountText: {
        fontSize: 17,
        fontWeight: 700,
    },
    numberInputBtn: {
        width: 38,
        height: 38,
        fontSize: 22,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#079DC1",
    }
}

export const SignupModal = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const {
        cancel,
        modalVisible,
        setModalVisible,
        slot,
        price,
        tryNr,
        cancelHours
    } = props;
    const [confirmation, setConfirmation] = useState({
        havePmp: false,
        haveMedical: false,
        allDataTrue: false
    } as any);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [bonusAmount, setBonusAmount] = useState("0");

    const MiniInfoCard = (props: any) => {
        const { Icon, text, data } = props;
        return <Row style={{ padding: "16px 20px", backgroundColor: "#F0F2F5", borderRadius: 2 }}>
            <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Icon />
            </Col>
            <Col style={{ paddingLeft: 12 }}>
                <p style={{ marginBottom: 0 }}>{text}</p>
                <b>{data}</b>
            </Col>
        </Row>
    }

    const resetModal = () => {
        setModalVisible(false);
        setLoading(false);
    };

    const signupForExam = () => {
        setLoading(true);
        http.post(`check-allowed-theory-exam-by-driving`).then((res: AxiosResponse) => {
            const { allowed, msg } = res.data;
            if (allowed) {
                http.post(`buy-theory-exam-by-datetime`, { exam_date: slot?.exam_date, time_from: slot?.time_from, bonus_amount: Number(bonusAmount) > 0 ? Number(bonusAmount) : undefined }).then((response: any) => {
                    if (response?.data?.msg?.includes("balance")) {
                        message.error("Nepietiek līdzekļu!");
                        history.push('/planner/payment-help');
                        return;
                    }
                    //console.log(response)
                    setError("")
                    resetModal();
                    history.push('/planner');
                }).catch((err: any) => {
                    setLoading(false);
                    errorMessageResolver(err)
                    const { message: msg } = err.response.data
                    const translatedMSG = msg?.includes("Not old enough to purchase!")
                        ? t('errors.not_old_enough')
                        : msg?.includes("Already purchased")
                            ? t('errors.already_purchased')
                            : msg?.includes("Not allowed because of balance on medicine!")
                                ? t('errors.balance_or_medicine')
                                : msg
                    message.error(translatedMSG)
                })
            } else {
                Modal.info({
                    title: t('errors.error'),
                    content: msg
                })
            }
        }).catch((err: AxiosError) => {
            message.error("Kļūda!")
        })
    }

    const errorMessageResolver = (err: any) => {
        const { data, status } = err.response;
        if (status === 403) {
            if (data?.length > 0) {
                setError(`${t(`errors.${data[0].message_type}`)} ${data[0].info} ${t(`errors.error_activities.${data[0].reason}`)}`);
            }
        } else {
            setError("")
        }
    }

    useEffect(() => {
        const unconfirmed = Object.keys(confirmation).filter((key: any) => {
            return !confirmation[key]
        });
        if (unconfirmed.length > 0) {
            setAllowSubmit(false);
        } else {
            setAllowSubmit(true);
        }
    }, [confirmation])

    return <Modal
        title={t('theory_exams.attend_for_theory_exam')}
        visible={modalVisible}
        onOk={resetModal}
        onCancel={resetModal}
        footer={
            <Row>
                <Col span={12}>
                    <Button
                        style={{ width: "100%", marginRight: 6 }}
                        onClick={resetModal}
                    >
                        {t('common.cancel')}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        style={{ width: "100%", marginLeft: 6 }}
                        type="primary"
                        onClick={signupForExam}
                        disabled={!allowSubmit}
                        loading={loading}
                    >
                        {t('theory_exams.approve_and_attend')}
                    </Button>
                </Col>
            </Row>
        }
        width={700}
    >
        <Row gutter={[12, 12]} style={{ marginBottom: 18 }}>
            <Col xs={24} lg={12}>
                <MiniInfoCard
                    Icon={IcCalendar}
                    text={t('theory_exams.choosen_day')}
                    data={slot?.exam_date}
                />
            </Col>
            <Col xs={24} lg={12}>
                <MiniInfoCard
                    Icon={IcApply}
                    text={t('theory_exams.your_try')}
                    data={tryNr}
                />
            </Col>
            <Col xs={24} lg={12}>
                <MiniInfoCard
                    Icon={IcClock}
                    text={t('theory_exams.choosen_time')}
                    data={
                        moment(slot?.time_from, "HH:mm:ss").format("HH:mm")
                        + " - "
                        + moment(slot?.time_to, "HH:mm:ss").format("HH:mm")
                    }
                />
            </Col>
            <Col xs={24} lg={12}>
                <MiniInfoCard
                    Icon={IcEuro}
                    text={t('theory_exams.every_retried_exam_price')}
                    data={<>{price}&euro;</>}
                />
            </Col>
        </Row>
        <Row style={{ marginBottom: 14 }}>
            <Col span={24}>
                <h3 style={{ borderBottom: "1px solid #D9D9D9" }}>{t('theory_exams.exam_process_what_to_expect')}</h3>
                <p>{t('theory_exams.exam_process_description')}</p>
                <p>{t('theory_exams.exam_cancel_rules')}</p>
            </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
            <Divider style={{ marginTop: 0 }} />
            {
                cancel
                    ? <Space>
                        <Button type="text" style={{ color: "red" }}>{t('theory_exams.cancel_exam')}</Button>
                        <p style={{ margin: 0 }}>{t('theory_exams.exam_cancel_is_free')}</p>
                    </Space>
                    : <Col span={24}>
                        <h3 style={{ borderBottom: "1px solid #D9D9D9" }}>{t('theory_exams.im_consent_with')}</h3>
                        <div style={{ marginBottom: 9 }}>
                            <Checkbox
                                style={{ marginRight: 10 }}
                                checked={confirmation.haveMedical}
                                onChange={(e: any) => {
                                    setConfirmation((prev: any) => {
                                        return {
                                            ...prev,
                                            haveMedical: e.target.checked
                                        }
                                    })
                                }}
                            />
                            <span>{t('theory_exams.i_have_medical_inspection')} *</span>
                        </div>
                        <div style={{ marginBottom: 9 }}>
                            <Checkbox
                                style={{ marginRight: 10 }}
                                checked={confirmation.havePmp}
                                onChange={(e: any) => {
                                    setConfirmation((prev: any) => {
                                        return {
                                            ...prev,
                                            havePmp: e.target.checked
                                        }
                                    })
                                }}
                            />
                            <span>{t('theory_exams.i_have_pmp')} *</span>
                        </div>
                        <div style={{ marginBottom: 9 }}>
                            <Checkbox
                                style={{ marginRight: 10 }}
                                checked={confirmation.allDataTrue}
                                onChange={(e: any) => {
                                    setConfirmation((prev: any) => {
                                        return {
                                            ...prev,
                                            allDataTrue: e.target.checked
                                        }
                                    })
                                }}
                            />
                            <span>{t('theory_exams.info_is_true')} *</span>
                        </div>
                    </Col>
            }
            <Col span={24}>
                <h3>
                    {
                        Number(bonusAmount) > 0
                        && <s style={{ ...styles.amountText, marginRight: 10 }}>{Number(price).toFixed(2)}</s>
                    }
                    <span style={{ ...styles.amountText, color: "#079DC1" }}>{(price - Number(bonusAmount)).toFixed(2)}</span>
                </h3>
            </Col>
            <BonusAdjustForm
                amount={price}
                type="bonus_school_exam"
                setBonusAmount={setBonusAmount}
                bonusAmount={bonusAmount}
                student={props.student}
            />
            {
                i18n.language !== "lv"
                    ? <Tag color="red" style={{ whiteSpace: "normal", width: "100%", textAlign: "center" }}>
                        {t('theory_exams.warning_about_csdd_exam_lang')}
                    </Tag>
                    : <></>
            }
            {
                error.length > 0
                && <Tag color="red" style={{ marginTop: 10, whiteSpace: "normal", width: "100%", textAlign: "center" }}>
                    {error}
                </Tag>
            }
        </Row>
    </Modal>
}