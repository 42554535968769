import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom";
import arrowLeft from "../../../img/ic-left-arrow-circle.svg";
import arrowRight from "../../../img/ic-right-arrow-circle.svg";
import i18n from 'i18next';
import { http } from "../../../helpers/http";

export const ChapterNavigation = (props: any) => {
    const { t } = useTranslation();
    const { chapter, previous, next, disabled, exam, category } = props;

    const history = useHistory();

    const goTo = async () => {
        if (disabled) return;
        const url = (chapter === undefined
            ? '/e-learning'
            : exam
                ? `/e-learning/exam/${chapter?.module_hash_id}`
                : `/e-learning/${category}/${chapter?.module_hash_id}/${chapter?.hash_id}`)
        if (chapter === undefined) {
            await http.post(`/set-passed-module/${props.module.hash_id}`, { skip: 1 })
        }
        history.push(url);
        if (!exam) window.location.reload();
    }

    useEffect(() => {
        console.log(chapter)
    }, [chapter]);

    return <div
        className={`chapter-navigation
    ${disabled ? 'disabled' : ''}
    ${next ? 'right' : ''}
    `}
        onClick={goTo}
    >
        <div className={`title-wrap`}>
            {
                previous
                    ? <div style={{ width: 20, height: 20, position: "absolute", top: -1, left: 0 }}>
                        <img src={arrowLeft} style={{ width: "100%", height: "100%" }} />
                    </div>
                    : <></>
            }
            <b>
                {
                    chapter === undefined
                        ? t("common.finish")
                        : exam
                            ? t('e_learning.final_test')
                            : <>
                                {
                                    previous
                                        ? `${t('e_learning.previous')} `
                                        : `${t('e_learning.next')} `
                                }
                                {t('e_learning.lesson')}
                            </>
                }
            </b>
            {
                next
                    ? <div style={{ width: 20, height: 20, position: "absolute", top: -1, right: 0 }}>
                        <img src={arrowRight} style={{ width: "100%", height: "100%" }} />
                    </div>
                    : <></>
            }
        </div>
        {!exam && <p>{chapter?.[`description_${i18n.language}`]}</p>}
    </div >
}