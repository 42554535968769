import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const EighthStep = (props: any) => {
    const { t } = useTranslation();
    const baseUrl = process.env.REACT_APP_API_URL;

    return <div>
        <h1>{t('tutorial.step_eight.school_driving_exam')}</h1>
        <p>
        {t('tutorial.step_eight.school_driving_exam_explanation')}
        </p>
        <div style={{ width: "100%" }}>
            <img src={baseUrl + "storage/student-tutorial/driving-exam-btn-view-tutorial.png"} style={{ width: "100%" }} />
        </div>
    </div>
}