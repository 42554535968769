import { Card, Row, Col, Button, Space, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { ServicePaymentModal } from "../Payment/ServicePaymentModal";
import { AxiosError } from "axios";

const { confirm } = Modal;

export const Notification = (props: any) => {
    const { notification, deleteFromList, student, refetch, refreshBalance } = props;
    const [loading, setLoading] = useState(false);
    const [paymentModalVisible, setPaymentModalVisible] = useState(false);

    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        //console.log(notification)
    }, []);

    const checkRead = (withReload: boolean = false) => {
        if (props.custom) {
            http.get(`set-read-custom-notification/${notification.id}`)
            refreshBalance();
            deleteFromList(notification.id, true);
            setLoading(false);
            return;
        }
        http.post('notification-read', { id: notification.id }).then((response: any) => {
            refreshBalance();
            if (withReload) {
                // window.location.reload();
            }
            //console.log(response);
            deleteFromList(notification.id)
            setLoading(false);
        });
    }

    const cancelAttendance = (type: 'foreign' | 'service') => {
        confirm({
            title: t('notification.cancel'),
            icon: <ExclamationCircleOutlined />,
            content: type === 'foreign' ? t('notification.cancel_theory_lesson') : t('notification.cancel_service'),
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: () => {
                const student_id = notification.notifiable_id;
                const splitTheType = notification.data.type.split('_');
                const lesson_id = Number(splitTheType[splitTheType.length - 1])
                type === 'foreign'
                    ? http.post(`/student-study-group-cancel-attendance/${student_id}`, { id: lesson_id })
                        .then((response: any) => {
                            refreshBalance();
                            //console.log(response)
                            refetch();
                            checkRead()
                        }).catch(() => {
                            message.error(t('errors.error'))
                        })
                    : notification?.data?.status === "register_payment"
                        ? http.post(`/student-remove-study-group-registration/${student_id}/${lesson_id}`)
                            .then((response: any) => {
                                refreshBalance();
                                checkRead(true)
                            }).catch((error: any) => {
                                message.error(t('errors.error'))
                            })
                        : http.post(`/student-study-group-services/cancel-service/${student_id}/${lesson_id}`, { id: lesson_id })
                            .then((response: any) => {
                                refreshBalance();
                                //console.log(response)
                                checkRead()
                            }).catch(() => {
                                message.error(t('errors.error'))
                            })
            }
        });
    };

    const acceptAttendance = (type: 'foreign' | 'service', bonus: number | undefined = undefined, group: number | undefined = undefined) => {
        confirm({
            title: t('notification.confirm_payment'),
            icon: <ExclamationCircleOutlined />,
            content: type === 'foreign'
                ? (<>
                    <p style={{ marginBottom: 5 }}>{t('notification.pay_additional_theory_lesson')}</p>
                    <small>{t('notification.if_not_accepted_no_lesson')}</small>
                </>)
                : (t('notification.pay_for_service')),
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: () => {
                const student_id = notification.notifiable_id;
                const splitTheType = notification.data.type.split('_');
                const lesson_id = Number(splitTheType[splitTheType.length - 1]);
                setLoading(true);
                type === 'foreign'
                    ? http.post(`/student-study-group-approve-attendance/${student_id}`, {
                        id: lesson_id,
                        bonus_amount: bonus,
                        bonus_type: notification.bonus_type
                    })
                        .then((response: any) => {
                            refreshBalance();
                            if (response.data.status === "error") {
                                message.error(t('common.not_enough_balance'))
                                if (response.data.msg === "Not enough balance.") {
                                    history.push('/planner/payment-help')
                                }
                            } else {
                                message.success(t('notification.lesson_is_paid'))
                                checkRead(true)
                            }
                        }).catch((error: any) => {
                            //console.log(error)
                            message.error(t('errors.error'))
                        })
                    : notification?.data?.status === "register_payment"
                        ? http.post(`/student-pay-study-group-registration/${student_id}/${lesson_id}`, {
                            bonus_amount: bonus,
                            bonus_type: notification.bonus_type
                        })
                            .then((response: any) => {
                                refreshBalance();
                                if (response.data.status === "error") {
                                    message.error(t('errors.error'))
                                    if (response.data.msg === "Not enough balance.") {
                                        history.push('/planner/payment-help')
                                    }
                                } else {
                                    message.success(t('notification.registration_is_paid'))
                                    checkRead(true)
                                }
                            }).catch((error: any) => {
                                message.error(t('errors.error'))
                            })
                        : http.post(`/student-study-group-services/approve-service/${student_id}/${lesson_id}`, {
                            bonus_amount: bonus,
                            bonus_type: notification.bonus_type
                        })
                            .then((response: any) => {
                                refreshBalance();
                                if (response.data.status === "error") {
                                    message.error(t('errors.error'))
                                    if (response.data.msg === "Not enough balance.") {
                                        history.push('/planner/payment-help')
                                    }
                                } else {
                                    if (notification.bonus_type.includes("pmp")) {
                                        http.post(`medical-groups-attach/${student.id}/${response.data?.data?.study_group_id}`, {
                                            medical_group: group,
                                            main: 1
                                        }).catch((err: AxiosError) => {
                                            console.log(err.response?.data?.msg)
                                            const { msg } = err.response?.data;
                                            // message.error("Kļūda! " + msg)
                                        })
                                    }
                                    message.success(t('notification.service_is_paid'))
                                    checkRead(true)
                                }
                            }).catch((error: any) => {
                                message.error(t('errors.error'))
                            })
            }
        });
    };

    const acceptExam = () => {
        confirm({
            title: t('notification.confirm_payment'),
            icon: <ExclamationCircleOutlined />,
            content: <p>{t('e_learning.do_you_want_to_pay_for_module')}</p>,
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: () => {
                const exam_id = notification.data.type.split("_").reverse()[0];
                setLoading(true);
                http.post(`approve-e-learning-module-exam/${exam_id}`).then((response: any) => {
                    refreshBalance();
                    //console.log(response)
                    if (response.data.status === "error") {
                        message.error(response.data.msg)
                        if (response.data.msg === "Not enough balance.") {
                            history.push('/planner/payment-help')
                        }
                    } else {
                        message.success(response.data.msg);
                        checkRead(props.reload);
                        http.post(`/reset-module/${response.data.data.hash}`).then((response: any) => {
                        })
                    }
                }).catch((err: any) => {
                    message.error(t('errors.error'));
                })
            }
        })
    };

    const cancelExamPayment = () => {
        confirm({
            title: t('notification.cancel'),
            icon: <ExclamationCircleOutlined />,
            content: <p>{t('e_learning.do_you_want_to_decline_extra_module')}</p>,
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: () => {
                const exam_id = notification.data.type.split("_").reverse()[0]
                http.post(`cancel-e-learning-module-exam/${exam_id}`).then((response: any) => {
                    refreshBalance();
                    checkRead();
                }).catch((err: any) => {
                    message.error(t('errors.error'));
                })
            }
        })
    };

    const acceptExtraMedical = (bonus: number | undefined = undefined, group_id: number | undefined = undefined) => {
        confirm({
            title: t('notification.confirm_payment'),
            icon: <ExclamationCircleOutlined />,
            content: <p>*{t('notification.sum_will_be_withdrawn_from_balance')}.</p>,
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: () => {
                const medical_id = notification.data.type.split("_").reverse()[0];
                setLoading(true);
                if (notification.data.status === "extra_pmp") {
                    http.post(`approve-extra-pmp/${medical_id}`, {
                        bonus_amount: bonus,
                        bonus_type: notification.bonus_type
                    }).then((response: any) => {
                        refreshBalance();
                        console.log(response)
                        if (response.data.status === "error") {
                            message.error(response.data.msg)
                            if (response.data.msg === "Not enough balance.") {
                                history.push('/planner/payment-help')
                            }
                        } else {
                            http.post(`medical-groups-attach/${student.id}/${response.data?.data[1]?.study_group_service?.study_group_id}`, {
                                medical_group: group_id,
                                main: 0,
                                extra_pmp: student?.extra_pmp?.[student.extra_pmp.length - 1]?.id
                            }).catch((err: AxiosError) => {
                                console.log(err.response?.data?.msg)
                                const { msg } = err.response?.data;
                                message.error("Kļūda! " + msg)
                            })
                            message.success(response.data.msg);
                            checkRead(props.reload);
                        }
                    }).catch((err: any) => {
                        message.error(t('errors.error'));
                    })
                } else {
                    http.post(`approve-extra-medical/${medical_id}`).then((response: any) => {
                        refreshBalance();
                        //console.log(response)
                        if (response.data.status === "error") {
                            message.error(response.data.msg)
                            if (response.data.msg === "Not enough balance.") {
                                history.push('/planner/payment-help')
                            }
                        } else {
                            http.post(`medical-groups-attach/${student.id}`, {
                                medical_group: group_id,
                                main: 0,
                                extra_pmp: student?.extra_pmp?.[student.extra_pmp.length - 1]?.id
                            }).catch((err: AxiosError) => {
                                console.log(err.response?.data?.msg)
                                const { msg } = err.response?.data;
                                message.error("Kļūda! " + msg)
                            })
                            message.success(response.data.msg);
                            checkRead(props.reload);
                        }
                    }).catch((err: any) => {
                        message.error(t('errors.error'));
                    })
                }
            }
        })
    };

    const cancelExtraMedicalPayment = () => {
        confirm({
            title: t('notification.cancel'),
            icon: <ExclamationCircleOutlined />,
            content: <p>{t('notification.do_you_want_cancel_service')}</p>,
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: () => {
                const medical_id = notification.data.type.split("_").reverse()[0]
                if (notification.data.status === "extra_pmp") {
                    http.post(`cancel-extra-pmp/${medical_id}`).then((response: any) => {
                        refreshBalance();
                        checkRead();
                    }).catch((err: any) => {
                        message.error(t('errors.error'));
                    })
                } else {
                    http.post(`cancel-extra-medical/${medical_id}`).then((response: any) => {
                        refreshBalance();
                        checkRead();
                    }).catch((err: any) => {
                        message.error(t('errors.error'));
                    })
                }
            }
        })
    }

    const acceptExtraOmniva = () => {
        confirm({
            title: t('notification.confirm_payment'),
            icon: <ExclamationCircleOutlined />,
            content: <p>*{t('notification.sum_will_be_withdrawn_from_balance')}.</p>,
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: () => {
                const omniva_id = notification.data.type.split("_").reverse()[0];
                setLoading(true);
                http.post(`approve-extra-omniva/${omniva_id}`).then((response: any) => {
                    refreshBalance();
                    //console.log(response)
                    if (response.data.status === "error") {
                        message.error(response.data.msg)
                        if (response.data.msg === "Not enough balance.") {
                            history.push('/planner/payment-help')
                        }
                    } else {
                        message.success(response.data.msg);
                        checkRead(props.reload);
                    }
                }).catch((err: any) => {
                    message.error(t('errors.error'));
                })
            }
        })
    };

    const cancelExtraOmnivaPayment = () => {
        confirm({
            title: t('notification.cancel'),
            icon: <ExclamationCircleOutlined />,
            content: <p>{t('notification.do_you_want_cancel_service')}</p>,
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: () => {
                const omniva_id = notification.data.type.split("_").reverse()[0]
                http.post(`cancel-extra-omniva/${omniva_id}`).then((response: any) => {
                    refreshBalance();
                    checkRead();
                }).catch((err: any) => {
                    message.error(t('errors.error'));
                })
            }
        })
    }

    const acceptIndividualDrivingRegistration = () => {
        confirm({
            title: t('notification.confirm_payment'),
            icon: <ExclamationCircleOutlined />,
            content: <p>{t('driving.do_you_want_to_pay_for_registration')}</p>,
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: () => {
                setLoading(true);
                http.post(`pay-individual-driving-registration`).then((response: any) => {
                    refreshBalance();
                    if (response.data.status === "error") {
                        message.error(response.data.msg)
                        if (response.data.msg === "Not enough balance.") {
                            history.push('/planner/payment-help')
                        }
                    } else {
                        message.success(response.data.msg);
                        checkRead(props.reload);
                    }
                }).catch((err: any) => {
                    message.error(t('errors.error'));
                })
            }
        })
    };

    const cancelIndividualDrivingRegistration = () => {
        confirm({
            title: t('notification.cancel'),
            icon: <ExclamationCircleOutlined />,
            content: <p>{t('notification.do_you_want_cancel_individual_driving')}</p>,
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: () => {
                http.post(`cancel-individual-driving-registration`).then((response: any) => {
                    refreshBalance();
                    checkRead();
                }).catch((err: any) => {
                    message.error(t('errors.error'));
                })
            }
        })
    }

    useEffect(() => {
        // console.log(student)
    }, [])

    const notificationStyle = {
        backgroundColor: props.custom ? 'rgb(246, 255, 237)' : notification.data.status === 'success'
            ? 'rgb(246, 255, 237)'
            : notification.data.status === "service_payment"
                || notification.data.status === "payment"
                || notification.data.status === "elearning_module_extra_exam"
                || notification.data.status === "extra_medical"
                || notification.data.status === "extra_pmp"
                || notification.data.status === "extra_omniva"
                || notification.data.status.includes("individual_driving_registration")
                ? '#ffeece'
                : 'rgb(255, 234, 230)',
        borderColor: props.custom ? 'rgb(82, 196, 26)' : notification.data.status === 'success'
            ? 'rgb(82, 196, 26)'
            : notification.data.status === "service_payment"
                || notification.data.status === "payment"
                || notification.data.status === "elearning_module_extra_exam"
                || notification.data.status === "extra_medical"
                || notification.data.status === "extra_pmp"
                || notification.data.status === "extra_omniva"
                || notification.data.status.includes("individual_driving_registration")
                ? 'orange'
                : 'red'
    }

    return (
        <Card style={{ marginBottom: 10, ...notificationStyle }}>
            {
                paymentModalVisible
                && <ServicePaymentModal
                    visible={paymentModalVisible}
                    setVisible={setPaymentModalVisible}
                    type={notification.bonus_type}
                    amount={notification.amount}
                    student={student}
                    pay={
                        (bonus: number, group: number | undefined) => notification.data.status === "payment"
                            ? acceptAttendance('foreign', bonus)
                            : notification.data.status === "extra_pmp"
                                ? acceptExtraMedical(bonus, group)
                                : acceptAttendance('service', bonus, group)
                    }
                />
            }
            <Row>
                <Col span={24} lg={16}>
                    <p style={{ overflowWrap: "break-word" }}>{props.custom ? notification.message : (notification.category ?? "") + " " + notification.data.message}</p>
                    {
                        notification.additional_information
                        && <b>{notification.additional_information}</b>
                    }
                </Col>
                <Col span={24} lg={8}>
                    {
                        props.history
                            ? <Row justify="end" >
                                <Col span={12} style={{ paddingLeft: 15 }}>
                                    <h5><b>Saņemts:</b></h5>
                                    <p style={{ marginBottom: 0, fontSize: 12 }}><b>{moment(notification.created_at).format("DD.MM.YYYY")}</b></p>
                                    <p style={{ marginBottom: 0, fontSize: 12 }}><b>{moment(notification.created_at).format("HH:mm")}</b></p>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 15 }}>
                                    <h5><b>Izlasīts:</b></h5>
                                    <p style={{ marginBottom: 0, fontSize: 12 }}><b>{moment(notification.read_at).format("DD.MM.YYYY")}</b></p>
                                    <p style={{ marginBottom: 0, fontSize: 12 }}><b>{moment(notification.read_at).format("HH:mm")}</b></p>
                                </Col>
                            </Row>
                            : <Row justify="end" className="notification-buttons">
                                {
                                    props.custom
                                        ? <Button loading={loading} onClick={() => checkRead()}>{t('notification.mark_as_read')}</Button>
                                        : notification.data.status === "payment"
                                            ? <Space>
                                                <Button loading={loading} className="btn-green" onClick={() => setPaymentModalVisible(true)}>{t('common.confirm')}</Button>
                                                <Button loading={loading} danger type="primary" onClick={() => cancelAttendance('foreign')}>{t('common.cancel')}</Button>
                                            </Space>
                                            : notification.data.status === "service_payment" || notification.data.status === "register_payment"
                                                ? <Space>
                                                    <Button loading={loading} className="btn-green" onClick={() => notification.bonus_type ? setPaymentModalVisible(true) : acceptAttendance("service")}>{t('common.confirm')}</Button>
                                                    <Button loading={loading} danger type="primary" onClick={() => cancelAttendance('service')}>{t('common.cancel')}</Button>
                                                </Space>
                                                : notification.data.status === "elearning_module_extra_exam"
                                                    ? <Space>
                                                        <Button loading={loading} className="btn-green" onClick={() => acceptExam()}>{t('common.confirm')}</Button>
                                                        <Button loading={loading} danger type="primary" onClick={() => cancelExamPayment()}>{t('common.cancel')}</Button>
                                                    </Space>
                                                    : notification.data.status === "extra_medical"
                                                        ? <Space>
                                                            <Button loading={loading} className="btn-green" onClick={() => acceptExtraMedical()}>{t('common.confirm')}</Button>
                                                            <Button loading={loading} danger type="primary" onClick={() => cancelExtraMedicalPayment()}>{t('common.cancel')}</Button>
                                                        </Space>
                                                        : notification.data.status === "extra_pmp"
                                                            ? <Space>
                                                                <Button loading={loading} className="btn-green" onClick={() => setPaymentModalVisible(true)}>{t('common.confirm')}</Button>
                                                                <Button loading={loading} danger type="primary" onClick={() => cancelExtraMedicalPayment()}>{t('common.cancel')}</Button>
                                                            </Space>
                                                            : notification.data.status === "extra_omniva"
                                                                ? <Space>
                                                                    <Button loading={loading} className="btn-green" onClick={() => acceptExtraOmniva()}>{t('common.confirm')}</Button>
                                                                    <Button loading={loading} danger type="primary" onClick={() => cancelExtraOmnivaPayment()}>{t('common.cancel')}</Button>
                                                                </Space>
                                                                : notification.data.status.includes("individual_driving_registration")
                                                                    ? <Space>
                                                                        <Button loading={loading} className="btn-green" onClick={() => acceptIndividualDrivingRegistration()}>{t('common.confirm')}</Button>
                                                                        <Button loading={loading} danger type="primary" onClick={() => cancelIndividualDrivingRegistration()}>{t('common.cancel')}</Button>
                                                                    </Space>
                                                                    : <Button loading={loading} onClick={() => checkRead()}>{t('notification.mark_as_read')}</Button>
                                }
                            </Row>
                    }
                </Col>
            </Row>
        </Card>
    )
}