import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Space, Tag } from 'antd';
import { ReactComponent as SteeringWheelLogo } from '../../img/ic-instructors-car.svg';
import { ReactComponent as CalendarLogo } from '../../img/ic-instructors-calendar.svg';
import { ReactComponent as LocationMarkerLogo } from '../../img/ic-instructors-location.svg';
import { ReactComponent as PriceLogo } from '../../img/ic-instructors-price.svg';
//@ts-ignore
import Flag from "react-flags";
import { useTranslation } from 'react-i18next';

export const InstructorInfoCard = (props: any) => {
    const { t } = useTranslation();
    const [cityService, setCityService] = useState({} as any);
    const {
        instructor: s,
        callback,
        loading
    } = props;

    useEffect(() => {
        //console.log(s, props.filter)
    }, [s])

    useEffect(() => {
        //console.log(cityService)
    }, [cityService])

    const out = {
        name: `${s.instructor?.first_name} ${s.instructor?.last_name}`,
        transmission: s.transmission == "manual" ? t('common.transmission.manual') : t('common.transmission.auto'),
        // languages: s.languages.map((a: any) => a.value).join(", "),
        meeting_place: s.city_services?.find((el: any) => el.city_id === props.filter.city?.id)?.meeting_place,
        city: s.cities?.find((el: any) => el.id === props.filter.city?.id)?.value,
        // cost: `${Number(cityService)?.toFixed(2)} EUR`,
        vehicle: s.vehicle,
        total_rate_real: s?.city_services?.find((el: any) => el.city_id === props.filter.city?.id)?.city_total_price,
        languages_iso: s.instructor?.languages
    };

    return <div className={`instructor-card-container ${props.type}`}>
        <Card
            className='instructor-card'
            loading={loading}
            hoverable={props.type === "inline-small" ? true : false}
        >

            <Row>
                <Col span={24} className={`main-col ${props.type}`}>
                    <div className="top-container">
                        <div className="img-wrapper">
                            <img src={process.env.REACT_APP_API_URL + s.img} alt="" />
                        </div>
                        <div className="instructor-photo-wrapper">
                            <img src={process.env.REACT_APP_API_URL + s.instructor?.public_image} alt="" />
                        </div>
                    </div>
                    <div className="card-container">
                        <h3>
                            {out.name}
                            <span className="flags">
                                {out.languages_iso?.map((lang: any) => {
                                    const iso = lang.iso === "en" ? "gb" : lang.iso;
                                    return <Flag
                                        key={lang.id}
                                        basePath={`${process.env.REACT_APP_API_URL}storage/flags`}
                                        format="svg"
                                        name={iso}
                                    />
                                })}
                            </span>
                        </h3>
                        <Row className='param-row'>
                            <div className="icon-wrap"><SteeringWheelLogo /></div>
                            <Col className='param-col'>
                                <span className='param-title'>{t('instructor_card.vehicle')}</span>
                                <b className='param'>
                                    {out.vehicle}
                                    <Tag color={out.transmission === "Manuālā" ? "#079DC1" : "#6D7278"}>{out.transmission}</Tag>
                                </b>
                            </Col>
                        </Row>
                        <Row className='param-row'>
                            <div className="icon-wrap"><LocationMarkerLogo /></div>
                            <Col className='param-col'>
                                <span className='param-title'>{t('instructor_card.meeting_place')}</span>
                                <b className='param'>
                                    {
                                        props.filter.all
                                            ? t('instructor_card.depends_on_city')
                                            : out?.meeting_place + ", " + out?.city
                                    }
                                </b>
                            </Col>
                        </Row>
                        <Row className='param-row'>
                            <div className="icon-wrap"><PriceLogo /></div>
                            <Col className='param-col'>
                                <span className='param-title'>{t('instructor_card.one_lesson_price')}</span>
                                <b className='param'>
                                    {
                                        props.filter.all
                                            ? t('instructor_card.depends_on_city')
                                            : out.total_rate_real?.toFixed(2) + " EUR"
                                    }
                                </b>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Card>
    </div>
}