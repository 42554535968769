import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const FirstStep = (props: any) => {
    const { t } = useTranslation();
    const { setActive } = props;

    return <div>
        <h1>{t('tutorial.step_one.welcome_to_system')}</h1>
        <p>{t('tutorial.step_one.introduction')}</p>
        <Button
            type="link"
            style={{ display: "block" }}
            onClick={() => setActive(2)}
        >
            2. {t('tutorial.step_one.deposit_replenishment')}
        </Button>
        <Button
            type="link"
            style={{ display: "block" }}
            onClick={() => setActive(3)}
        >
            3. {t('tutorial.step_one.services')}
        </Button>
        <Button
            type="link"
            style={{ display: "block" }}
            onClick={() => setActive(4)}
        >
            4. {t('tutorial.step_one.payments')}
        </Button>
        <Button
            type="link"
            style={{ display: "block" }}
            onClick={() => setActive(5)}
        >
            5. {t('tutorial.step_one.theory_overview')}
        </Button>
        <Button
            type="link"
            style={{ display: "block" }}
            onClick={() => setActive(6)}
        >
            6. {t('tutorial.step_one.driving_overview')}
        </Button>
        <Button
            type="link"
            style={{ display: "block" }}
            onClick={() => setActive(7)}
        >
            7. {t('tutorial.step_one.school_theory_exam')}
        </Button>
        <Button
            type="link"
            style={{ display: "block" }}
            onClick={() => setActive(8)}
        >
            8. {t('tutorial.step_one.school_driving_exam')}
        </Button>
    </div>
}