import { Button, Card, Row, Statistic, Tooltip } from "antd";
import { PlusOutlined, InfoCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CoinImage from "../../img/einstein-coin.png";
import { ReactComponent as WalletIcon } from '../../img/ic-sidenav-wallet.svg';
import moment from "moment";
import { useTranslation } from "react-i18next";

export const BalanceCard = (props: any) => {
    const { t } = useTranslation();
    const { balance, credit, self, bonus } = props;
    // const [balance, setBalance] = useState(undefined as any);
    // const [credit, setCredit] = useState(undefined as any);
    const [loading, setLoading] = useState(true);

    // const fetchBalance = async () => {
    //     await http.get('balance').then((response: any) => {
    //         setCredit({
    //             current_credit: response.data.data.current_credit,
    //             used_credit: response.data.data.used_credit,
    //             credit: response.data.data.credit
    //         })
    //         setBalance(response.data.data.balance);
    //     })
    // };

    useEffect(() => {
        // fetchBalance();
    }, [])

    useEffect(() => {
        //console.log(balance, credit)
        if (balance !== undefined) setLoading(false)
    }, [balance]);

    const customSuffix = (
        <>
            &euro;
            <Link style={{ marginLeft: 5 }} to={`/planner/payment-help`}>
                <Button className='add-balance-btn' type="text" icon={<PlusOutlined />}></Button>
            </Link>
        </>
    );

    return loading
        ? <></>
        : <Card className='balance-card' bodyStyle={{ padding: 0, paddingTop: 20 }}>
            <WalletIcon className="icon" style={{ marginLeft: 20 }} />
            {/* <Link
            style={{
                display: "absolute",
                top: 0,
                left: 0,
                width: 
            }}
            to="/payments"
            ></Link> */}
            <Statistic title={t('balance.your_remaining_balance')} value={balance} suffix={customSuffix} precision={2} />
            {
                Number(credit?.credit) > 0.00 && moment(self.credit_till, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment())
                    ? <div>
                        <p>{t('balance.credit_limit')}: {Number(credit.credit).toFixed(2)} &euro;</p>
                        <p>{t('balance.available_credit')}: {Number(credit.current_credit).toFixed(2)} &euro;</p>
                        <p>{t('balance.due_date')}: {self.credit_till}</p>
                    </div>
                    : !moment(self.credit_till, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment())
                        && !(Number(credit?.current_credit) === Number(balance))
                        ? <p style={{ color: "red" }}>{t('balance.credit_due_date')}:<br /> {self.credit_till}</p>
                        : <></>
            }
            <Link to="/einstein-coins">
                <Row style={{ borderTop: "1px solid #666F80", marginTop: 17, padding: "14px 20px" }} align="middle">
                    <img style={{ width: 20, height: 20 }} src={CoinImage} alt="Einstein coins" />
                    <span style={{ marginLeft: 8, color: "#fff" }}>{t('common.in_your_account')} <span style={{ fontWeight: "bold" }}>{bonus}</span> Einšteini </span>
                    {/* <Tooltip placement="top" title={"comment"}>
                        <InfoCircleFilled style={{ color: "#A1ABB3", marginLeft: 4 }} />
                    </Tooltip> */}
                </Row>
            </Link>
        </Card>
}