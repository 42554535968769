import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IcPointA } from "../../../img/ic-a.svg";
import { ReactComponent as IcPointB } from "../../../img/ic-b.svg";
import { ReactComponent as IcDistance } from "../../../img/ic-distance.svg";
import { ReactComponent as IcSpeed } from "../../../img/ic-speed.svg";
import { DrivingStatBadge } from "../../components/DrivingStatBadge";

export const DrivingStats = (props: any) => {
    const { t } = useTranslation();
    return <div>
        <DrivingStatBadge
            Icon={IcPointA}
            text={t('driving.eval.started')}
            data={props.route?.start_address}
        />
        <DrivingStatBadge
            Icon={IcPointB}
            text={t('driving.eval.ended')}
            data={props.route?.end_address}
        />
        <DrivingStatBadge
            Icon={IcDistance}
            text={t('driving.eval.driven')}
            data={props.route?.distance ? (props.route?.distance / 1000).toFixed(1) + " km" : 0 + " km"}
        />
        {/* <DrivingStatBadge
            Icon={IcSpeed}
            text={t('driving.eval.overspeed')}
            data={"2 gadījumi"}
        /> */}
    </div>
}