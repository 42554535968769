import { Row, Col, Progress } from "antd"
import React from "react"
import { useTranslation } from "react-i18next";

export const RatingSection = (props: any) => {
    const { t } = useTranslation();
    const { rating, total, gradeLVL } = props;
    return <Row style={{
        flexWrap: "nowrap",
        alignItems: "center"
    }}>
        <div style={{
            paddingRight: 0,
            order: 1
        }}>
            <h3 style={{
                marginBottom: 4,
            }}>
                <b>
                    {t('driving.eval.student_rating')}
                </b>
            </h3>
            <p style={{
                marginBottom: 4,
                lineHeight: "16px",
            }}>
                <small>{t('driving.eval.student_rating_according_to_lesson')}</small>
            </p>
            <p style={{
                margin: 0,
                lineHeight: "16px",
            }}>
                <small>{t('driving.eval.learning_lvl')}: {t(`driving.eval.${gradeLVL}`)}</small>{/*Tulkojums*/}
            </p>
        </div>
        <div style={{
            display: "flex",
            alignItems: "center",
            order: 2
        }}>
            <Progress
                percent={Number((rating / (total * 0.01)).toFixed(0))}
                format={(steps: any) => `${rating}`}
                type="circle"
                width={80}
                style={{ marginBottom: 20 }}
            />
        </div>
    </Row>
}