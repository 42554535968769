import { Card, Col, Layout, Row, Typography, Button, Collapse, Form, InputNumber, Input, Space, Radio, Divider, Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { ReactComponent as Success } from '../../img/ic-success-40.svg';
import { http } from "../../helpers/http";
import localeValues from "antd/lib/locale/lv_LV";
import { useTranslation } from "react-i18next";

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

var thePath = document.location.pathname;
var result = thePath.split('/');
var token = result[result.length - 2];
//this should return student by token, shoots only once per token! 
//after that need to change student_login_tokens set to null consumed_at current token in db to continue testing
if (token.length > 40) http.get('tokenizer/' + token)
    .then((result: any) => {
        if (typeof result.data.data.api_token !== "undefined") {
            sessionStorage.setItem("bearer_token", result.data.data.api_token);
            window.location.reload()
        }
    });
export const PaidPage = withRouter((props: any) => {
    const { t } = useTranslation();
    const [deposit, setDeposit] = useState(0);
    const [balance, setBalance] = useState(null as any);
    const [student, setStudent] = useState(null as any);
    const [loaded, setLoaded] = useState(false);
    const [paymentType, setPaymentType] = useState("");
    const [paid, setPaid] = useState(false);

    useEffect(() => {
        const p1 = http.get('self')
            .then((result: any) => { setStudent(result.data.data) });

        const p2 = http.get('balance')
            .then((result: any) => { setBalance(result.data.data.balance); })

        Promise.all([p1, p2])
            .then(() => { setLoaded(true) });
    }, []);

    const AddVal = () => {
        const buttons = []
        for (let i = 25; i <= 200; i += 25) {
            i === 175 ? i = 200 : i = i;
            buttons.push(<Button onClick={(value: any) => setDeposit(deposit + i)} key={i} value={i}>+{i}</Button>)
        }
        return <Space className="add-value-container">
            {buttons}
        </Space>;
    }

    return (
        <Layout className="main-content">
            <Card style={{ marginBottom: 5 }}>
                <Row gutter={20} justify="space-between">
                    <Col>
                        <Title level={3} style={{ marginLeft: 30, marginBottom: 0 }}>
                            {t('payments.payment')}
                        </Title>
                    </Col>
                    <Col>
                        <Link to={`/planner`}><Button type="primary">{t('payments.return')}</Button></Link>
                    </Col>
                </Row>
            </Card>
            {loaded ?
                <Row>
                    <Col className="payment-col" xl={16}>
                        <Card style={{ margin: 24 }} title={<Title level={5}>{t('payments.account_replenishment')}</Title>}>
                            {/* <Button onClick={() => console.log()}>!</Button> */}
                            <Row>
                                <Col span={24} style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "44px 24px 24px" }}>
                                    <Success style={{ marginBottom: 20 }} />
                                    <Title style={{ textAlign: "center" }} level={5}>{t('payments.your_account_replenished_by')} {props.match.params.sum} EUR!</Title>
                                    <Text style={{ fontWeight: "normal", color: "#575757", lineHeight: "22px", fontSize: 14, textAlign: "center", marginBottom: 20 }}>
                                        {t('payments.paid_page_info_text')}
                                    </Text>
                                    {/*<Button
                                        className="custom-btn"
                                        style={{ marginBottom: 44 }}
                                        type="primary">
                                        {t('payments.schedule_driving_lessons')}
                                    </Button>
                                    <Divider />
                                    <Collapse className="help-collapse" accordion style={{ width: "100%", marginTop: 20 }}>
                                        <Panel header="Ar ko man ir jāsāk?" key="1">
                                            <p>Nepieciešamais teksts, par to kur un kā audzēknis var izlietot papildināto summu un ko darīt, ja mācību beigās maciņā veidosies atlikums. Lorem Ipsum izmanto tāpēc, kas tas nodrošina vairāk vai mazāk vienmērīgu burtu izvietojumu un padara to līdzīgu lasāmam tekstam angļu valodā, kas neizdodas.</p>
                                        </Panel>
                                        <Panel header="Kā es varu pieteikt braukšanas nodarbības?" key="2">
                                            {/* <p>{text}</p> 
                                        </Panel>
                                        <Panel header="Ko darīt, ja mācību beigās manā maciņā paliek atlikums?" key="3">
                                            {/* <p>{text}</p> 
                                        </Panel>
                                        <Panel header="Ar ko man ir jāsāk?" key="4">
                                            {/* <p>{text}</p> 
                                        </Panel>
                                        <Panel header="Kā es varu pieteikt braukšanas nodarbības?" key="5">
                                            {/* <p>{text}</p> 
                                        </Panel>
                                    </Collapse>*/}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="deposit-info-col" xl={8}>
                        <Card style={{ borderTop: "2px solid #079DC1" }}>
                            <Row>
                                <Col>
                                    <Title level={5}>{t('payments.hi')}, {student?.first_name} {student?.last_name}</Title>
                                    <small>{t('payments.your_account_balance')}</small>
                                    <Title level={3}>{balance} EUR</Title>
                                    {/* <Button onClick={() => console.log(props)}>!</Button> */}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                : <><Skeleton /></>}
        </Layout>
    );
});
