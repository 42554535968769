import { Card, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { http } from "../../../helpers/http";
import { QuestionHeader } from "./QuestionHeader";
import { QuestionHistory } from "./QuestionHistory";
import { TopicHeader } from "./TopicHeader";
import i18n from 'i18next';

export const TopicsCard = (props: any) => {
    const { t } = useTranslation();
    const {
        isMobile,
        themes,
        student,
        studentHistory,
        subjectHistory
    } = props;
    const [data, setData] = useState([] as any);

    useEffect(() => {
        const newSubjects = themes.map((theme: any) => {
            return {
                ...theme,
                subjects: theme.subjects?.map((subject: any) => {
                    return {
                        ...subject,
                        results: subjectHistory[subject.id]
                    }
                })
            }
        })
        setData(newSubjects);
    }, [themes, subjectHistory])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return <Card
        title={t('driving.eval.lesson_plan')}
        bodyStyle={{
            padding: isMobile ? 0 : 24
        }}
        style={{
            margin: isMobile ? "0 0 24px" : 24
        }}
    >
        <Collapse expandIconPosition="right">
            {
                data.map((theme: any, index: any) => {
                    return <Collapse.Panel
                        key={index}
                        header={<TopicHeader
                            isMobile={isMobile}
                            number={index + 1}
                            active={false}
                            topic={theme}
                            description={theme?.[`tooltip_${i18n.language}`]}
                            questions={theme.subjects}
                            subjectHistory={subjectHistory}
                        />}
                    >
                        <Collapse expandIconPosition="right">
                            {
                                theme.subjects.map((question: any, ind: any) => {
                                    const slots = studentHistory?.filter((lesson: any) => {
                                        if (Array.isArray(lesson.grades)) return false;
                                        const beginner = lesson.grades.beginner?.subject_ids?.some((el: any) => el === question.id)
                                        const medium = lesson.grades.medium?.subject_ids?.some((el: any) => el === question.id)
                                        const exam_worthy = lesson.grades.exam_worthy?.subject_ids?.some((el: any) => el === question.id)
                                        return beginner || medium || exam_worthy
                                    })
                                    return <Collapse.Panel
                                        key={ind}
                                        header={
                                            <QuestionHeader
                                                isMobile={isMobile}
                                                number={`${index + 1}.${ind + 1}.`}
                                                question={question}
                                            />
                                        }
                                    >
                                        <QuestionHistory slots={slots} questionID={question.id} />
                                    </Collapse.Panel>
                                })
                            }
                        </Collapse>
                    </Collapse.Panel>
                })
            }
        </Collapse>
    </Card>
}