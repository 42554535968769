import { List, Row, Col, DatePicker, Button } from "antd";
import i18n, { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";

export const LessonsList = (props: any) => {

    const [selectedDate, setDate] = useState("");
    const [freeLessons, showFree] = useState(false);
    const [period, setPeriod] = useState(null as any);
    const [lang, setLang] = useState("lv");

    function groupBy(arr: any, property: any) {
        if (arr === null) return;
        return arr.reduce(function (memo: any, x: any) {
            if (!memo[x[property]]) {
                memo[x[property]] = [];
            }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }
    let sortedDays = groupBy(props.slots, "date");

    const onDatePick = (date: any, dateString: string) => {
        setDate(dateString);
    };

    const resetDatePick = () => {
        setDate("");
    };

    const fetchByPeriod = () => {
        if (period === null) return;
        props.fetchByPeriod(period, true);
    }

    useEffect(() => {
        setLang(i18n.language)
    }, [i18n.language])

    return (
        <div>
            <DatePicker
                style={{ marginBottom: "20px" }}
                defaultValue={moment()}
                format={props.dateFormat}
                onChange={onDatePick}
            />
            <div style={{ border: "1px solid rgb(203 203 203)", padding: 10, marginBottom: 20 }}>
                <DatePicker.RangePicker
                    className="mobile-range-picker"
                    format={props.dateFormat}
                    onChange={setPeriod}
                    value={period}
                />
                <Button onClick={fetchByPeriod} className="mobile-range-picker-btn">{t('common.show')}</Button>
            </div>
            <div className="list-container" style={{ overflow: "scroll", height: "100vh", paddingBottom: 200 }}>
                {selectedDate.length < 1 ? Object.keys(sortedDays)
                    .sort()
                    .map((day: any) => {
                        if (moment(day, "YYYY-MM-DD").isSameOrAfter(moment(props?.student?.can_start_driving_date ?? undefined), 'day')) {
                            return (
                                <Day
                                    key={day}
                                    date={day}
                                    list={sortedDays[day]}
                                    dateCell={props.dateCell}
                                />
                            );
                        }
                        else return;
                    }) : <Day
                    date={selectedDate}
                    list={sortedDays[selectedDate]}
                    dateCell={props.dateCell}
                    expanded={true}
                    openDate={true}
                    resetDate={resetDatePick}
                />}
            </div>
        </div>
    );
};

const Day = (props: any) => {
    const [expanded, setExpanded] = useState(props.expanded);
    const [isSingleDate, toggleDate] = useState(props.openDate);

    const expandHandler = () => {
        expanded ? setExpanded(false) : setExpanded(true);
    };

    React.useEffect(() => {
        setExpanded(props.expanded);
    }, [props.expanded])

    React.useEffect(() => {
        toggleDate(props.openDate);
    }, [props.openDate])

    const backToList = () => {
        toggleDate(false);
        setExpanded(false);
        props.resetDate();
    }

    return (
        <div>
            <List
                className="mobile-calendar-list"
                style={
                    expanded
                        ? { backgroundColor: "#F0F0F0" }
                        : { backgroundColor: "#FFF" }
                }
                header={
                    <Row justify={"space-between"}>
                        <Col span={12}>
                            <button
                                style={{
                                    border: 0,
                                    backgroundColor: "transparent",
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                onClick={expandHandler}
                            >
                                {props.date}
                            </button>
                        </Col>
                    </Row>
                }
                bordered
            >
                {expanded ? (
                    <div
                        style={{
                            padding: "5px",
                            borderTop: "1px solid #c9c9c9",
                        }}
                    >
                        {props.dateCell(moment(props.date))}
                        {isSingleDate ? <Button onClick={backToList} style={{ margin: 5 }}>
                            {t('calendar.back_to_all')}
                        </Button> : <></>}
                    </div>
                ) : (
                    <></>
                )}
            </List>
        </div>
    );
};
