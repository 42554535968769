import { Button, Table } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const QuestionHistory = (props: any) => {
    const { t } = useTranslation();
    const { slots, questionID } = props;
    const history = useHistory();

    const difficultyTranslation = {
        beginner: t('driving.eval.beginner'),
        medium: t('driving.eval.medium'),
        exam_worthy: t('driving.eval.exam_worthy')
    } as any;

    const gradeTranslation = {
        beginner: t('driving.eval.poor'),
        medium: t('driving.eval.almost_good'),
        exam_worthy: t('driving.eval.good')
    } as any;

    const columns = [
        {
            title: t('common.date_and_time'),
            key: "datetime",
            render: (nothing: any, record: any) => {
                return `${record.lesson_date} ${record.time_from} ${record.time_to}`
            }
        },
        {
            title: t('driving.eval.instructor'),
            dataIndex: "instructor_name",
            key: "instructor_name"
        },
        {
            title: t('driving.eval.lesson_lvl'),
            dataIndex: "grade",
            key: "grade",
            render: (grade: any, record: any) => {
                return difficultyTranslation[grade];
            }
        },
        {
            title: t('driving.eval.grade'),
            key: "rating",
            render: (nothing: any, record: any) => {
                if (Array.isArray(record.grades)) return false;
                const beginner = record.grades.beginner?.subject_ids?.some((el: any) => el === questionID)
                const medium = record.grades.medium?.subject_ids?.some((el: any) => el === questionID)
                const exam_worthy = record.grades.exam_worthy?.subject_ids?.some((el: any) => el === questionID)
                const level = beginner
                    ? "beginner"
                    : medium
                        ? "medium"
                        : exam_worthy
                            ? "exam_worthy"
                            : ""
                return gradeTranslation[level];
            }
        },
        {
            title: "",
            key: "action",
            render: (nothing: any, record: any) => {
                return <Button
                    onClick={() => history.push(`/driving/stats/${record.student_lesson_id}`)}
                >
                    {t('driving.eval.open_lesson')}
                </Button>
            }
        },
    ] as any;

    useEffect(() => {
        console.log(slots, questionID)
    }, [slots])

    return <Table
        dataSource={slots}
        columns={columns}
    />
}