import { Button, Card, Input, Modal, Row, Table, Tag } from "antd";
import comment from "antd/lib/comment";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { http } from "../../../helpers/http";
import { MobileUpcomingLessons } from "./MobileUpcomingLessons";

export const UpcomingLessonsTable = (props: any) => {
    const {
        upcomingLessons,
        isMobile,
        lastLesson,
        goToCalendar,
        removeLesson
    } = props;

    const history = useHistory();
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const [cancelPercent, setCancelPercent] = useState(0 as number);
    const [lessonToCancel, setLessonToCancel] = useState(null as any);
    const [freeToCancel, setFreeToCancel] = useState(false);

    function cancelLesson() {
        setLoading(true);
        console.log(lessonToCancel)
        http.patch(`lessons/${lessonToCancel.lesson_id}`, { comment }).then((response: any) => {
            setOpen(false);
            removeLesson();
            setLoading(false)
            // window.location.reload();
        });
    }

    const fetchStudentCancelPercent = async () => {
        await http.get('/get-student-cancel-school-percent').then((response: any) => {
            setCancelPercent(Number(response.data.data))
        })
    }

    useEffect(() => {
        fetchStudentCancelPercent();
    }, []);

    useEffect(() => {
        if (lessonToCancel) {
            setFreeToCancel(moment(lessonToCancel.date + " " + lessonToCancel.time_from, "YYYY-MM-DD HH:mm").diff(moment(), "minutes") / 60 >= 24)
            setOpen(true)
        }
    }, [lessonToCancel])

    const columns = [
        {
            title: t('common.date'),
            dataIndex: "date",
            key: "date",
            render: (date: any) => {
                return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY")
            }
        },
        {
            title: t('driving.eval.time'),
            key: "time",
            render: (nothing: any, record: any) => {
                const timeFrom = moment(record.time_from, "HH:mm:ss").format("HH:mm");
                const timeTo = moment(record.time_to, "HH:mm:ss").format("HH:mm");
                return `${timeFrom} - ${timeTo}`
            }
        },
        {
            title: t('driving.eval.instructor'),
            dataIndex: "instructor_name",
            key: "instructor_name"
        },
        {
            title: t('driving.eval.starting_place'),
            dataIndex: "meeting_place",
            key: "meeting_place"
        },
        {
            dataIndex: "student_lesson_id",
            render: (id: any, record: any) => {
                return <Button
                    danger
                    onClick={() => setLessonToCancel(record)}
                >
                    {t('common.cancel')}
                </Button>
            }
        }
    ]

    return <Card
        bodyStyle={{
            padding: isMobile ? "12px 20px 30px" : "0 0 30px"
        }}
        title={
            <Row justify="space-between">
                <h3 style={{ margin: 0 }}><b>{t('driving.scheduled_lessons')}</b></h3>
                {
                    !isMobile
                        ? lastLesson
                            ? <Button onClick={() => goToCalendar(lastLesson?.instructor_service_id, lastLesson?.city_id)} type="primary">{t('driving.apply_for_lesson')}</Button>
                            : <Button onClick={() => history.push('/planner/select-instructor')} type="primary">{t('driving.select_instructor')}</Button>
                        : <></>

                }
            </Row>
        }
    >
        {
            isMobile
                ? upcomingLessons?.map((lesson: any) => {
                    return <MobileUpcomingLessons
                        key={lesson.lesson_id}
                        lesson={lesson}
                        setLessonToCancel={setLessonToCancel}

                    />
                })
                : <Table
                    columns={columns}
                    dataSource={upcomingLessons}
                    pagination={false}
                />
        }
        <Modal
            onCancel={() => { setOpen(false); setLessonToCancel(null) }}
            onOk={cancelLesson}
            visible={isOpen}
            title={t('driving.cancel_lesson')}
            footer={[
                <Button key="back" onClick={() => { setOpen(false); setLessonToCancel(null) }}>
                    {t('driving.will_attend')}
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={() => cancelLesson()} danger>
                    {t('driving.yes_cancel_lesson')}
                </Button>
            ]}
        >
            {
                freeToCancel
                    ? cancelPercent > 0
                    && <Tag style={{ marginBottom: 5, whiteSpace: "normal", textAlign: "center", width: "100%" }} color="warning">
                        {t('driving.warning.comission_warning_1')} {cancelPercent} % {t('driving.warning.comission_warning_1')}
                    </Tag>
                    : <Tag
                        color="error"
                        style={{ whiteSpace: "normal", fontSize: 14, textAlign: "center", marginBottom: 15 }}>
                        {t('driving.warning.24h_cancel_lesson')}
                    </Tag>
            }
            {t('driving.you_sure_want_cancel_lesson')} <br />
            {t('driving.reason')}:
            <Input.TextArea
                value={comment}
                onChange={(e: any) => setComment(e.target.value)}
                autoSize={{ maxRows: 3, minRows: 3 }}
            />
        </Modal>
    </Card>
}