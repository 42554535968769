import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const FourthStep = (props: any) => {
    const { t } = useTranslation();
    const baseUrl = process.env.REACT_APP_API_URL;

    return <div>
        <h1>{t('tutorial.step_four.payments')}</h1>
        <p>{t('tutorial.step_four.payments_review')}</p>
        <p>{t('tutorial.step_four.payments_invoices')}</p>
        <div style={{ width: "100%" }}>
            <img src={baseUrl + "storage/student-tutorial/payments-view-tutorial.png"} style={{ width: "100%" }} />
        </div>
    </div>
}