import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColoredProgressBar } from "../../components/ColoredProgressBar";

export const QuestionHeader = (props: any) => {
    const {t} =  useTranslation();
    const {
        number,
        question,
        tries
    } = props;
    const [isMobile, setIsMobile] = useState(false);

    const translation = {
        beginner: t('driving.eval.poor'),
        medium: t('driving.eval.almost_good'),
        exam_worthy: t('driving.eval.good')
    } as any;

    useEffect(() => {
        setIsMobile(window.matchMedia("(max-width: 996px)").matches)

        window.addEventListener("resize", () => {
            setIsMobile(window.matchMedia("(max-width: 996px)").matches)
        })
    }, []);

    return <Row
        justify="space-between"
    // style={{ flexWrap: "nowrap" }}
    >
        <Col
            span={24}
            lg={18}
        >
            <Row style={{ flexWrap: "nowrap" }}>
                <Col>
                    {number}
                </Col>
                <Col style={{ marginLeft: 10 }}>
                    <p>{question.content_lv}</p>
                    <small>{t('driving.eval.tried')} {question?.results ? question?.results.counter : 0}</small>
                </Col>
            </Row>
        </Col>
        <Col
            span={24}
            lg={6}
            style={{
                display: isMobile ? "block" : "flex",
                flexDirection: isMobile ? "row" : "column",
                justifyContent: isMobile ? "space-between" : "start",
                alignItems: isMobile ? "center" : "end",
            }}
        >
            {
                question?.results
                    ? <div style={{ order: isMobile ? 2 : 1 }} >
                        <ColoredProgressBar
                            results={question?.results.grades}
                        />
                    </div>
                    : <></>
            }

            <b style={{ order: isMobile ? 1 : 2 }}>
                {
                    question?.results !== undefined
                        ? translation[JSON.parse(JSON.stringify(question.results.grades)).reverse()[0]]
                        : t('driving.eval.not_valuated')
                }
            </b>
        </Col>
    </Row>
}