import React, { useEffect, useState } from "react";
import { Button, Row, Tooltip, Typography } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { ReactComponent as IconOnSite } from "../../../img/ic-small-xs-klatiene.svg";

const CheckpointBubble = (props: any) => {
    const { number, info } = props;

    const [status, setStatus] = useState("upcoming" as string);

    useEffect(() => {
        const foreignLesson = info.foreign_lesson;
        const foreignLessonAttained = (foreignLesson && info.attained) && !info.skipped
        const foreignLessonSkipped = (foreignLesson && info.skipped) && !info.attained
        setStatus(props.status)
        if (foreignLesson) {
            setStatus("foreign")
        }
        if (foreignLessonAttained) {
            setStatus("completed")
        }
        if (foreignLessonSkipped) {
            setStatus("missed")
        }

    }, [props])

    const style = (status: string) => {
        return {
            fontSize: 14,
            display: "flex",
            justifyContent: "center",
            zIndex: 10,
            alignItems: "center",
            fontWeight: 600,
            width: 32,
            height: 32,
            borderRadius: "50%",
            border: status === "completed" ? "1px solid #E0E6EC" : 0,
            color: status === "missed" ? "#FFFFFF" : status === "upcoming" || status === "eLesson" ? "#8C8C8C" : "#000000",
            backgroundColor: status === "missed" || status === "foreign"
                ? status === "foreign"
                    ? "#ddce1d"
                    : "#C02227"
                : status === "upcoming" || status === "eLesson"
                    ? "#F0F2F5"
                    : "#FFFFFF",
            lineHeight: "20px"
        }
    };

    return (
        <Tooltip title={
            <>
                {info !== undefined
                    ? <>
                        <p>{info.name !== undefined ? info.name : <></>}</p>
                        <p>{info.lesson_date}</p>
                        <p>{info.time_from !== undefined ? info.time_from + " - " + info.time_to : <></>}</p>
                        {/* <b>{status}</b> */}
                    </>
                    : <></>}
            </>
        }>
            <div
                className="cp-bubble"
                style={style(status)}
            >
                {status === "completed" ? <CheckCircleTwoTone className="cp-check" twoToneColor="#52c41a" /> : <></>}
                {number}
            </div>
        </Tooltip>

    )
}

export const CheckpointHorizontal = (props: any) => {
    const { total, attended, done, skipped, foreignLessons } = props;
    const [cpBubbles, setCpBubbles] = useState([] as any);

    useEffect(() => {
        setCpBubbles([]);
        for (let i = 1; i <= total.length; i++) {
            setCpBubbles((data: any) => {
                return [
                    ...data,
                    {
                        ...total[i - 1],
                        normal_id: i
                    }
                ]
            })
        }
    }, [done]);

    useEffect(() => {
    }, [cpBubbles])

    return (
        <Row className="cp-container" justify="space-between" align="middle">
            <div className="cp-line" style={{ position: "absolute", top: 15 }}></div>
            {
                total?.map((lesson: any, index: any) => {
                    const foreign = foreignLessons.find((el: any) => el.lesson_counter === lesson.lesson_counter);
                    const lessonData = !!lesson.skipped
                        ? foreign || lesson
                        : lesson
                    const status = !!lesson.skipped
                        ? "missed"
                        : !!lesson.attained
                            ? "completed"
                            : "upcoming"
                    return <CheckpointBubble
                        key={index}
                        number={index + 1}
                        status={status}
                        info={lessonData}
                    />
                })
            }
            {/* {cpBubbles.map((point: any, index: any) => {

                const skip = skipped.some((el: any) => el === index + 1);
                const notStarted = attended.length === 0 && !done && skipped.length === 0 && foreignLessons.length === 0;
                if (foreignLessons.some((el: any) => el.lesson_counter === index + 1)) {
                    point = foreignLessons.find((el: any) => el.lesson_counter === index + 1)
                }
                if (index > done) {
                    return <CheckpointBubble
                        key={index}
                        number={index + 1}
                        status="upcoming"
                        info={point}
                    />
                }
                if (attended === null) {
                    return <CheckpointBubble
                        key={index}
                        number={index + 1}
                        status="completed"
                        info={point}
                    />
                }
                if (attended.includes(index + 1)) {
                    return <CheckpointBubble
                        key={index}
                        number={index + 1}
                        status="completed"
                        info={point}
                    />
                }
                return <CheckpointBubble
                    key={index}
                    number={index + 1}
                    status={skip ? `upcoming` : notStarted ? 'upcoming' : `missed`}
                    info={point}
                />
            })} */}
        </Row>
    )
}