import { Card, Collapse, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { http } from "../../helpers/http";
import { ChapterTitleCard } from "./ChapterTitleCard";
import { ModuleMiniCard } from "./ModuleMiniCard";

export const ModulesList = (props: any) => {
    const { t } = useTranslation();
    const { self } = props;

    const [modules, setModules] = useState(undefined as any);
    const [loading, setLoading] = useState(true);
    const [studentProgress, setStudentProgress] = useState([] as any);
    const [isFirstChapter, setIsFirstChapter] = useState(false);

    const fetchStudentProgress = async () => {
        await http.get(`/student-chapters/${self.id}`).then((response: any) => {
            //console.log("Student chapters: ", response.data.data)
            setStudentProgress(response.data.data)
        })
    };

    const getActiveChapter = (id: any) => {
        const chapter = studentProgress.find((el: any) => el.hash_id === id);
        if (chapter === undefined) return false;
        if (chapter?.finished === 1) return false;
        if (chapter?.finished !== 1) return true;
    };

    const checkIfFirstChapter = () => {
        const chapter = modules?.language_chapters?.some((el: any) => {
            return studentProgress.some((item: any) => {
                //console.log(item, el)
                return item.hash_id === el.hash_id
            });
        });
        //console.log(chapter)
        if (chapter === undefined) {
            setIsFirstChapter(true);
        } else {
            setIsFirstChapter(!chapter);
        }
        // setIsFirstChapter(chapter === undefined || chapter === false);
    }

    const getDoneChapter = (id: any) => {
        const chapter = studentProgress.find((el: any) => el.hash_id === id)
        //console.log(studentProgress, id)
        if (chapter === undefined) return false;
        if (chapter?.finished === 1) return true;
        if (chapter?.finished !== 1) return false;
    };

    useEffect(() => {
        fetchStudentProgress();
    }, [])

    useEffect(() => {
        if (props.active) {
            setModules(props.modules())
        } else {
            setModules(props.modules)
        }
    }, [props.modules]);

    useEffect(() => {
        console.log(modules)
        if (modules !== undefined) {
            setLoading(false)
        }
    }, [modules]);

    useEffect(() => {
        if (modules !== undefined) {
            checkIfFirstChapter();
        }
    }, [studentProgress, modules])

    return loading
        ? <Skeleton />
        : modules?.length > 0 || typeof modules === "object"
            ? props.active
                ? <Collapse
                    defaultActiveKey={['1']}
                    className="e-learning-card"
                    expandIcon={() => <></>}
                >
                    <Collapse.Panel
                        header={<ModuleMiniCard ind={modules.ind} module={modules} active />}
                        key={1}
                    >
                        {
                            modules.failed_exam
                                ? <></>
                                : modules.language_chapters.map((chapter: any, index: any) => {
                                    //console.log(chapter)
                                    const done = getDoneChapter(chapter.hash_id);
                                    const active = getActiveChapter(chapter.hash_id);
                                    const next = (isFirstChapter && index === 0)
                                        || (studentProgress[studentProgress.length - 1]?.hash_id === modules.language_chapters[index - 1]?.hash_id
                                            && studentProgress[studentProgress.length - 1]?.finished === 1)
                                    //console.log(next)
                                    return <ChapterTitleCard
                                        key={index}
                                        chapter={chapter}
                                        module={modules}
                                        categoryId={self.category_id}
                                        active={active}
                                        done={done}
                                        next={next}
                                    />
                                })
                        }
                        {
                            modules.failed_exam || modules.exam_questions_count === 0
                                ? <></>
                                : <ChapterTitleCard
                                    exam
                                    moduleID={modules.hash_id}
                                    next={modules.language_chapters.length === modules.language_chapters.filter((item: any) => {
                                        return studentProgress.some((el: any) => (el.hash_id === item.hash_id) && el.finished === 1)
                                    }).length
                                        && studentProgress[studentProgress.length - 1]?.finished === 1}
                                />
                        }

                    </Collapse.Panel>
                </Collapse>
                : props.doneChapters
                    ? modules.map((module: any, index: any) => {
                        return <Collapse
                            key={index}
                            className="e-learning-card"
                            expandIcon={() => <></>}
                        >
                            <Collapse.Panel
                                header={
                                    <ModuleMiniCard
                                        ind={module.ind}
                                        module={module}
                                        done={props.done}
                                    />
                                }
                                key={index}
                                style={{
                                    boxShadow: '0 3px 6px 0 rgba(64,96,128,0.1)',
                                    borderRadius: '2px',
                                    marginBottom: '4px'
                                }}
                            >
                                {
                                    module.language_chapters?.map((chapter: any, index: any) => {
                                        return <ChapterTitleCard
                                            key={index}
                                            chapter={chapter}
                                            categoryId={self.category_id}
                                            // active={active}
                                            module={module}
                                            done={true}
                                        // next={next}
                                        />
                                    })
                                }
                            </Collapse.Panel>
                        </Collapse>
                    })
                    : modules.map((module: any, index: any) => {
                        return <Card
                            className="e-learning-card"
                            key={index}
                        >
                            <ModuleMiniCard
                                ind={module.ind}
                                module={module}
                            // done={props.done}
                            />
                        </Card>
                    })
            : <b>{t('e_learning.no_available_modules')}</b>
}