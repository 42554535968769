import { Button, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReactComponent as GoBackArrow } from '../../../img/ic-goback.svg';

export const ElearningHeader = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Row className="e-learning-header" justify="space-between">
            <Row style={{ marginBottom: 10 }}>
                <Button
                    className="go-back-btn"
                    style={{ fontWeight: "bold", marginRight: 29 }}
                    icon={<GoBackArrow />}
                    onClick={() => history.goBack()}
                >
                </Button>
                <b style={{ lineHeight: "30px" }}>{t('navigation.e_learning')}</b>
            </Row>

            <Button
                onClick={() => history.push('/e-learning/questions')}
            >
                {t('navigation.asked_questions')}
            </Button>
        </Row>
    )
}