import { Row, Input, Col, Button, Radio, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { ReactComponent as IconClock } from "../../img/ic-time-left.svg";
import { CountDownTimer } from "./common/CountDownTimer";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';

export const VideoQuestion = (props: any) => {
    const { t } = useTranslation();
    const { question, setAnswerIsCorrect, fullScreen } = props;
    const [selectedAnswer, setAnswer] = useState(undefined as any);
    const [stopTimer, setStopTimer] = useState(false);
    const [timeIsUp, setTimeIsUp] = useState(false);
    const [correct, setCorrect] = useState(undefined as any);
    const [mobile, setMobile] = useState(false);
    const [allowTimer, setAllowTimer] = useState(false);
    const [imgLoaded, setImgLoaded] = useState([] as any);
    const [qImageLoaded, setQImageLoaded] = useState(false);
    const [loading, setLoading] = useState(false)

    const actionHandler = () => {
        if (correct !== undefined && !timeIsUp) {
            setCorrect(undefined);
            setAnswerIsCorrect(correct)
            setAllowTimer(false);
        }
        if (stopTimer) return;
        if (timeIsUp) {
            //console.log("THIS IS UP")
            setAnswerIsCorrect(false);
            setAllowTimer(false);
            return;
        }
        if (correct === undefined) {
            submitAnswer();
            // setAllowTimer(false);
            return;
        }
    }

    const submitAnswer = () => {
        setStopTimer(true);
        if (correct !== undefined) return;
        setLoading(true);
        http.post(`/set-chapter-answer`, { answer_id: selectedAnswer }).then((response: any) => {
            setCorrect(Number(response.data.data.correct) ? true : false);
            setLoading(false);
        }).catch(() => {
            message.error(t('errors.error'));
            setLoading(false);
        })
    };

    const QuestionSection = (props: any) => {

        useEffect(() => {
            if (question.img === null) {
                props.qImageLoaded(true);
            }
        }, [question])

        return <div className="question-wrap">
            {
                question.img !== null
                    ? <div className="img-wrap">
                        <img
                            src={process.env.REACT_APP_API_URL + question.public_image}
                            onLoad={() => {
                                props.qImageLoaded(true);
                            }}
                            onError={() => console.log("ITS ERROR")}
                        />
                    </div>
                    : <></>
            }
            {
                question[`description_${i18n.language}`] !== null
                    ? <div className="description-wrap">
                        <b>{question[`description_${i18n.language}`]}</b>
                    </div>
                    : <></>
            }
        </div>
    }

    useEffect(() => {
        //console.log("Question: ", question)
        setMobile(window.matchMedia("(max-width: 767px)").matches)

        window.addEventListener("resize", () => {
            setMobile(window.matchMedia("(max-width: 767px)").matches)
        });

        setImgLoaded(() => {
            const hasImages = question?.answers.filter((answer: any) => {
                return answer.img !== null
            }).map((item: any) => {
                return {
                    img: item.id,
                    loaded: false
                }
            });
            hasImages.push({ img: "qImage", loaded: false })
            return hasImages
        })
    }, []);

    useEffect(() => {
        if (qImageLoaded) {
            setImgLoaded((prev: any) => {
                if (prev.some((el: any) => el.img === "qImage")) {
                    const newArray = JSON.parse(JSON.stringify(prev)).map((item: any) => {
                        if (item.img === "qImage") {
                            return {
                                img: "qImage",
                                loaded: true
                            }
                        } else return item
                    })
                    return newArray
                }
                else return prev
            })
        }
    }, [qImageLoaded]);

    useEffect(() => {
        //console.log(imgLoaded)
        if (imgLoaded.length === 0 || imgLoaded.some((el: any) => el.loaded === false)) {
            console.log("HERE not loaded")
        }
        else {
            console.log("HERE loaded")
            setAllowTimer(true);
        }
    }, [imgLoaded])

    return <div
        className={`question-modal`}
        onClick={(e: any) => { e.stopPropagation(); }}
    >
        <div
            style={fullScreen ? { overflow: "scroll", height: "100%" } : {}}
            className={`question-card ${timeIsUp ? 'time-is-up' : ''}`}
            onClick={(e: any) => e.stopPropagation()}
        >
            {
                timeIsUp
                    ? <Row>
                        <Col span={24}>
                            <Row justify="center" style={{ marginBottom: 30 }}>
                                <div className="icon-round-wrap">
                                    <IconClock />
                                </div>
                            </Row>
                            <p style={{ textAlign: "center" }}>
                                {t('e_learning.time_remaining')}: <b style={{ color: "#CC3326" }}>00:00</b>
                            </p>
                            <h3>{t('e_learning.your_question_time_exceeded')}.</h3>
                            <button
                                className="btn-submit"
                                onClick={actionHandler}
                            >
                                {t('e_learning.start_from_begining')}
                            </button>
                        </Col>
                    </Row>
                    : <Row gutter={24} style={{ height: "100%", paddingBottom: fullScreen ? 100 : "" }}>
                        <Col md={9}>
                            <Row justify="space-between">
                                <p>{t('e_learning.control_question')}</p>
                                <p>{t('e_learning.time_remaining')}:&nbsp;
                                    {
                                        allowTimer
                                            ? <CountDownTimer
                                                seconds={question.answer_time_limit}
                                                stopTimer={stopTimer}
                                                setTimeIsUp={setTimeIsUp}
                                                start_time={moment().format("YYYY-MM-DD HH:mm:ss")}
                                                returnTime={() => { }}
                                            />
                                            : <></>
                                    }
                                </p>
                            </Row>
                            {
                                mobile && <QuestionSection qImageLoaded={(status: any) => setQImageLoaded(status)} />
                            }
                            <p><b>{question[`question_${i18n.language}`]}</b></p>
                            <Radio.Group
                                value={selectedAnswer}
                                className="answer-wrap"
                                style={{ width: "100%" }}
                                onChange={(e: any) => {
                                    if (correct !== undefined) return;
                                    setAnswer(e.target.value)
                                }}
                            >
                                {
                                    question.answers.map((answer: any, index: any) => {
                                        return <div
                                            key={index}
                                            className={`answer ${answer.id === selectedAnswer
                                                ? correct === undefined
                                                    ? "selected"
                                                    : correct
                                                        ? "correct"
                                                        : "incorrect"
                                                : ""
                                                }`}
                                            onClick={() => {
                                                if (correct !== undefined) return;
                                                setAnswer(answer.id)
                                            }}
                                        >
                                            <Radio value={answer.id} />
                                            <div className="answer-col">
                                                {
                                                    answer.img !== null
                                                        ? <div className="img-wrap">
                                                            <img
                                                                src={process.env.REACT_APP_API_URL + answer.public_image}
                                                                alt=""
                                                                onLoad={() => {
                                                                    //console.log("IMG loaded")
                                                                    setImgLoaded((prev: any) => {
                                                                        const newArray = JSON.parse(JSON.stringify(prev)).map((item: any) => {
                                                                            if (item.img === answer.id) {
                                                                                return {
                                                                                    img: item.img,
                                                                                    loaded: true
                                                                                }
                                                                            } else return item
                                                                        })
                                                                        return newArray;
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        : <></>
                                                }
                                                <p>{answer[`answer_${i18n.language}`]}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </Radio.Group>
                            {
                                correct === true
                                    ? <b className="warning green">{t('e_learning.submited_answer_true')}</b>
                                    : correct === false
                                        ? <b className="warning red">{t('e_learning.submited_answer_false')}</b>
                                        : <></>
                            }
                            <Button
                                style={{ marginBottom: fullScreen ? 40 : "" }}
                                className={`btn-submit ${selectedAnswer ? '' : 'disabled'}`}
                                onClick={actionHandler}
                                loading={loading}
                            >
                                {
                                    correct === true
                                        ? t('e_learning.continue_lesson')
                                        : correct === false
                                            ? t('e_learning.start_from_begining')
                                            : t('e_learning.submit_answer')
                                }
                            </Button>
                        </Col>
                        <Col md={15}>
                            {
                                mobile || <QuestionSection qImageLoaded={(status: any) => setQImageLoaded(status)} />
                            }
                        </Col>
                    </Row>
            }
        </div>
    </div>
}