import { Row, Tag } from 'antd';
import React, { useEffect, useState } from 'react';

export const ConnectionCheck = (props: any) => {
    const [isDisonnected, setIsDisconnected] = useState(false);

    const handleConnection = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            const webPing = setInterval(
                () => {
                    fetch('/connection-stability-check', {
                        mode: 'no-cors',
                    })
                        .then(() => {
                            console.log("fetched")
                            setIsDisconnected(false);
                            clearInterval(webPing);
                        }).catch(() => setIsDisconnected(true))
                }, 2000);
            return;
        }

        return setIsDisconnected(true);
    }

    useEffect(() => {
        console.log("mounted")
        window.addEventListener("online", handleConnection);
        window.addEventListener("offline", handleConnection);
        return () => {
            window.removeEventListener("online", handleConnection);
            window.removeEventListener("offline", handleConnection);
        }
    }, [])

    return <div>
        {
            isDisonnected
            && <Row justify='center'>
                <Tag color='red' style={{ fontSize: 16, marginBottom: 10 }}>
                    Nav savienojuma ar tīklu
                </Tag>
            </Row>
        }
        {props.children}
    </div>
}