import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Space, Tag } from 'antd';
import { ReactComponent as SteeringWheelLogo } from '../../img/ic-instructors-car.svg';
import { ReactComponent as CalendarLogo } from '../../img/ic-instructors-calendar.svg';
import { ReactComponent as LocationMarkerLogo } from '../../img/ic-instructors-location.svg';
import { ReactComponent as PriceLogo } from '../../img/ic-instructors-price.svg';
//@ts-ignore
import Flag from "react-flags";
import { useTranslation } from 'react-i18next';

export const InstructorCard = (props: any) => {
    const { t } = useTranslation();
    const [cityService, setCityService] = useState({} as any);
    const {
        instructor: s,
        callback,
        loading
    } = props;

    useEffect(() => {
        //console.log(s, props.filter)
        // console.log(s.city_services.find((el: any) => el.city_id === props.filter.city_id)?.meeting_place)
        // setCityService(() => {
        //     const cityService = s.city_services.filter((service: any) => {
        //         return service.city_id === props.filter?.city_id;
        //     })[0];
        //     return cityService !== undefined ? cityService.city_total_price : s.total_rate_real;
        // });
    }, [s])

    useEffect(() => {
        //console.log(s)
    }, [])

    const out = {
        onClick: () => callback(s.service_id, props.filter.city_id),
        // name: `${s.instructor.first_name} ${s.instructor.last_name}`,
        // transmission: s.transmission == "manual" ? "Manuālā" : `${t('common.transmission.auto')}`,
        // languages: s.instructor.languages.map((a: any) => a.value).join(", "),
        // meeting_place: ", " + s.city_services.find((el: any) => el?.city_id === props.filter?.city_id)?.meeting_place,
        // city: s.cities.find((el: any) => el?.id === props.filter?.city_id)?.value,
        // cost: `${Number(cityService)?.toFixed(2)} EUR`,
        // vehicle: s.vehicle,
        // total_rate_real: s.city_services.find((el: any) => el?.city_id === props.filter?.city_id)?.city_total_price,
        // // evening_rate_real: s.evening_rate_real,
        // nearest_time: s.nearest_time.filter((time: any) => {
        //     return time.city_id === props.filter?.city_id;
        // })[0],
        // languages_iso: s.instructor.languages
        name: s.name,
        transmission: s.transmission == "manual" ? t('common.transmission.manual') : t('common.transmission.auto'),
        languages: s.langs,
        meeting_place: s.meeting_place,
        city: s.city,
        cost: `${(s.total_rate)} EUR`,
        // cost: (Math.round(s.total_rate * 10) / 10).toFixed(2) + "EUR",
        vehicle: s.vehicle,
        // total_rate_real: s.total
        // evening_rate_real: s.evening_rate_real,
        nearest_time: s.nearest_date
        // languages_iso: s.instructor.languages
    };

    return <div className={`instructor-card-container ${props.type}`}>
        <Card
            className='instructor-card'
            loading={loading}
            hoverable={props.type === "inline-small" ? true : false}
            onClick={props.type === "inline-small" ? out.onClick : () => {}}
        >

            <Row>
                <Col span={24} className={`main-col ${props.type}`}>
                    <div className="top-container">
                        <div className="img-wrapper">
                            <img src={process.env.REACT_APP_API_URL + s.car_image} alt="" />
                        </div>
                        <div className="instructor-photo-wrapper">
                            <img src={process.env.REACT_APP_API_URL + s.image} alt="" />
                        </div>
                    </div>
                    <div className="card-container">
                        <h3>
                            {out.name}
                            {/* {s.instructor.services.find((el: any) => el.id === s.id).free_slot_count} */}
                            <span className="flags">
                                {out.languages?.map((lang: any) => {
                                    const iso = lang === "en" ? "gb" : lang;
                                    return <Flag
                                        key={lang}
                                        basePath={`${process.env.REACT_APP_API_URL}storage/flags`}
                                        format="svg"
                                        name={iso}
                                    />
                                })}
                            </span>
                        </h3>
                        <Row className='param-row'>
                            <div className="icon-wrap"><SteeringWheelLogo /></div>
                            <Col className='param-col'>
                                <span className='param-title'>{t('instructor_card.vehicle')}</span>
                                <b className='param'>
                                    {out.vehicle}
                                    <Tag color={out.transmission === "Manuālā" ? "#079DC1" : "#6D7278"}>{out.transmission}</Tag>
                                </b>
                            </Col>
                        </Row>
                        <Row className='param-row'>
                            <div className="icon-wrap"><CalendarLogo /></div>
                            <Col className='param-col'>
                                <span className='param-title'>{t('instructor_card.nearest_available_time')}</span>
                                <b className='param'>
                                    {
                                        out.nearest_time
                                            ? out.nearest_time
                                            : t('instructor_card.not_available')
                                    }
                                </b>
                            </Col>
                        </Row>
                        <Row className='param-row'>
                            <div className="icon-wrap"><LocationMarkerLogo /></div>
                            <Col className='param-col'>
                                <span className='param-title'>{t('instructor_card.meeting_place')}</span>
                                <b className='param'>
                                    {
                                        out.city
                                            ? out?.city + " " + out?.meeting_place
                                            : t('instructor_card.not_available')
                                    }

                                </b>
                            </Col>
                        </Row>
                        <Row className='param-row'>
                            <div className="icon-wrap"><PriceLogo /></div>
                            <Col className='param-col'>
                                <span className='param-title'>{t('instructor_card.one_lesson_price')}</span>
                                <b className='param'>{out.cost}</b>
                            </Col>
                        </Row>
                        {/* <Row className='param-row'>
                            <div className="icon-wrap"><PriceLogo /></div>
                            <Col className='param-col'>
                                <span className='param-title'>Maksa par 1 mācību stundu pēc plkst. 18:00</span>
                                <b className='param'>{out.evening_rate_real?.toFixed(2)} EUR</b>
                            </Col>
                        </Row> */}
                        <Row>
                            <Button onClick={out.onClick} className="accept-button">{t('instructor_card.choose_this_instructor')}</Button>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Card>
    </div>
}