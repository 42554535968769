import { Button, Col, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import i18n from 'i18next';
import { OmnivaForm } from "./OmnivaForm";
import { MedicalForm } from "./MedicalForm";
import { PmpForm } from "./PmpForm";
import { useTranslation } from "react-i18next";
import { BonusAdjustForm } from "../Payment/BonusAdjustForm";

type AddServiceModalProps = {
    type: "medical" | "pmp" | "book" | null,
    service: any;
    setService: (state: any) => void;
    self: any;
    amount: number;
    refresh: () => void;
    refreshBalance: () => void;
    groupID: number
}

const styles = {
    amountText: {
        fontSize: 14,
        fontWeight: 700,
    },
    numberInputBtn: {
        width: 38,
        height: 38,
        fontSize: 22,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#079DC1",
    }
}

export const AddServiceModal = ({ type, service, setService, self, amount, refresh, refreshBalance, groupID }: AddServiceModalProps) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [additionalPrice, setAdditionalPrice] = useState("");
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [finalPrice, setFinalPrice] = useState(0);
    const [bonusAmount, setBonusAmount] = useState("0");
    const [bonusAmountOmniva, setBonusAmountOmniva] = useState("0");

    const submitService = () => {
        setSubmit(true);
    }

    useEffect(() => {
        let price = 0;
        if (type === "book") {
            price = service.omniva.price
            if (service.omniva.hasOwnProperty("pivot")) {
                price = service.omniva.pivot.individual_price
            }
            console.log(price)
            setAdditionalPrice(String(price))
        }
        setFinalPrice(Number(amount))
    }, [service])

    return <Modal
        visible={service}
        onOk={() => setService(null)}
        onCancel={() => setService(null)}
        footer={
            <Button
                className="accept-button"
                style={{ width: "100%" }}
                onClick={() => {
                    submitService();
                }}
                disabled={disabledSubmit}
                loading={submit}
            >
                {t('common.buy_and_pay')} ({(finalPrice - Number(bonusAmount) + (additionalPrice ? Number(additionalPrice) - Number(bonusAmountOmniva) : 0)).toFixed(2)}&nbsp;EUR)
            </Button>
        }
    >
        <Row style={{ marginBottom: 30 }}>
            <Col span={24}>
                <h2 style={{ marginBottom: 30 }}>{service?.[`value_${i18n.language}`]}</h2>
            </Col>
            {
                type === "book"
                && <Col span={24}>
                    <Row
                        style={{
                            padding: "18px 20px",
                            marginBottom: 30,
                            borderRadius: 4,
                            backgroundColor: "#EFF2F5"
                        }}
                    >
                        <Col>
                            <Row justify="space-between">
                                <p>
                                    <b>
                                        {t('common.omniva_shipping')}
                                    </b>
                                </p>
                                <p>
                                    <b>
                                        {
                                            Number(bonusAmountOmniva) > 0
                                            && <s style={{ ...styles.amountText, marginRight: 10 }}>{Number(additionalPrice).toFixed(2)}</s>
                                        }
                                        <span style={{ ...styles.amountText, color: "#079DC1" }}>+ {(Number(additionalPrice) - Number(bonusAmountOmniva)).toFixed(2)} EUR</span>
                                    </b>
                                </p>
                            </Row>
                            <p>
                                {t('common.only_omniva_available')}
                            </p>
                        </Col>
                    </Row>
                    <BonusAdjustForm
                        amount={Number(additionalPrice)}
                        type="bonus_omniva"
                        student={self}
                        setBonusAmount={setBonusAmountOmniva}
                        bonusAmount={bonusAmountOmniva}
                    />
                </Col>
            }
            <Col span={12}>
                <span style={{ color: "#475766", marginBottom: 10, display: "inline-block" }}>{t('common.first_name')}, {t('common.first_name')}:</span>
            </Col>
            <Col span={12}>
                <b>{self.first_name} {self.last_name}</b>
            </Col>
            <Col span={12}>
                <span style={{ color: "#475766", marginBottom: 10, display: "inline-block" }}>{t('common.phone_number')}:</span>
            </Col>
            <Col span={12}>
                <b>{self.phone_number}</b>
            </Col>
        </Row>
        {
            type === "book"
                ? <OmnivaForm
                    service={service}
                    setDisabled={(state: boolean) => setDisabledSubmit(state)}
                    submit={submit}
                    destroyModal={() => setService(null)}
                    refresh={() => refresh()}
                    refreshBalance={() => refreshBalance()}
                    finalPrice={finalPrice}
                    omnivaBonus={Number(bonusAmountOmniva)}
                    setBonusBooks={setBonusAmount}
                    student={self}
                />
                : type === "medical"
                    ? <MedicalForm
                        service={service}
                        setDisabled={(state: boolean) => setDisabledSubmit(state)}
                        submit={submit}
                        destroyModal={() => setService(null)}
                        refresh={() => refresh()}
                        refreshBalance={() => refreshBalance()}
                    />
                    : type === "pmp"
                        ? <PmpForm
                            service={service}
                            setDisabled={(state: boolean) => setDisabledSubmit(state)}
                            submit={submit}
                            destroyModal={() => setService(null)}
                            refresh={() => refresh()}
                            refreshBalance={() => refreshBalance()}
                            student={self}
                            groupID={groupID}
                            finalPrice={finalPrice}
                            setBonus={setBonusAmount}
                        />
                        : <></>
        }
    </Modal>
}