import { Button, Card, Col, Divider, Modal, Row, Space } from "antd";
import { CalendarOutlined, EnvironmentOutlined, UserOutlined, PhoneOutlined, CarOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { http } from "../../../helpers/http";

export const ExamCard = (props: any) => {
    const { t } = useTranslation();
    const { examSlot } = props;
    const [loading, setLoading] = useState(false);

    const deleteExam = () => {
        Modal.confirm({
            title: "Atcelt nodarbību",
            content: "Apstiprinot izvelētā nodarbība tiks atcelta!",
            onOk: () => {
                setLoading(true);
                http.patch(`lessons/${examSlot[0]?.lesson_id}`).then((response: any) => {
                    setLoading(false);
                    window.location.reload();
                }).catch(() => {
                    setLoading(false);
                });
            }
        })
    }

    return <Card style={{ borderColor: "rgb(82, 196, 26)" }} title={
        <Row justify="space-between">
            <div>{t('driving.top_info_card.school_driving_exam')}</div>
            <Button loading={loading} onClick={deleteExam} danger>Atteikties</Button>
        </Row>
    }>
        {
            examSlot.map((slot: any) => {
                return <div key={slot.slot_id}>
                    <Row>
                        <Col span={12}>
                            <Row>
                                <Col style={{ display: "flex", alignItems: "center" }}>
                                    <CalendarOutlined style={{ fontSize: 26, marginRight: 20 }} />
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <span>{moment(slot.date).format("dddd, D. MMMM")}<br />{slot.time_from} - {slot.time_to}</span>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col style={{ display: "flex", alignItems: "center" }}>
                                    <EnvironmentOutlined style={{ fontSize: 26, marginRight: 20 }} />
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <span>{slot.meeting_place}<br />{slot.city}</span>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h3 style={{ margin: 0 }}>{t('driving.top_info_card.inspector')}:</h3>
                        </Col>
                        <Col span={12}>
                            <Space>
                                <UserOutlined style={{ fontSize: 26 }} />
                                <h3 style={{ margin: 0 }}>{slot.instructor_name}</h3>
                            </Space>
                        </Col>
                        <Col span={12}>
                            <Space>
                                <PhoneOutlined style={{ fontSize: 26 }} />
                                <a href={`tel:${slot.phone_number}`}><h3 style={{ margin: 0 }}>{slot.phone_number}</h3></a>
                            </Space>
                        </Col>
                        <Col span={24}>
                            <Space>
                                <CarOutlined style={{ fontSize: 26 }} />
                                <h3 style={{ margin: 0 }}>{slot.vehicle}, {slot.licence_plate}</h3>
                            </Space>
                        </Col>
                    </Row>
                </div>
            })
        }
    </Card>
}