import { Button, Card, Checkbox, Col, Layout, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";

export const NotificationSettings = (props: any) => {
    const { t } = useTranslation();
    const { student } = props;
    const [channels, setChannels] = useState({
        ...student.notification_channels
    });

    const saveChannels = async () => {
        await http.post(`set-student-notification-channels/${student.id}`, channels).then((response: any) => {
            message.success("Paziņojuma kanāli ir saglabāti")
        }).catch((err: any) => {
            message.error("Kļūda!");
        })
    };

    useEffect(() => {
        //console.log(channels)
    }, [channels]);

    return <Layout className="main-content">
        <Card
            title={t('notification.student_notifications_settings')}
            style={{ marginBottom: 20 }}
        >
            <Row>
                <Col span={24} style={{ paddingBottom: 10 }}>
                    <b>Paziņojumu kanāli</b>
                </Col>
                {
                    Object.keys(channels).filter((el: any) => el !== "db").map((key: any) => {
                        return <div key={key} style={{ marginRight: 15 }}>
                            <span>{t(`notification.channels.${key}`)}:</span>
                            <Checkbox
                                checked={channels[key]}
                                style={{ marginLeft: 5 }}
                                onChange={(e: any) => {
                                    setChannels((prev: any) => {
                                        return {
                                            ...prev,
                                            [key]: e.target.checked ? 1 : 0
                                        }
                                    })
                                }}
                            />
                        </div>
                    })
                }

            </Row>
            <Row justify="end">
                <Button type="primary" onClick={saveChannels}>
                    Saglabāt
                </Button>
            </Row>
        </Card>
    </Layout>
}