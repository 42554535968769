import { Button, Card, Col, Divider, Input, Layout, Row, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import CoinImage from "../../img/einstein-coin.png";
import LinkImage from "../../img/link-icon-64px.svg";
import CoinsImage from "../../img/3-einstein-coins.svg";
import SteeringIcon from "../../img/steering-wheel-icon-64px.svg";
import CoinImage40 from "../../img/einstein-coin-40px.png";
import Link40 from "../../img/link-icon-40px.svg";
import Stickman40 from "../../img/stickman-icon-40px.svg";
import EinsteinWallpaper from "../../img/einstein-wallpaper.png";
import SpendThumb from "../../img/how_spend_thumb.png";
import GetThumb from "../../img/where_get_thumb.png";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";
import moment from "moment";

type CoinsLayoutProps = {
    refreshBalance: () => void;
    bonus: string;
    student: any;
}

const styles = {
    miniCard: {
        display: "inline-flex",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: 10,
        padding: "40px 24px",
        border: "1px solid #CED4D9",
        borderRadius: 4,
        boxShadow: " 0px 3px 6px 0px rgba(64, 96, 128, 0.10)",
        width: "100%",
        height: "100%"
    } as React.CSSProperties,
}

export const CoinsLayout = ({ ...props }: CoinsLayoutProps) => {
    const { t } = useTranslation();
    const [code, setCode] = useState('' as any);
    const [email, setEmail] = useState('' as any);
    const [uniqueRefHash, setUniqueRefHash] = useState('' as any);
    const [history, setHistory] = useState([] as any);

    const columns = [
        {
            title: t('common.date'),
            dataIndex: "created_at",
            render: (date: string) => {
                return moment(date).format("DD.MM.YYYY")
            }
        },
        {
            title: t('common.email'),
            dataIndex: "email",
        },
        {
            title: t('common.revenue'),
            dataIndex: "amount",
            render: (amount: string) => {
                return <div>
                    <img style={{ width: 20, height: 20, marginRight: 8 }} src={CoinImage} alt="Einstein coins" />
                    <span style={{ color: "#059DC1", fontWeight: "bold" }}>+{amount} EIN</span>
                </div>
            }
        },
        {
            title: t('common.status'),
            dataIndex: "claimed",
            render: (claimed: 1 | 0) => {
                return claimed === 1 ? <span style={{ color: "#1A804D" }}>{t('common.accepted')}</span> : <span style={{ color: "orange" }}>{t('common.awaiting_accept')}</span>
            }
        }
    ]

    const assignScholarship = () => {
        if (code === '') return;
        http.post('assign-scholarship', { code }).then((response: any) => {
            if (response.data.data === "wrong_code") return message.error(t('errors.wrong_code'));
            console.log(response)
            props.refreshBalance();
            setCode('');
            message.success(t('bonus.bonus_assigned'))
        }).catch((error: any) => {
            message.error(t('errors.error'));
        });
    }

    const sendAffiliateRequest = () => {
        if (email === '') return;
        http.post('send-affiliate-request', { email }).then((response: any) => {
            setEmail('');
            message.success(t('bonus.request_sent'))
        }).catch((error: any) => {
            message.error(t('errors.error'));
        });
    }

    const getUniqueRefHash = () => {
        http.get('get-affiliate-hash').then((response: any) => {
            setUniqueRefHash((process.env.REACT_APP_STUDENT_URL ?? "https://student.einsteins.lv/") + "ref/" + response.data);
        }).catch((error: any) => {
            message.error(t('errors.error'));
        });
    }

    const getHistory = () => {
        http.get('get-my-affiliates').then((response: any) => {
            console.log(response.data);
            setHistory(response.data.data);
        }).catch((error: any) => {
            message.error(t('errors.error'));
        });
    }

    useEffect(() => {
        getUniqueRefHash();
        getHistory();
    }, [])

    return <Layout className="main-content">
        <Card style={{ marginBottom: 32 }} bodyStyle={{ padding: 32 }}>
            <h2>{t('bonus.earn_bonus')}</h2>
            <Row gutter={16}>
                <Col span={24} lg={8}>
                    <div style={styles.miniCard}>
                        <img style={{ width: 64, height: 64, marginBottom: 20 }} src={LinkImage} alt="Link icon" />
                        <div>
                            <h3 style={{ fontWeight: "bold", marginBottom: 8, textAlign: "center" }}>{t('bonus.invite_friend')}</h3>
                            <p style={{ margin: 0 }}>{t('bonus.invite_friend_content')}</p>
                        </div>
                    </div>
                </Col>
                <Col span={24} lg={8}>
                    <div style={styles.miniCard}>
                        <img style={{ width: 64, height: 64, marginBottom: 20 }} src={CoinsImage} alt="3 Coins icon" />
                        <div>
                            <h3 style={{ fontWeight: "bold", marginBottom: 8, textAlign: "center" }}>{t('bonus.get_einsteins')}</h3>
                            <p style={{ margin: 0 }}>{t('bonus.get_einsteins_content')}</p>
                        </div>
                    </div>
                </Col>
                <Col span={24} lg={8}>
                    <div style={styles.miniCard}>
                        <img style={{ width: 64, height: 64, marginBottom: 20 }} src={SteeringIcon} alt="Steering wheel icon" />
                        <div>
                            <h3 style={{ fontWeight: "bold", marginBottom: 8, textAlign: "center" }}>{t('bonus.spend_expedient')}</h3>
                            <p style={{ margin: 0 }}>{t('bonus.spend_expedient_content')}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
        <Card
            bodyStyle={{ padding: "32px" }}
            style={{ marginBottom: 32 }}
        >
            <Row style={{ padding: 24, background: "#CED4D9", marginBottom: 8 }} justify="space-between" align="middle">
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <img style={{ width: 40, height: 40, marginRight: 16 }} src={CoinImage40} alt="Einstein coins" />
                    <div>
                        <p style={{ margin: 0 }}>{t('common.in_your_account')}</p>
                        <h3 style={{ fontWeight: "bold", marginBottom: 0 }}>{props.bonus} EIN</h3>
                    </div>
                </div>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                    {/* <Button>
                        {t('common.how_to_earn')}
                    </Button>
                    <Button
                        style={{ marginLeft: 4 }}
                    >
                        {t('common.where_to_spend')}
                    </Button> */}
                </div>
            </Row>
            {
                props.student?.category?.use_affiliate === 1
                && <Row
                    style={{ padding: 24, border: "1px solid #CED4D9", marginBottom: 8, boxShadow: " 0px 3px 6px 0px rgba(64, 96, 128, 0.10)" }}
                    justify="space-between"
                    align="middle"
                >
                    <Col span={24} lg={12} style={{ display: "inline-flex", alignItems: "center", marginBottom: 10 }}>
                        <img style={{ width: 40, height: 40, marginRight: 16 }} src={Link40} alt="unique link" />
                        <div>
                            <h3 style={{ fontWeight: "bold", marginBottom: 0 }}>{t('bonus.unique_link')}</h3>
                            <p style={{ margin: 0 }}>{t('bonus.invite_friends_and_get')}</p>
                        </div>
                    </Col>
                    <Col style={{ display: "inline-flex", alignItems: "center", width: 300 }}>
                        <div style={{ position: "relative" }}>
                            <Input
                                style={{ width: 300, height: 38, backgroundColor: "#F0F2F5", paddingRight: 80 }}
                                value={uniqueRefHash}
                            />
                            <Button
                                type="primary"
                                style={{ position: "absolute", right: 3, top: 3 }}
                                onClick={() => { try { navigator.clipboard.writeText(uniqueRefHash); message.success('Saite nokopēta') } catch (e) { message.error('Neizdevās nokopēt saiti') } }}
                            >
                                {t('common.copy')}
                            </Button>
                        </div>

                    </Col>
                </Row>
            }
            {
                !!!props.student?.has_promo
                && <Row style={{ padding: 24, border: "1px solid #CED4D9", marginBottom: 8, boxShadow: " 0px 3px 6px 0px rgba(64, 96, 128, 0.10)" }} justify="space-between" align="middle">
                    <Col span={24} lg={12} style={{ display: "inline-flex", alignItems: "center", marginBottom: 10 }}>
                        <img style={{ width: 40, height: 40, marginRight: 16 }} src={Link40} alt="unique code" />
                        <div>
                            <h3 style={{ fontWeight: "bold", marginBottom: 0 }}>{t('bonus.write_code')}</h3>
                            <p style={{ margin: 0 }}>{t('bonus.write_code_for_bonus')}</p>
                        </div>
                    </Col>
                    <Col style={{ display: "inline-flex", alignItems: "center", width: 300 }}>
                        <div style={{ position: "relative" }}>
                            <Input
                                style={{ width: 300, height: 38, backgroundColor: "#F0F2F5", paddingRight: 115 }}
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            <Button
                                type="primary"
                                style={{ position: "absolute", right: 3, top: 3 }}
                                onClick={assignScholarship}
                                disabled={code === ''}
                            >
                                {t('common.confirm')}
                            </Button>
                        </div>

                    </Col>
                </Row>
            }
            {
                props.student?.category?.use_affiliate === 1
                && <Row style={{ padding: 24, border: "1px solid #CED4D9", marginBottom: 32, boxShadow: " 0px 3px 6px 0px rgba(64, 96, 128, 0.10)" }} justify="space-between" align="middle">
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                        <img style={{ width: 40, height: 40, marginRight: 16 }} src={Stickman40} alt="invite friend" />
                        <div>
                            <h3 style={{ fontWeight: "bold", marginBottom: 0 }}>{t('bonus.invite_with_email')}</h3>
                            <p style={{ margin: 0 }}>{t('bonus.invite_friends_and_get')}</p>
                        </div>
                    </div>
                    <Col style={{ display: "inline-flex", alignItems: "center", width: 300 }}>
                        <div style={{ position: "relative" }}>
                            <Input
                                style={{ width: 300, height: 38, backgroundColor: "#F0F2F5", paddingRight: 105 }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                            />
                            <Button
                                type="primary"
                                style={{ position: "absolute", right: 3, top: 3 }}
                                onClick={sendAffiliateRequest}
                                disabled={email === ''}
                            >
                                {t('bonus.invite')}
                            </Button>
                        </div>

                    </Col>
                </Row>
            }
            <h2>{t('bonus.activity_history')}</h2>
            <Table
                columns={columns}
                dataSource={history}
            />
        </Card>
        <Card bodyStyle={{ padding: 32 }}>
            <h2>{t('bonus.earn_bonus')}</h2>
            <Row id="what_is_einsteins" gutter={16}>
                <Col span={24} md={18}>
                    <h2 style={{ fontWeight: "bold", marginBottom: 8 }}>{t('bonus.what_is_einsteins')}</h2>
                    <p>{t('bonus.what_is_einsteins_content')}</p>
                    <Tag color="blue" style={{ marginBottom: 20 }}>1 Einšteins = 1 EUR</Tag>
                </Col>
                <Col span={24} md={6}>
                    <img style={{ width: "100%" }} src={EinsteinWallpaper} alt="Einstein coin wallpaper with background" />
                </Col>
            </Row>
            <Divider />
            <Row id="where_spend" gutter={16}>
                <Col span={24} md={18}>
                    <h2 style={{ fontWeight: "bold", marginBottom: 8 }}>{t('bonus.where_spend_einsteins')}</h2>
                    <p>{t('bonus.where_spend_einsteins_content')}</p>
                </Col>
                <Col span={24} md={6}>
                    <img style={{ width: "100%" }} src={SpendThumb} alt="Where to spend thumbnail of manual image" />
                </Col>
            </Row>
            <Divider />
            <Row id="where_get" gutter={16}>
                <Col span={24} md={18}>
                    <h2 style={{ fontWeight: "bold", marginBottom: 8 }}>{t('bonus.where_get_einsteins')}</h2>
                    <p>{t('bonus.where_get_einsteins_content')}</p>
                </Col>
                <Col span={24} md={6}>
                    <img style={{ width: "100%" }} src={GetThumb} alt="Where to get thumbnail of manual image" />
                </Col>
            </Row>
        </Card>
    </Layout>
}