import { Button, Card, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { http } from '../../helpers/http';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';

export const RenewPassword = withRouter((props: any) => {
    const { t } = useTranslation();
    const [canSend, setCanSend] = useState(false);
    const [secret, setSecret] = useState(null);
    const [form] = Form.useForm();
    const captchaAPI = process.env.REACT_APP_RECAPTCHA ? process.env.REACT_APP_RECAPTCHA : ""

    const submitHandler = () => {
        form.validateFields().then( async (values: any) => {
            await http.post(`/reset-password`, { email: values.email, secret: secret }).then((response: any) => {
                if (response.data.status === "error") {
                    message.error(t('error.error'))
                } else {
                    message.success(t('login.new_password.password_was_sent_to_email'))
                }
            }).catch(() => {
                message.error(t('error.error'))
            })
        });
    }

    const captchaHandler = (e: any) => {
        setSecret(e)
        if (e !== null) {
            setCanSend(true);
        } else {
            setCanSend(false);
        }
    }

    return (
        <Card title={<b>{t('login.new_password.renew_password')}</b>} className='login-form'>
            <Form form={form} name="login_form" onFinish={submitHandler} layout="vertical">
                <Form.Item
                    name="email"
                    label={t('login.e_mail')}
                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: t('login.new_password.please_type_right_email'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <ReCAPTCHA
                    sitekey={captchaAPI}
                    onChange={captchaHandler}
                />
                <Button disabled={!canSend} style={{ marginTop: 15 }} htmlType='submit' type="primary">{t('login.new_password.confirm')}</Button>
            </Form>
        </Card>
    )
})
