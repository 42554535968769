import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { DrivingStats } from "./DrivingStats";
import { MapPoints } from "./MapPoints/MapPoints";
import { GoogleMap, useLoadScript, Marker, Polyline, LoadScriptProps } from "@react-google-maps/api";
import IcMarkerA from "../../../img//marker-a.svg";
import IcMarkerB from "../../../img/marker-b.svg";
import IcFail from "../../../img/comment-base.svg"
import { useTranslation } from "react-i18next";
import { http } from "../../../helpers/http";
import moment from "moment";
//@ts-ignore
import Geocode from "react-geocode";

const libraries: LoadScriptProps['libraries'] = ['geometry']

export const Map = (props: any) => {
    const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "", libraries });

    // const decodeFun = (code: string) => {
    //     const decode = google.maps.geometry.encoding.decodePath(code);
    //     return decode;
    // }

    return !isLoaded
        ? <div>Loading</div>
        : <GoogleMap
            zoom={13}
            center={
                props.points.length === 0
                    ? props.routes?.length > 0
                        ? { lat: Number(props.routes[0]?.start_lat), lng: Number(props.routes[0]?.start_lng) }
                        : { lat: 56.942872, lng: 24.097699 }
                    : { lat: Number(props.points[props.points.length - 1].lat), lng: Number(props.points[props.points.length - 1].lng) }
                // props.path.length === 0
                //     ? { lat: 56.942872, lng: 24.097699 }
                //     : props.path[props.path.length - 1]
            }
            mapContainerClassName="map-container"
            options={{ mapId: "98226c7c40bea12e", disableDefaultUI: true }}
        >
            {
                props.routes?.length > 0
                && <>
                    <Marker
                        position={{ lat: Number(props.routes[0]?.start_lat), lng: Number(props.routes[0]?.start_lng) }}
                        icon={{
                            url: IcMarkerA,
                            size: new google.maps.Size(71, 71),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(17, 34),
                            scaledSize: new google.maps.Size(40, 40)
                        }}
                    />
                    <Marker
                        position={{ lat: Number(props.routes[props.routes.length - 1]?.end_lat), lng: Number(props.routes[props.routes.length - 1]?.end_lng) }}
                        icon={{
                            url: IcMarkerB,
                            size: new google.maps.Size(71, 71),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(17, 34),
                            scaledSize: new google.maps.Size(40, 40)
                        }}
                    />
                </>
            }
            {
                props.routes?.map((route: any, index: any) => {
                    return <Polyline
                        key={index}
                        path={route.polyline}
                        options={{ strokeColor: "#34c6eb", strokeOpacity: 1.0, strokeWeight: 2 }}
                    />
                })
            }
            {/* {
                <Polyline
                    path={props.path}
                    options={{ strokeColor: "#34c6eb", strokeOpacity: 1.0, strokeWeight: 2 }}
                />
            } */}
            {
                props.points?.map((fail: any, index: any) => {
                    return <Marker
                        key={index}
                        position={{ lat: Number(fail.lat), lng: Number(fail.lng) }}
                        icon={{
                            url: IcFail,
                            size: new google.maps.Size(30, 30),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(15, 15),
                            scaledSize: new google.maps.Size(30, 30)
                        }}
                        label={{
                            text: String(index + 1),
                            color: "#FFFFFF",
                            fontWeight: "bold"
                        }}
                    />
                })
            }
        </GoogleMap>
}

export const MapSection = (props: any) => {
    const { t } = useTranslation();
    const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "", libraries });
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEO_API_KEY ?? "");
    Geocode.setLanguage("lv");
    Geocode.setRegion("lv");
    Geocode.setLocationType("ROOFTOP");
    const { isMobile, lesson } = props;
    const [allRoutes, setAllRoutes] = useState(null as any);
    const [loading, setLoading] = useState(false);
    const [addresses, setAddresses] = useState(["", ""]);
    const [distance, setDistance] = useState(0);

    const getDistance = (path: []) => {
        console.log(path)
        setDistance(google.maps.geometry.spherical.computeLength(path))
    }

    const getAddress = async (pointCoords: { lat: number, lng: number }) => {
        let address
        await Geocode.fromLatLng(pointCoords.lat, pointCoords.lng).then(
            (response: any) => {
                address = response.results[0].formatted_address;
            },
            (error: any) => {
                console.error(error);
            }
        );

        return address;
    }

    const processAddresses = async (data: any) => {
        let address = {
            start_address: null as string | null,
            end_address: null as string | null
        }
        if (data && data?.route_decoded && data?.route_decoded.length > 0) {
            if (!data.route_decoded[0].start_address) {
                address.start_address = await getAddress({ lat: Number(data.route_decoded[0].start_lat), lng: Number(data.route_decoded[0].start_lng) }) ?? null
                setAddresses((prev: any) => {
                    const copy = [...prev];
                    copy[0] = address.start_address;
                    return copy;
                })
            } else {
                setAddresses((prev: any) => {
                    const copy = [...prev];
                    copy[0] = data.route_decoded[0].start_address;
                    return copy;
                })
            }
            if (!data.route_decoded[data.route_decoded.length - 1].end_address) {
                address.end_address = await getAddress({ lat: Number(data.route_decoded[data.route_decoded.length - 1].end_lat), lng: Number(data.route_decoded[data.route_decoded.length - 1].end_lng) }) ?? null
                setAddresses((prev: any) => {
                    const copy = [...prev];
                    copy[1] = address.end_address;
                    return copy;
                })
            } else {
                setAddresses((prev: any) => {
                    const copy = [...prev];
                    copy[1] = data.route_decoded[data.route_decoded.length - 1].end_address;
                    return copy;
                })
            }
            if (address.start_address || address.end_address) {
                const newRoutes = [...data.route_decoded];
                newRoutes[0] = {
                    ...newRoutes[0],
                    start_address: address.start_address
                }
                newRoutes[newRoutes.length - 1] = {
                    ...newRoutes[newRoutes.length - 1],
                    end_address: address.end_address
                }
                saveAddress(newRoutes, data.id)
            }
        }
    }

    const saveAddress = (route: any, routeID: number) => {
        http.post(`update-driving-route/${routeID}`, { route })
    }

    const getRoute = () => {
        setLoading(true);
        http.get(`get-driving-route/${lesson?.student_lesson_id}`).then((res: any) => {
            if (!!!res.data.data?.route_decoded || res.data.data?.route_decoded.length === 0) {
                http.post(`call-driving-route-import/${lesson.instructor_service_id}/${lesson.student_lesson_id}`,
                    {
                        from: `${lesson.lesson_date} ${moment(lesson.time_from, "HH:mm").format("HH:mm:ss")}`,
                        to: `${lesson.lesson_date} ${moment(lesson.time_to, "HH:mm").format("HH:mm:ss")}`
                    }).then((response: any) => {
                        http.get(`get-driving-route/${lesson?.student_lesson_id}`).then((res2: any) => {
                            processAddresses(res2.data.data);
                            setAllRoutes(res2.data.data);
                            if (res2.data.data.route_decoded) {
                                getDistance(res2.data.data?.route_decoded?.reduce((prev: any, current: any) => {
                                    if (current.polyline) {
                                        return prev.concat(current.polyline)
                                    }
                                    return prev.concat([])
                                }, []))
                            }
                        })
                    })
            } else {
                getDistance(res.data.data.route_decoded.reduce((prev: any, current: any) => {
                    if (current.polyline) {
                        return prev.concat(current.polyline)
                    }
                    return prev.concat([])
                }, []))
                processAddresses(res.data.data);
                setAllRoutes(res.data.data)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getRoute();
    }, []);

    return loading
        ? <Skeleton />
        : allRoutes?.route_decoded !== null && allRoutes?.route_decoded?.length > 0 || lesson?.route_comments?.length > 0 ?
            <div>
                <h3><b>{t('driving.eval.lesson_map')}</b></h3>
                <div style={{ marginBottom: 30 }}>
                    <Map routes={allRoutes?.route_decoded} points={lesson.route_comments} />
                </div>
                {
                    allRoutes?.route_decoded !== null && allRoutes?.route_decoded?.length > 0
                    && <DrivingStats
                        route={{
                            start_address: addresses[0],
                            end_address: addresses[1],
                            distance: distance
                        }}
                    />
                }
                {
                    lesson.route_comments.length > 0
                    && <MapPoints
                        points={lesson.route_comments}
                    />
                }
            </div>
            : <h3 style={{ color: "grey" }}><b>{t("driving.eval.no_route_found")}</b></h3>
}