import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { ReactComponent as IcCalendar } from "../../../img/ic-calendar.svg";
import { ReactComponent as IcClock } from "../../../img/ic-clock.svg";
import { ReactComponent as IcUserBox } from "../../../img/ic-user-box.svg";
import { ReactComponent as IcA } from "../../../img/ic-a.svg";
import { InfoBadge } from "../../components/InfoBadge";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const MobileUpcomingLessons = (props: any) => {
    const { t } = useTranslation();
    const {
        lesson,
        setLessonToCancel
    } = props;
    const [showMore, setShowMore] = useState(false);

    const timeFrom = moment(lesson.time_from, "HH:mm:ss").format("HH:mm");
    const timeTo = moment(lesson.time_to, "HH:mm:ss").format("HH:mm");

    return <Row gutter={[10, 10]}>
        <Col span={12}>
            <InfoBadge
                Icon={IcCalendar}
                text={t('common.date')}
                data={moment(lesson.date, "YYYY-MM-DD").format("DD.MM.YYYY")}
            />
        </Col>
        <Col span={12}>
            <InfoBadge
                Icon={IcClock}
                text={t('driving.eval.time')}
                data={`${timeFrom} - ${timeTo}`}
            />
        </Col>
        {
            showMore
            && <>
                <Col span={12}>
                    <InfoBadge
                        Icon={IcUserBox}
                        text={t('driving.eval.instructor')}
                        data={lesson.instructor_name}
                    />
                </Col>
                <Col span={12}>
                    <InfoBadge
                        Icon={IcA}
                        text={t('driving.eval.starting_place')}
                        data={lesson.meeting_place}
                    />
                </Col>
            </>
        }
        <Col span={12}>
            <Button
                style={{ width: "100%" }}
                danger
                onClick={() => setLessonToCancel(lesson)}
            >
                {t('common.cancel')}
            </Button>
        </Col>
        <Col span={12}>
            <Button
                style={{ width: "100%" }}
                onClick={() => setShowMore(!showMore)}
            >
                {t('common.show_more')}
            </Button>
        </Col>
    </Row>
}