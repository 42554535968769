import { Button, Card, Col, Row } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { ReactComponent as NotificationsIcon } from '../../img/ic-notifications.svg';
import { Link } from "react-router-dom";
import { http } from "../../helpers/http";
import { Notification } from "../PlannerPage/Notification";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const ShortNotificationsCard = (props: any) => {
    const { t } = useTranslation();
    // const [notifications, setNotifications] = useState([] as any);
    // const [customNotifications, setCustomNotifications] = useState([] as any);
    const [lastNotific, setLastNotific] = useState({} as any);
    const [notificsToShow, setNotificsToShow] = useState([] as any);

    // const fetchNotifications = async () => {
    //     setLoading(true);
    //     await http.get('notifications-unread').then((response) => {
    //         setNotifications(response.data)
    //     })
    //     await http.get('get-custom-notifications').then((response) => {
    //         setCustomNotifications(response.data.data);
    //     })
    //     setLoading(false)
    // }

    const deleteNotification = (id: any, custom: boolean = false) => {
        props.fetchNotifications();
    }



    useEffect(() => {
        props.fetchNotifications();
    }, []);

    useEffect(() => {
        const notPaymentNotifications = [
            ...props.notifications.filter((notification: any) => {
                return notification.data.status === "success"
            }),
            ...props.customNotifications
        ]
        const paymentNotifications = props.notifications.filter((notification: any) => {
            return !(notification.data.status === "success")
        })
        const notificationToShow = notPaymentNotifications.find((el: any, index: any, self: any) => {
            return !self.some((notific: any) => {
                return moment(el.created_at).isBefore(moment(notific.created_at))
            })
        })
        setLastNotific(notificationToShow);
        setNotificsToShow(paymentNotifications);
    }, [props.notifications, props.customNotifications])

    return <Card>
        <Row justify="space-between" style={{ marginBottom: 15 }}>
            <Col>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <NotificationsIcon />
                    <b style={{ marginLeft: 8, fontSize: 17 }}>{t('common.notifications')}</b>
                    <Button
                        type="text"
                        onClick={() => {
                            props.fetchNotifications();
                        }}
                        style={{ height: "unset", width: "unset", padding: "5px 5px 0" }}
                        icon={<ReloadOutlined style={{ fontSize: 24 }} spin={props.loading} />}
                    />
                </div>
            </Col>
            <Col>
                <Link to="/notifications">
                    {t('common.open_all_notifications')} ({props.customNotifications.length + props.notifications.length})
                </Link>
            </Col>
        </Row>
        {
            lastNotific && lastNotific !== undefined
            && Object.keys(lastNotific).length > 0
            && <Notification
                key={lastNotific.id}
                deleteFromList={deleteNotification}
                notification={lastNotific}
                refreshBalance={() => props.refreshBalance()}
                refetch={props.refetch}
                custom={!lastNotific.hasOwnProperty('notifiable_id')}
                student={props.student}
            />
        }
        {
            notificsToShow.length > 0
            && <div>
                <b
                    style={{
                        display: "block",
                        marginBottom: 10,
                        paddingBottom: 10,
                        borderBottom: "1px solid #F0F0F0"
                    }}
                >
                    {t('common.payment_requests')}
                </b>
                {
                    notificsToShow.map((notific: any) => {
                        return <Notification
                            key={notific.id}
                            deleteFromList={deleteNotification}
                            notification={notific}
                            refreshBalance={() => props.refreshBalance()}
                            refetch={props.refetch}
                            student={props.student}
                        />
                    })
                }
            </div>
        }
    </Card>
}