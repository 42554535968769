import { Card, Row, Col, Tag, Button, Space, Divider, message, Spin } from "antd";
import { InfoCircleOutlined, EnvironmentOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { http } from "../../helpers/http";
import moment from "moment";
import { AxiosError } from "axios";

export const SlotOportunity = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { token } = useParams() as any;

    const [s, setS] = useState({} as any);
    const [signingUp, setSigningUp] = useState(false);
    const [balance, setBalance] = useState({} as any);
    const [cancelPercent, setCancelPercent] = useState(0)
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [slotTaken, setSlotTaken] = useState(false);
    const [error, setError] = useState("");
    // const price = 20;

    const fetchTimeSlot = async () => {
        await http.get(`tokenizer48hours/${token}`).then(async (response: any) => {
            //console.log(response.data.data)
            const student = response.data.data.student;
            const price = response.data.data.slot && response.data.data.slot.prices.reduce((prev: any, current: any) => {
                return prev + current.rate_total
            }, 0)
            //console.log(price)
            if (response.data.data.slot === null) {
                setSlotTaken(true);
                setLoading(false);
                return;
            }
            setS(() => {
                return {
                    ...response.data.data.slot,
                    price: price * response.data.data.slot.how_many_lessons
                }
            });

            if (student.hasOwnProperty('api_token')) {
                sessionStorage.setItem("bearer_token", student.api_token);
                http.defaults.headers.common["Authorization"] = `Bearer ${student.api_token}`;
                await http.get("balance").then((response: any) => {
                    setBalance(response.data.data)
                    //console.log(response)
                });
                await http.get('/get-student-cancel-school-percent').then((response: any) => {
                    //console.log(response)
                    setCancelPercent(Number(response.data.data))
                })
            }
            setLoading(false);
        }).catch((err: any) => {
            setIsError(true);
            setLoading(false);
        });
    };

    function signup() {
        const date = moment(s.date, "YYYY-MM-DD")
        const time = moment(s.time_from, "HH:mm:ss")
        if (date.isSame(moment(), "day")) {
            if (time.isSameOrAfter(moment().add(15, "minutes"), "minutes")) {
                setSigningUp(true);
                if (!signingUp) {
                    http.post(`lessons/${s.slot_id}`).then(() => {
                        if (props.isFirst) {
                            window.location.href = '/planner';
                        } else {
                            window.location.href = '/planner';
                        }
                    }).catch((err: AxiosError) => {
                        errorMessageResolver(err);
                        message.error(t('calendar.lesson_is_taken'))
                    });
                }
            } else {
                message.error(t('calendar.15min_before_lesson'))
            }
        } else {
            setSigningUp(true);
            if (!signingUp) {
                http.post(`lessons/${s.slot_id}`).then(() => {
                    if (props.isFirst) {
                        window.location.href = '/planner';
                    } else {
                        window.location.href = '/planner';
                    }
                }).catch((err: AxiosError) => {
                    errorMessageResolver(err);
                    message.error(t('calendar.lesson_is_taken'))
                });
            }
        }
    }

    const errorMessageResolver = (err: any) => {
        const { data, status } = err.response;
        if (status === 403) {
            if (data?.length > 0) {
                setError(`${t(`errors.${data[0].message_type}`)} ${data[0].info} ${t(`errors.error_activities.${data[0].reason}`)}`);
            }
        } else {
            setError("")
        }
    }

    useEffect(() => {
        fetchTimeSlot();
    }, []);

    useEffect(() => {
        //console.log(slotTaken)
    }, [slotTaken])

    return loading
        ? <Spin size="large" spinning={loading} />
        : isError && !slotTaken
            ? <Card>
                <Row justify="space-between">
                    <h1>Radās kļūda, vai saite vairs nav aktīva</h1>
                    <Button onClick={() => history.push('/')} type="primary">Ielogoties</Button>
                </Row>
            </Card>
            : slotTaken
                ? <Card>
                    <Row justify="space-between">
                        <h1>Nodarbība vairs nav pieejama</h1>
                        <Button onClick={() => window.location.href = '/planner'} type="primary">Atvērt plānotāju</Button>
                    </Row>
                </Card>
                : <Card
                    title={<h3 style={{ whiteSpace: "normal" }}>
                        {s.category_letter +
                            ` ${t('common.category')}, ` +
                            (s.transmission === "auto" ? t('common.transmission.auto') : t('common.transmission.manual')) +
                            ` ${t('common.transmission.transmission')}, ` + s.languages}
                    </h3>
                    }
                    className='slot-oportunity-form'
                >
                    {
                        error.length > 0
                        && <Tag color="red" style={{ marginBottom: 10, whiteSpace: "normal", width: "100%", textAlign: "center" }}>
                            {error}
                        </Tag>
                    }
                    <Row>
                        {
                            Number(s.price) <= Number(balance.balance)
                                ? <Col span={12}>
                                    {t('calendar.signup_for_driving')}
                                </Col>
                                : Number(s.price) <= Number(balance.current_credit)
                                    ? <Col span={12}>
                                        {t('calendar.signup_for_driving')}
                                    </Col>
                                    : <Col span={12}>
                                        <Tag style={{ marginBottom: 10 }} color="red">{t('common.not_enough_balance')}</Tag>
                                        <Button onClick={() => window.location.href = '/planner/payment-help'} style={{ marginBottom: 10 }} type="primary">{t('calendar.replenish')}</Button>
                                    </Col>
                        }

                        <Col span={12}>
                            <Space>
                                {/* {Number(props.balance.balance) + Number(props.balance.current_credit)} */}
                                <h3>{t('common.price')}:</h3>
                                <h3>{s.price} EUR</h3>
                            </Space>
                        </Col>
                        {
                            cancelPercent > 0
                            && <Tag style={{ marginBottom: 5, whiteSpace: "normal", textAlign: "center", width: "100%" }} color="warning">
                                {t('driving.warning.comission_warning_1')} {cancelPercent} % {t('driving.warning.comission_warning_2')}
                            </Tag>
                        }
                        <Tag style={{ whiteSpace: "normal", textAlign: "center" }} color="warning">
                            {t('driving.warning.24h_cancel_lesson')}
                        </Tag>
                    </Row>
                    {/* <Button onClick={() => console.log(s)}>SSS</Button> */}
                    <Divider />
                    <Space>
                        <span><EnvironmentOutlined /></span>
                        <span>{s?.meeting_places[s?.city_id][0]}, {s?.cities.find((el: any) => el.id === s?.city_id)?.value}</span>
                    </Space>
                    <Divider />
                    <Space>
                        <InfoCircleOutlined />
                        <span>{s?.comment}</span>
                    </Space>
                    <Divider />
                    <br />{s?.time_from} - {s?.time_to}
                    <br />{s?.date}
                    <Divider />
                    <Row justify="space-between">
                        {
                            Number(s.price) <= Number(balance?.balance)
                                ? <>
                                    <Button>{t('common.cancel')}</Button>
                                    <Button type="primary" onClick={signup}>{t('common.signup')}</Button>
                                </>
                                : Number(s.price) <= Number(balance?.current_credit)
                                    ? <>
                                        <Button>{t('common.cancel')}</Button>
                                        <Button type="primary" onClick={signup}>{t('common.signup')}</Button>
                                    </>
                                    : <></>
                        }
                    </Row>
                </Card>
}