import { Col, Row } from "antd";
import React from "react";

export const InfoBadge = (props: any) => {
    const { Icon, text, data } = props;
    return <Row style={{ flexWrap: "nowrap" }}>
        <Col style={{ paddingTop: 10 }}>
            <Icon />
        </Col>
        <Col style={{ paddingLeft: 12 }}>
            <p style={{ marginBottom: 0 }}><small>{text}</small></p>
            <b>{data}</b>
        </Col>
    </Row>
}