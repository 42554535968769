import { Button, Card, Col, Divider, message, Row, Select, Spin } from "antd";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosError, AxiosResponse } from 'axios';
import moment from "moment";

export const PmpCard = (props: any) => {
    const { student } = props;
    const { t } = i18next;
    // const [relatedGroups, setRelatedGroups] = useState({});
    const [pmpGroup, setPmpGroup] = useState(undefined as any);
    const [commonServices, setCommonServices] = useState([] as any);
    const [paid, setPaid] = useState(undefined as boolean | undefined);
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([] as any);
    const [group, setGroup] = useState(undefined as any);
    const [serviceStudyGroup, setServiceStudyGroup] = useState(undefined as any);
    const [extraPmps, setExtraPmps] = useState(undefined as any);
    const [loadingGroups, setLoadingGroups] = useState(false);

    const fetchMedicalGroup = async (id: any) => {
        setLoading(true);
        await http.get(`get-student-medical/${student.id}/${id}`).then((response) => {
            setLoading(false);
            const { medical_groups } = response.data.data;
            const { data } = response.data;
            let relation = data.relations.reverse().find((el: any) => el.main);
            let medicalGroup: any;
            if (extraPmps.length > 0) {
                const extraPmp = extraPmps?.[extraPmps.length - 1];
                if (extraPmp.medical_group_student_id !== null) {
                    relation = data.relations.find((el: any) => el.id === extraPmp.medical_group_student_id);
                } else {
                    relation = undefined;
                }
            }
            medicalGroup = medical_groups.length > 0 ? medical_groups.find((el: any) => el.id === relation?.medical_group_id) : undefined
            //console.log(medicalGroup)
            setPmpGroup(medicalGroup);
        }).catch((err: AxiosError) => {
            setLoading(false);
        });
    };

    const getExtraPmps = () => {
        http.get(`extra-pmps`).then((res: AxiosResponse) => {
            setExtraPmps(res.data)
        })
    }

    const fetchGroups = () => {
        setGroup(undefined);
        setLoadingGroups(true);
        http.get(`get-student-medical-groups`, {
            params: {
                no_paginate: 1,
                availableOnly: 1,
                fe: 1
            }
        }).then((res: AxiosResponse) => {
            setLoadingGroups(false);
            setGroups(res.data.data)
        }).catch((err: AxiosError) => {
            message.error("Kļūda piesakot grupu");
            setLoadingGroups(false);
        })
    };

    const fetchCommonServices = () => {
        http.get(`get-student-common-services/${student.id}`).then((res: AxiosResponse) => {
            setCommonServices(res.data)
        })
    };

    const saveGroup = () => {
        http.post(`medical-groups-attach/${student.id}/${serviceStudyGroup?.study_group_id}`, {
            medical_group: group,
            main: extraPmps?.length > 0 ? 0 : 1,
            extra_pmp: extraPmps?.length === 0 ? undefined : extraPmps?.[extraPmps.length - 1]?.id
        }).then(() => {
            getExtraPmps();
        }).catch((err: AxiosError) => {
            console.log(err.response?.data?.msg)
            const { msg } = err.response?.data;
            fetchCommonServices();
            fetchGroups();
        })
    };

    useEffect(() => {
        getExtraPmps();
    }, []);


    useEffect(() => {
        if (extraPmps !== undefined) {
            fetchGroups();
            fetchCommonServices();
        }
    }, [extraPmps]);

    useEffect(() => {
        if (commonServices.length > 0) {
            const mainService = commonServices?.findLast((cService: any) => {
                return cService.study_group_service_type_id === 6
            }) as any;
            if (mainService === undefined) return;
            if (extraPmps.length > 0) {
                setPaid(!!extraPmps[extraPmps.length - 1].paid);
            } else {
                setPaid(!!mainService.paid)
            }
            setServiceStudyGroup(mainService);
            fetchMedicalGroup(mainService.study_group_id);
        }
    }, [commonServices])

    return pmpGroup !== undefined
        ? <Card
            title={t('theory.pmp_training')}
            style={{ marginTop: 20 }}
            loading={loading}
        >
            <Row
                gutter={24}
                style={{
                    padding: "16px 8px",
                    backgroundColor: "#F0F2F5"
                }}
            >
                <Col span={24}>
                    <b>{t('common.online_training')}:</b>
                    <p>{pmpGroup.online_date} {pmpGroup.online_from} - {pmpGroup.online_to}</p>
                    <p style={{ margin: 0 }}>{t('theory.online_training_link')}:</p>
                    {
                        pmpGroup?.zoom_link === undefined
                            ? ""
                            : <a href={pmpGroup?.zoom_link} target="_blank">{pmpGroup?.zoom_link}</a>
                    }
                    {
                        pmpGroup?.zoom_info === undefined
                            ? ""
                            : <div>
                                <p style={{ margin: 0 }}>{t('theory.additional_zoom_info')}:</p>
                                <span>{pmpGroup?.zoom_info}</span>
                            </div>
                    }
                </Col>
                <Col span={24} style={{ paddingTop: 8 }}>
                    <b>{t('theory.on_site_training')}:</b>
                    <p>{pmpGroup.medical_date} {pmpGroup.medical_from} - {pmpGroup.medical_to}</p>
                    <p>{t('common.address')}: {pmpGroup?.address === undefined ? "" : pmpGroup?.address}</p>
                </Col>
            </Row>
        </Card>
        : paid === undefined
            ? <></>
            : paid
                ? <Card
                    title={t('theory.pmp_training')}
                    style={{ marginTop: 20 }}
                    loading={loading}
                >
                    <Row
                        gutter={24}
                        style={{
                            padding: "16px 8px",
                            backgroundColor: "#F0F2F5"
                        }}
                    >
                        <div style={{ width: "100%" }}>
                            <p style={{ marginBottom: 10 }}>{t('groups.choose_pmp_group')}</p>
                            {
                                loadingGroups
                                    ? <Row justify="center"><Spin /></Row>
                                    : <Select
                                        value={group ? group : undefined}
                                        style={{ width: "100%", marginTop: 5, marginBottom: 10 }}
                                        onChange={(value: any) => {
                                            setGroup(value)
                                        }}
                                        onClick={e => e.stopPropagation()}
                                        loading={loading}
                                        optionLabelProp="label"
                                    >
                                        {
                                            groups.map((group: any) => {
                                                return <Select.Option key={group.id} value={group.id} label={<b>{group.name} {group.online_date}, {group.medical_date}</b>}>
                                                    <div style={{ fontStyle: "bold", padding: 4, border: "1px solid #f0f0f0" }}>
                                                        <p style={{ marginBottom: 2 }}>{group.name}</p>
                                                        <p style={{ marginBottom: 2 }}>1. nod. Tiešsaistē: {group.online_date} {moment(group.online_from, "HH:mm:ss").format("HH:mm")} {moment(group.online_to, "HH:mm:ss").format("HH:mm")}</p>
                                                        <p style={{ marginBottom: 2 }}>2. nod. Klātienē: {group.medical_date} {moment(group.medical_from, "HH:mm:ss").format("HH:mm")} {moment(group.medical_to, "HH:mm:ss").format("HH:mm")}</p>
                                                    </div>
                                                </Select.Option>
                                            })
                                        }
                                    </Select>
                            }
                            {
                                !loading && group
                                    ? <Button
                                        type="primary"
                                        onClick={saveGroup}
                                    >
                                        Saglabāt
                                    </Button>
                                    : <></>
                            }
                        </div>
                    </Row>
                </Card>
                : <></>
}