import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const FinalStep = (props: any) => {
    const { t } = useTranslation();
    const baseUrl = process.env.REACT_APP_API_URL;

    return <div>
        <h1>{t('tutorial.final_step.help')}</h1>
        <p>{t('tutorial.final_step.help_explanation')}</p>
        <div style={{ width: "100%", textAlign: "center" }}>
            <img src={baseUrl + "storage/student-tutorial/help-btn-view-tutorial.png"} style={{ width: "80%" }} />
        </div>
    </div>
}