import { Layout, Select, DatePicker, Space, Table, PageHeader, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import { StudentPaymentsPDFTable } from "./StudentPaymentsPDFTable";

export const StudentPaymentsPage = withRouter((props: any) => {
    const { t } = useTranslation()

    return (
        <Layout className="table-page">
            <PageHeader
                className="header-responsive"
                style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}
                title={
                    <>
                        <span style={{ marginLeft: 40 }}>{t('payments.payments')}</span>
                    </>
                }
            />
            <Layout.Content>
                <StudentPaymentsPDFTable self={props.self} />
            </Layout.Content>
        </Layout>
    );
});