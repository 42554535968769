import { Card, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProgressReel } from "./ProgressReel";

export const ProgressCard = (props: any) => {
    const { t } = useTranslation();
    const { modules, passedModules, theoryLessons, foreignLessons, showInstructions } = props;

    return <Card
        className="e-learning-card"
        title={
            <div>
                <b>{t('e_learning.theory_progress')}</b>
                {showInstructions && <p style={{ whiteSpace: "normal" }}>{t('e_learning.for_your_attention')}</p>}
            </div>
        }
    >
        <ProgressReel
            modules={modules}
            passedModules={passedModules}
            theoryLessons={theoryLessons}
            foreignLessons={foreignLessons}
        />
    </Card>
}