import { Card, Form, Input, Space, Button } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import React from "react";
import { Link } from "react-router-dom";


export const LoginForm = (props: any) => {
    const { error } = props;
    const { t } = useTranslation();
    return <Card title={t('login.login_to_student_portal')} className='login-form'>
        <Form name="login_form" onFinish={(values: any) => props.onLogin(values)} layout="vertical">
            <Form.Item name="email" label={t('login.e_mail')}>
                <Input />
            </Form.Item>
            <Form.Item name="password" label={t('login.password')} style={{ marginBottom: 12 }}>
                <Input type="password" />
            </Form.Item>
            {
                error
                    ? <div style={{ display: "flex", alignItems: "center" }}>
                        <ExclamationCircleFilled style={{ color: "#C02227", fontSize: 20 }} />
                        <span style={{ marginLeft: 10, color: "#C02227", fontSize: 14 }}>
                            {t('errors.bad_credentials')}
                        </span>
                    </div>
                    : <></>
            }
            <Form.Item style={{ marginTop: 20 }}>
                <Space>
                    <Button htmlType="submit" type="primary">{t('login.sign_in')}</Button>
                    {/* <Link to="/register"><Button type="default">Reģistrēties</Button></Link> */}
                    <Link to="/renew-password"><Button type="link">{t('login.forgot_password?')}</Button></Link>
                </Space>
            </Form.Item>
        </Form>
    </Card>
}