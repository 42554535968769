import { Row, Col, Select, message } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";
import { BonusAdjustForm } from "../Payment/BonusAdjustForm";

type OmnivaFormProps = {
    service: any;
    setDisabled: (state: boolean) => void;
    submit: boolean;
    destroyModal: () => void;
    refresh: () => void;
    refreshBalance: () => void;
    finalPrice: number;
    setBonusBooks: (state: string) => void;
    student: any;
    omnivaBonus?: number;
}

const styles = {
    amountText: {
        fontSize: 17,
        fontWeight: 700,
    },
    numberInputBtn: {
        width: 38,
        height: 38,
        fontSize: 22,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#079DC1",
    }
}

export const OmnivaForm = ({ service, setDisabled, submit, destroyModal, refresh, refreshBalance, finalPrice, setBonusBooks, student, omnivaBonus }: OmnivaFormProps) => {
    const { t } = useTranslation();
    const [omnivaCities, setOmnivaCities] = useState([] as any);
    const [omnivaCity, setOmnivaCity] = useState("");
    const [terminals, setTerminals] = useState([] as any);
    const [terminal, setTerminal] = useState("");
    const [bonusAmountBooks, setBonusAmountBooks] = useState("0");

    const fetchOmnivaCities = () => {
        http.get(`omniva-terminals/get-cities/LV`).then((res: AxiosResponse) => {
            setOmnivaCities(res.data)
        })
    }

    const fetchTerminals = async (city: string) => {
        await http.get(`/omniva-terminals/get-address/${city}`).then((response) => {
            setTerminals(response.data);
        });
    }

    const submitBook = async () => {
        http.post(`add-service-for-self`, {
            services: [
                {
                    service_id: service.id,
                    bonus_amount: bonusAmountBooks
                },
                {
                    service_id: service.omniva.id,
                    terminal: terminals.find((item: any) => {
                        return item.name === terminal
                    })?.id,
                    bonus_amount: omnivaBonus?.toFixed(2)
                }
            ]
        }).then((res: AxiosResponse) => {
            refreshBalance();
            refresh();
            message.success(t("notification.service_bought_sucessfully"))
        }).catch((err: AxiosError) => {
            const msg = err.response?.data.msg
            message.error(msg)
        })
        return true
    }

    useEffect(() => {
        fetchOmnivaCities();
    }, []);

    useEffect(() => {
        if (submit) {
            submitBook().then(() => { destroyModal() });
        }
    }, [submit]);

    useEffect(() => {
        if (terminal.length === 0) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [terminal])

    useEffect(() => {
        setBonusBooks(bonusAmountBooks)
    }, [bonusAmountBooks])

    return <Row gutter={[20, 30]}>
        <Col span={12}>
            <p style={{ marginBottom: 10 }}>{t('common.city')}</p>
            <Select
                showSearch
                value={omnivaCity}
                style={{ width: 200, marginTop: 5 }}
                onChange={(value: any) => { fetchTerminals(value); setOmnivaCity(value) }}
                onClick={e => e.stopPropagation()}
            >
                {omnivaCities.map((city: any) => {
                    if (city.city === "") return <Select.Option key={city.county} value={city.county}>{city.county}</Select.Option>
                    return <Select.Option key={city.city} value={city.city}>{city.city}</Select.Option>
                })}
            </Select>
        </Col>
        {terminals.length > 0
            ? <Col span={24} >
                <p style={{ marginBottom: 10 }}>{t('common.terminal')}</p>
                <Select
                    showSearch
                    value={terminal}
                    style={{ width: "100%", marginTop: 5 }}
                    onChange={(value: any) => setTerminal(value)}
                    onClick={e => e.stopPropagation()}
                >
                    {terminals.map((terminal: any) => {
                        return <Select.Option key={terminal.id} value={terminal.name}>{terminal.name}</Select.Option>
                    })}
                </Select>
            </Col>
            : <></>
        }
        <Col span={24}>
            <h3>
                {
                    Number(bonusAmountBooks) > 0
                    && <s style={{ ...styles.amountText, marginRight: 10 }}>{Number(finalPrice).toFixed(2)}</s>
                }
                <span style={{ ...styles.amountText, color: "#079DC1" }}>{(Number(finalPrice) - Number(bonusAmountBooks)).toFixed(2)}</span>
            </h3>
            <BonusAdjustForm
                amount={finalPrice}
                type="bonus_books"
                student={student}
                setBonusAmount={setBonusAmountBooks}
                bonusAmount={bonusAmountBooks}
            />
        </Col>
    </Row>
}