import { Card, Row, Tooltip } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import i18n, { t } from "i18next";

export const CheckpointsCard = (props: any) => {
    const { self } = props;
    const [checkpoints, setCheckpoints] = useState({} as any);

    const fetchCheckpoints = () => {
        if (Object.keys(self).length === 0) return;
        const group = self?.only_study_group.find((group: any) => {
            return group.category_id === self?.category_id
        })
        if (group === undefined) return;
        http.get(`get-student-checkpoints/${group.id}`).then((response: any) => {
            setCheckpoints({
                ...response.data.data,
                count: response.data.data.student_checkpoints.length,
                done: response.data.data.student_checkpoints.filter((el: any) => !!el.passed).length
            });
        })
    }

    useEffect(() => {
        fetchCheckpoints();
    }, [self]);

    return checkpoints?.student_checkpoints?.length > 0
        ? <Card style={{ marginBottom: 40 }}>
            <Row justify="space-between">
                <p>{t('checkpoints.till_license_remains')}</p>
                <p>{t('checkpoints.completed_done_from_count', { done: checkpoints?.done, count: checkpoints?.count })}</p>
            </Row>

            <div className="checkpoints-reel">
                <div className="divider"></div>
                {
                    checkpoints?.student_checkpoints?.map((point: any, index: any) => {
                        const title = checkpoints?.checkpoints?.find((el: any) => el.ind === index)?.[`value_${i18n.language}`]
                        return <Tooltip key={index} placement="topLeft" title={title}>
                            <div className={"checkpoint" + (!!point.passed ? " done" : "")}>
                                {!!point.passed && <CheckCircleTwoTone className="cp-check" twoToneColor="#52c41a" />}
                                {index + 1}
                            </div>
                        </Tooltip>
                    })
                }
            </div>
        </Card >
        : <></>
}