import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const SecondStep = (props: any) => {
    const { t } = useTranslation();
    const baseUrl = process.env.REACT_APP_API_URL;

    return <div>
        <h1>{t('tutorial.step_two.deposit_replenishment')}</h1>
        <p>
            {t('tutorial.step_two.deposit_explanation')}
            <img src={baseUrl + "storage/student-tutorial/plus-sign-tutorial.png"} style={{ width: 32, marginLeft: 10 }} />
        </p>
        <p>
            {t('tutorial.step_two.choose_payment_type')}
        </p>
        <ul>
            <li>{t('tutorial.step_two.payment_option_1')}</li>
            <li>{t('tutorial.step_two.payment_option_2')}</li>
            <li>{t('tutorial.step_two.payment_option_3')}</li>
            <li>{t('tutorial.step_two.payment_option_4')}</li>
        </ul>
        <div style={{ width: "100%" }}>
            <img src={baseUrl + "storage/student-tutorial/add-balance-page-tutorial.png"} style={{ width: "100%" }} />
        </div>
    </div>
}