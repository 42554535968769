import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { ConfigProvider } from 'antd';
import 'moment/locale/lv';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import lv from './locale/lv.json';
import en from './locale/en.json';
import ru from './locale/ru.json';
import moment from 'moment';
import { http } from './helpers/http';

moment.locale('lv')

const getLangs = async () => {
    await http.get('get-translations').then((response: any) => {
        //console.log(response)
        const { data } = response.data;
        i18n.addResourceBundle('en', 'translation', data[3].translation, true, true)
        i18n.addResourceBundle('lv', 'translation', data[1].translation, true, true)
        i18n.addResourceBundle('ru', 'translation', data[2].translation, true, true)
    })
    i18n.reloadResources();
}


i18n.use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en
            },
            lv: {
                translation: lv
            },
            ru: {
                translation: ru
            }
        },
        lng: "lv",
        fallbackLng: "lv",
        interpolation: {
            escapeValue: false
        }
    })


getLangs();

ReactDOM.render(
    <ConfigProvider>
        <App />
    </ConfigProvider>,
    document.getElementById('root')
);
