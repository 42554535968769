import { Calendar } from "antd"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { MobileSlotList } from "./MobileSlotList";
import { SignupModal } from "./SignupModal/SignupModal";

export const ExamSlot = (props: any) => {
    const { slot, price, pricesPolicy, tryNr, cancelHours } = props;
    const [signupModalVisible, setSignupModalVisible] = useState(false);
    const [truePrice, setTruePrice] = useState(price);

    useEffect(() => {
        setTruePrice(
            pricesPolicy?.free_till === null
                ? price
                : moment(pricesPolicy?.free_till, "YYYY-MM-DD").isSameOrAfter(moment(slot.exam_date, "YYYY-MM-DD"))
                    ? "0.00"
                    : price
        )
    }, [price, pricesPolicy])

    const handleModal = (visible: boolean) => {
        setSignupModalVisible(visible)
    }

    return <div className="theory-exam-slot">
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
                border: "1px solid #D4DADE",
                borderRadius: 3,
                // height: 22,
                marginBottom: 4,
                boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
                padding: "5px 10px"
            }}
            onClick={() => handleModal(true)}
        >
            <p style={{ fontSize: 11, margin: 0 }}>
                {moment(slot.time_from, "HH:mm:ss").format("HH:mm")} - {moment(slot.time_to, "HH:mm:ss").format("HH:mm")}
                &nbsp;{truePrice}&euro;
            </p>
        </div>
        <SignupModal
            modalVisible={signupModalVisible}
            setModalVisible={handleModal}
            slot={slot}
            price={truePrice}
            tryNr={tryNr}
            cancelHours={cancelHours}
        />
    </div>
}

export const ExamCalendar = (props: any) => {
    const { examSlots, updateSlots, price, pricesPolicy, studentExams, cancelHours } = props;

    const dateCellRender = (value: any) => {
        return examSlots.filter((slot: any) => {
            return slot.exam_date === moment(value).format("YYYY-MM-DD")
        }).sort((a: any, b: any) => {
            return moment(a.time_from, "HH:mm:ss").isAfter(moment(b.time_from, "HH:mm:ss")) ? 1 : -1
        }).map((item: any, index: any) => {
            return <ExamSlot
                updateSlots={(slot: any) => updateSlots(slot)}
                key={index}
                slot={item}
                price={price}
                pricesPolicy={pricesPolicy}
                tryNr={studentExams.length + 1}
                cancelHours={cancelHours}
            />
        });
    };

    return window.matchMedia("(max-width: 767px)").matches
        ? <MobileSlotList
            examSlots={examSlots}
            updateSlots={updateSlots}
            price={price}
            pricesPolicy={pricesPolicy}
        />
        : <Calendar
            dateCellRender={dateCellRender}
            value={props.selectedDay}
        />
}