import React from "react";
import { useTranslation } from "react-i18next";
import "../../../CSS/StudentEvaluation.scss";
import i18n from 'i18next';

export const EvaluationList = (props: any) => {
    const { t } = useTranslation();
    const { grades, subjects, exam } = props;

    const translation = {
        beginner: t('driving.eval.poor'),
        medium: t('driving.eval.almost_good'),
        exam_worthy: t('driving.eval.good'),
    } as any;

    return <div>
        {
            Object.keys(translation)?.map((key: any, index: any) => {
                return <ul className="student-eval-list" key={index}>
                    <li className={key}>
                        {translation[key]}
                        <div className={`questions-counter ${key}`}>
                            {grades[key]?.total ?? 0}
                        </div>
                    </li>
                    {
                        grades[key]?.subject_ids?.sort((a: any, b: any) => Number(a) > Number(b) ? 1 : -1).map((question: any, index: any) => {
                            const text = subjects.find((el: any) => el.id === question);
                            const ind = subjects.indexOf(subjects.find((el: any) => el.id === question));
                            const trueText = text?.[`content_${i18n.language}`]
                                ? text?.[`content_${i18n.language}`]
                                : text?.content_lv
                            return <li key={index}>
                                <p><span style={{ color: "#828E99" }}></span> {trueText}</p>
                            </li>
                        })
                    }
                </ul>
            })
        }
    </div>
}