import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const SixthStep = (props: any) => {
    const { t } = useTranslation();
    const baseUrl = process.env.REACT_APP_API_URL;

    return <div>
        <h1>{t('tutorial.step_six.driving_overview')}</h1>
        <p>
            {t('tutorial.step_six.driving_explanation')}
            <img src={baseUrl + "storage/student-tutorial/select-instructor-btn-tutorial.png"} style={{ width: 200, marginLeft: 10 }} />.
        </p>
        <div style={{ width: "100%" }}>
            <img src={baseUrl + "storage/student-tutorial/select-instructor-btn-view-tutorial.png"} style={{ width: "100%" }} />
        </div>
    </div>
}