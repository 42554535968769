import { Col, Divider, Row } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../../helpers/http";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';

export const SpecialManeuverList = (props: any) => {
    const { t } = useTranslation();
    const { lesson } = props;
    const [result, setResult] = useState([] as any);

    const fetchFigures = () => {
        http.get(`get-lesson-figures/${lesson}`).then((res: any) => {
            console.log(res)
            setResult(res.data.data)
        })
    }

    useEffect(() => {
        fetchFigures();
    }, [])

    return result.length > 0
        ? <div style={{ marginBottom: 30 }}>
            <h3><b>{t('driving.eval.spec_maneuver_result')}</b></h3>
            {
                result.map((figure: any, index: any) => {
                    const grades = Object.keys(figure).filter((key: any) => {
                        return key.includes("grade") && figure[key] !== null
                    }).map((key: any) => {
                        return figure[key]
                    })
                    return <Row key={index} gutter={24} style={{ marginBottom: 10 }}>
                        <Col>
                            <b>{figure.figure?.[`figure_${i18n.language}`] ? figure.figure?.[`figure_${i18n.language}`] : figure.figure?.figure_lv}</b>
                        </Col>
                        <Col style={{ display: "flex", alignItems: "center" }}>
                            <Row align="middle">
                                {
                                    grades.map((grade: any) => {
                                        return !!grade
                                            ? <CheckOutlined style={{ marginLeft: 10, fontSize: 20, color: "green" }} />
                                            : <CloseOutlined style={{ marginLeft: 10, fontSize: 20, color: "red" }} />
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                })
            }
            <Divider />
        </div>
        : <></>
}