import { Button, Card, Col, Modal, Row, Tag, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import i18n from 'i18next';
import { AddServiceModal } from "./AddServiceModal";
import medicineImg from "../../img/medicine.jpg";
import pmpImg from "../../img/pmp.jpg";
import booklvImg from "../../img/book_lv.jpg";
import bookruImg from "../../img/book_ru.jpg";
import { useTranslation } from "react-i18next";

export const ServiceCard = (props: any) => {
    const { t } = useTranslation();
    const { groupService, studentServices, refresh, refreshBalance, omniva, activeGroup, self } = props;

    const [service, setService] = useState({} as any);
    const [selectedService, setSelectedService] = useState(null as any);

    const realPrice = (discount: boolean = false) => {
        if ((!service?.discount_price || Number(service.discount_price) === 0) && discount) return 0
        return service.hasOwnProperty("services")
            ? service.paidStatus === "paid"
                ? service.totalPaid
                : service.services.reduce((prev: any, current: any) => {
                    return prev + (
                        current.hasOwnProperty("pivot")
                            ? current.pivot.paid
                                ? 0
                                : Number(current.pivot.individual_price)
                            : current?.discount_price && Number(current.discount_price) > 0 && discount
                                ? Number(current.discount_price)
                                : Number(current?.price)
                    )
                }, 0)
            : service.hasOwnProperty("pivot")
                ? Number(service.pivot.individual_price)
                : service?.discount_price && Number(service.discount_price) > 0 && discount
                    ? Number(service.discount_price)
                    : Number(service?.price)
    }

    const expiredPopup = () => {
        Modal.error({
            title: t('common.attention'),
            content: t('notification.learning_expired'),
        });
    }

    useEffect(() => {
        setService(() => {
            let trueService = {} as any;
            if (Array.isArray(groupService)) {
                const paidStatuses = groupService.map((service: any) => {
                    const newService = groupService.hasOwnProperty("pivot")
                        ? groupService
                        : studentServices.find((el: any) => el.id === service.id) || service;
                    if (newService.hasOwnProperty("pivot")) {
                        if (newService.pivot.paid) {
                            return "paid"
                        }
                        return "reserved"
                    }
                    return "not_reserved"
                })
                const paidStatus = paidStatuses.filter((el: any) => el === "paid").length === groupService.length
                    ? "paid"
                    : paidStatuses.some((el: any) => el === "paid")
                        ? "partially_paid"
                        : paidStatuses.some((el: any) => el === "reserved")
                            ? "not_paid"
                            : "not_reserved"
                trueService = {
                    value_lv: t('common.medical_service'),
                    value_en: t('common.medical_service'),
                    value_ru: t('common.medical_service'),
                    description_lv: groupService.map((service: any) => {
                        return <div key={service.id}>
                            <p style={{ marginBottom: 0 }}>{service.description_lv}</p>
                        </div>
                    }),
                    description_en: groupService.map((service: any) => {
                        return <div key={service.id}>
                            <p style={{ marginBottom: 0 }}>{service.description_en}</p>
                        </div>
                    }),
                    description_ru: groupService.map((service: any) => {
                        return <div key={service.id}>
                            <p style={{ marginBottom: 0 }}>{service.description_ru}</p>
                        </div>
                    }),
                    services: groupService.map((service: any) => {
                        return groupService.hasOwnProperty("pivot")
                            ? groupService
                            : studentServices.find((el: any) => el.id === service.id) || service
                    }),
                    paidStatus,
                    totalPaid: groupService.map((service: any) => {
                        return groupService.hasOwnProperty("pivot")
                            ? groupService
                            : studentServices.find((el: any) => el.id === service.id) || service
                    }).reduce((prev: any, current: any) => {
                        return prev + (
                            current.hasOwnProperty("pivot")
                                ? Number(current.pivot.individual_price)
                                : 0
                        )
                    }, 0),
                    img: groupService.find((service: any) => {
                        return service.img
                    })?.img
                }
            } else {
                trueService = groupService.hasOwnProperty("pivot")
                    ? groupService
                    : studentServices.find((el: any) => el.id === groupService.id) || groupService;
                if (trueService.hasOwnProperty("pivot")) {
                    trueService = {
                        ...trueService,
                        paidStatus: !!trueService.pivot.paid ? "paid" : "not_paid"
                    }
                }
            }
            return trueService;
        })
    }, [groupService, studentServices])

    useEffect(() => {
        console.log(omniva)
    }, [omniva])

    return <Card className='extra-service-card'>
        {
            selectedService
            && <AddServiceModal
                type={props.type}
                service={props.type === "book" ? { ...selectedService, omniva } : selectedService}
                groupID={activeGroup.id}
                setService={setSelectedService}
                refresh={() => refresh()}
                refreshBalance={() => refreshBalance()}
                self={props.self}
                amount={
                    realPrice(true) > 0
                        ? realPrice(true).toFixed(2)
                        : realPrice().toFixed(2)
                    // service.hasOwnProperty("services")
                    // ? service.paidStatus === "paid"
                    //     ? service.totalPaid
                    //     : service.services.reduce((prev: any, current: any) => {
                    //         return prev + (
                    //             current.hasOwnProperty("pivot")
                    //                 ? current.pivot.paid
                    //                     ? 0
                    //                     : Number(current.pivot.individual_price)
                    //                 : Number(current?.price)
                    //         )
                    //     }, 0)
                    // : service.hasOwnProperty("pivot")
                    //     ? service.pivot.individual_price
                    //     : service?.discount_price && Number(service.discount_price) > 0
                    //         ? service?.discount_price
                    //         : service?.price
                }
            />
        }
        {
            service.hasOwnProperty("paidStatus")
                ? service.paidStatus === "partially_paid"
                    ? <Tag className="status-tag" color="yellow">{t('common.partially_paid')}</Tag>
                    : service.paidStatus === "paid"
                        ? <Tag className="status-tag" color="success">{t('common.paid')}</Tag>
                        : service.paidStatus === "not_paid"
                            ? <Tag className="status-tag" color="red">{t('common.not_paid')}</Tag>
                            : <></>
                : service.hasOwnProperty("pivot")
                    ? <Tag className="status-tag" color={service.pivot.paid ? "success" : "red"}>{service.pivot.paid ? t('common.npaid') : t('common.not_paid')}</Tag>
                    : <></>
        }
        <Row>
            <Col span={24}>
                <div className="img-holder">
                    {
                        service.img
                        && <img
                            src={process.env.REACT_APP_API_URL + "storage/study_group_types/" + service.img}
                            // src={
                            //     props.type === "book"
                            //         ? booklvImg
                            //         : props.type === "pmp"
                            //             ? pmpImg
                            //             : medicineImg
                            // }
                            alt="Attēls nav atrasts"
                        />
                    }
                </div>
            </Col>
            <Col span={24}>
                {
                    props.type === "book" && omniva !== undefined && service.hasOwnProperty("pivot")
                        ? omniva?.pivot?.delivered
                            ? <Tag
                                style={{ width: "100%", textAlign: "center", whiteSpace: "normal" }}
                                color="green">
                                Izsūtīts: {omniva?.pivot?.updated_at}
                            </Tag>
                            : <Tag
                                style={{ width: "100%", textAlign: "center", whiteSpace: "normal" }}
                                color="orange">Nav izsūtīts
                            </Tag>
                        : <></>
                }
            </Col>
            <Col span={24} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <div className="top-wrap">
                    <p><b>{service?.[`value_${i18n.language}`]}</b></p>
                    <p>{service?.[`description_${i18n.language}`]}</p>
                </div>
                <div className="bottom-wrap">
                    <b className="price-blue-text" style={{ color: service?.paidStatus === "partially_paid" ? "red" : "" }}>
                        {
                            service?.paidStatus === "partially_paid"
                            && <Tooltip placement="top" title={t('common.remaining_payment')}>
                                <InfoCircleOutlined style={{ color: "red", marginRight: 6 }} />
                            </Tooltip>
                        }
                        {
                            realPrice(true) > 0
                                ? <span>
                                    <s style={{ color: "red" }}>
                                        {realPrice().toFixed(2)}
                                    </s>&nbsp;
                                    {realPrice(true).toFixed(2)}
                                </span>
                                : realPrice().toFixed(2)

                            // service.hasOwnProperty("services")
                            //     ? service.paidStatus === "paid"
                            //         ? service.totalPaid
                            //         : service.services.reduce((prev: any, current: any) => {
                            //             return prev + (
                            //                 current.hasOwnProperty("pivot")
                            //                     ? current.pivot.paid
                            //                         ? 0
                            //                         : Number(current.pivot.individual_price)
                            //                     : Number(current?.price)
                            //             )
                            //         }, 0)

                            //     : service.hasOwnProperty("pivot")
                            //         ? service.pivot.individual_price
                            //         : service?.price
                        } EUR</b>
                    <Button
                        className={`accept-button ${service.paidStatus === "paid" && !(props.type === "medical") && !(props.type === "pmp") ? "disabled" : ""}`}
                        onClick={() => self?.expired_theory || self?.expired_driving ? expiredPopup() : setSelectedService(service)}
                        disabled={service.paidStatus === "paid" && !(props.type === "medical") && !(props.type === "pmp")}
                    >
                        {
                            service.paidStatus === "paid" && ((props.type === "medical") || (props.type === "pmp"))
                                ? t('common.retry_purchase')
                                : t('common.purchase')
                        }
                    </Button>
                </div>
            </Col>
        </Row>
    </Card>
}