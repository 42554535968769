import { Row, Col, Card, Layout } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { LessonsList } from "../PlannerPage/LessonsList";
import { TheoryInfoCard } from "../PlannerPage/TheoryInfoCard";
import { Notification } from "../PlannerPage/Notification";
import { useTranslation } from "react-i18next";
import { CheckpointsCard } from "../StudentCheckpoints/CheckpointsCard";
import i18next from "i18next";
import { ShortNotificationsCard } from "../Notifications/ShortNotificationsCard";

export const TheoryMain = (props: any) => {
    const { t } = useTranslation();
    const { self: student } = props;

    const [cities, setCities] = useState([] as any);
    const [theoryLessons, setTheoryLessons] = useState([] as any);
    // const [allTheoryLessons, setAlltheoryLessons] = useState([] as any);
    const [foreignLessons, setForeignLessons] = useState([] as any);
    // const [allSignedLessons, setAllSignedLessons] = useState([] as any);
    const [nextTheoryLesson, setNextTheoryLesson] = useState({} as any);
    const [loadedTheory, setLoadedTheory] = useState(false);
    const [notifications, setNotifications] = useState([] as any);
    const [customNotifications, setCustomNotifications] = useState([] as any);
    const [theoryExams, setTheoryExams] = useState([] as any);
    const [notificationsLoading, setNotificationsLoading] = useState(false);

    const fetchNotifications = async () => {
        setNotificationsLoading(true);
        await http.get('notifications-unread').then((response) => {
            setNotifications(response.data)
            if (response.data.length > 0) {
                window.scrollTo({ top: 5, left: 0, behavior: 'smooth' });
            }
        })
        await http.get('get-custom-notifications').then((response) => {
            //console.log(response.data)
            setCustomNotifications(response.data.data);
            if (response.data.length > 0) {
                window.scrollTo({ top: 5, left: 0, behavior: 'smooth' });
            }
        })
        setNotificationsLoading(false);
    }

    const getCities = async () => {
        await http.get('/cities').then((response: any) => {
            //console.log(response.data.data)
            setCities(response.data.data)
        })
    }

    const fetchTheoryExamResults = () => {
        http.get(`get-theory-exam-results`).then((response: any) => {
            //console.log(response)
            const notCanceledExams = response.data.filter((exam: any) => {
                return !exam.status.includes("admin_cancelled");
            })
            setTheoryExams(notCanceledExams)
        })
    }

    const deleteNotification = (id: any, custom: boolean = false) => {
        if (custom) {
            setCustomNotifications((data: any) => {
                const newList = data.filter((item: any) => {
                    return item.id !== id;
                })
                return newList;
            })
            return;
        }
        setNotifications((data: any) => {
            const newList = data.filter((item: any) => {
                return item.id !== id;
            })
            return newList;
        })
    };

    const fetchTheoryLessons = async () => {

        await http.get(`/student-study-group-attendance/${student.id}`).then((resp: any) => {
            const { attendance, foreign_lessons } = resp.data
            const attendedLessons = attendance.map((lesson: any) => {
                return {
                    ...lesson.lesson,
                    ...lesson.native,
                    csdd_nr: lesson.csdd
                }
            });

            setTheoryLessons(attendedLessons);
            setForeignLessons(() => {
                const mergedLessons = foreign_lessons.map((lesson: any) => {
                    return {
                        ...lesson.lesson,
                        ...lesson.foreign,
                        csdd_nr: lesson.csdd,
                        paid: lesson.paid
                    }
                })
                return mergedLessons.reverse();
            });
        })
    }

    useEffect(() => {
        fetchTheoryExamResults();
    }, [])

    useEffect(() => {
        if (theoryLessons.length > 0) {
            setLoadedTheory(true);
        }
    }, [theoryLessons])

    useEffect(() => {
        if (Object.keys(student).length > 0) {
            fetchTheoryLessons();
            getCities();
        }
    }, [student])

    useEffect(() => {
        fetchNotifications();
    }, [i18next.language]);

    return (
        <Layout className="main-content">
            <Row>
                <Col span={24}>
                    {
                        (customNotifications.length > 0 || notifications.length > 0)
                        && <ShortNotificationsCard
                            refreshBalance={() => props.refetchBalance()}
                            notifications={notifications}
                            customNotifications={customNotifications}
                            fetchNotifications={fetchNotifications}
                            loading={notificationsLoading}
                            refetch={fetchTheoryLessons}
                            student={student}
                        />
                    }
                    {/* {
                        notifications.length > 0 || customNotifications.length > 0
                            ? <Card style={{ width: "100%", marginBottom: 20 }} title={t('common.notifications')}>
                                {
                                    customNotifications?.map((notific: any) => {
                                        return <Notification
                                            key={notific.id}
                                            deleteFromList={deleteNotification}
                                            notification={notific}
                                            refreshBalance={() => props.refetchBalance()}
                                            custom
                                        />
                                    })
                                }
                                {notifications?.map((item: any) => {
                                    return <Notification
                                        key={item.id}
                                        deleteFromList={deleteNotification}
                                        notification={item}
                                        refetch={fetchTheoryLessons}
                                        refreshBalance={() => props.refetchBalance()}
                                    />
                                })}
                            </Card>
                            : <></>
                    } */}
                </Col>
                <Col span={24}>
                    <CheckpointsCard self={student} />
                </Col>
                <Col span={24}>
                    <Card>
                        {
                            loadedTheory
                                ? <TheoryInfoCard
                                    // allLessons={allTheoryLessons}
                                    attendedLessons={theoryLessons}
                                    foreignLessons={foreignLessons}
                                    // allSignedLessons={allSignedLessons}
                                    student={student}
                                    getNotifications={fetchNotifications}
                                    getNextLesson={setNextTheoryLesson}
                                    cities={cities}
                                    refetch={fetchTheoryLessons}
                                    theoryExams={theoryExams}
                                    refetchExams={() => fetchTheoryExamResults()}
                                    refreshBalance={() => props.refetchBalance()}
                                />
                                : <></>
                        }
                    </Card>
                    <Card
                        style={{ marginTop: 20 }}
                        title={t('theory.theory_lessons')}
                    >
                        {
                            loadedTheory
                                ? <LessonsList
                                    studentLessons={theoryLessons}
                                    foreignLessons={foreignLessons}
                                    student={student}
                                    getNotifications={fetchNotifications}
                                    nextTheoryLesson={nextTheoryLesson}
                                    refetch={fetchTheoryLessons}
                                    refreshBalance={() => props.refetchBalance()}
                                />
                                : <></>
                        }
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};