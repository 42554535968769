import { Row, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { ReactComponent as IconBookmark } from "../../img/ic-add-bookmark.svg";
import { useTranslation } from "react-i18next";

export const AddBookmark = (props: any) => {
    const { t } = useTranslation();
    const { time, videoID, id, refresh, fullscreen } = props;

    const [comment, setComment] = useState(props.comment);

    const closeModal = () => {
        props.setShow(false);
        //console.log(time)
        if (!id) {
            props.cancelAddBookmark()
        }
    }

    const saveBookmark = () => {
        http.post(`/create-bookmark`,
            {
                bookmark: time,
                video_id: videoID,
                comment: comment,
            }
        ).then((response: any) => {
            refresh();
            // closeModal();
        }).then(() => closeModal());
    };

    const deleteBookmark = () => {
        http.post(`/delete-bookmark`, { bookmark_id: id }).then((response: any) => {
            refresh();
            // closeModal();
        }).then(() => closeModal());
    }

    useEffect(() => {
        //console.log("ID: ", id)
    }, [])

    return <div
        className={`bookmark-modal ${fullscreen ? 'full-screen' : ''}`}
        onClick={(e: any) => { e.stopPropagation(); closeModal() }}
    >
        <div
            className="bookmark-popup"
            onClick={(e: any) => e.stopPropagation()}
        >
            <div
                className="close-btn"
                onClick={closeModal}
            >
                <CloseOutlined />
            </div>
            <Row justify="center">
                <div className="bookmark-icon">
                    <IconBookmark />
                </div>
            </Row>
            <h2 className="centered">
                {
                    id
                        ? t('e_learning.edit_bookmark')
                        : t('e_learning.adding_bookmark')
                }
            </h2>
            {
                id
                    ? <></>
                    : <p className="centered">
                        {t('e_learning.bookmark_info')}
                    </p>
            }
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <p className="black">{t('e_learning.video_time')}:</p>
                <div className="time-container">{moment.utc(time * 1000).format("HH:mm:ss")}</div>
            </Row>
            <Row>
                <Input.TextArea
                    value={comment}
                    placeholder={`${t('e_learning.your_comment')}...`}
                    autoSize={{ maxRows: 4, minRows: 4 }}
                    style={{ marginBottom: 20 }}
                    onChange={(event: any) => setComment(event.target.value)}
                />
            </Row>
            <button
                className="add-bookmark"
                onClick={saveBookmark}
            >
                {
                    id
                        ? t('common.save')
                        : t('e_learning.add_bookmark')
                }
            </button>
            {
                id
                    ? <button
                        className="delete-bookmark"
                        onClick={deleteBookmark}
                    >
                        {t('e_learning.delete_bookmark')}
                    </button>
                    : <></>
            }

        </div>
    </div>
}