import { Card, Row, Button } from "antd";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { BookInOtherGroup } from "./LessonsList";
import { useTranslation } from "react-i18next";

export const MissedWarning = (props: any) => {
    const { t } = useTranslation();
    const { reserved, missedId, student, getNotifications, cities, refetch } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const lesson = {
        id: missedId
    }

    useEffect(() => {
        //console.log(reserved)
    }, [])
    
    return (
        <Card className="warning" style={{ margin: "10px 0", border: reserved ? reserved?.skipped ? '' : `1px solid ${reserved.paid ? "rgb(77 163 0)" : "orange"}` : "" }}>
            <Row justify="space-between">
                {
                    reserved
                        ? !reserved?.skipped
                            ? <div style={{ color: `${reserved.paid ? "rgb(77 163 0)" : "orange"}`, display: "flex", alignItems: "center" }}>
                                {missedId}. nodarbība, {reserved.lesson_date}, {reserved.time_from} - {reserved.time_to}&nbsp;
                                {reserved.is_online && reserved.paid ? t('theory.online') : ""}
                                {!reserved.paid ? t('theory.need_confirmation') : ""}
                            </div>
                            : <>
                                <div style={{ color: "#C02227", display: "flex", alignItems: "center" }}>
                                    <ExclamationCircleTwoTone twoToneColor="red" style={{ fontSize: 22, marginRight: 10 }} />
                                    {t('theory.you_have_missed')} {missedId}.{t('theory.lesson')}!
                                </div>
                                <Button onClick={() => setModalVisible(true)} type="link">{t('theory.choose_time')}</Button>
                            </>
                        : <>
                            <div style={{ color: "#C02227", display: "flex", alignItems: "center" }}>
                                <ExclamationCircleTwoTone twoToneColor="red" style={{ fontSize: 22, marginRight: 10 }} />
                                {t('theory.you_have_missed')} {missedId}.{t('theory.lesson')}!
                            </div>
                            <Button onClick={() => setModalVisible(true)} type="link">{t('theory.choose_time')}</Button>
                        </>
                }
                <BookInOtherGroup
                    showModal={modalVisible}
                    setShowModal={setModalVisible}
                    getNotifications={getNotifications}
                    lesson={lesson}
                    lessonNum={missedId}
                    student={student}
                    cities={cities}
                    refetch={refetch}
                />
            </Row>
        </Card>
    )
}
