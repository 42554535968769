import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Radio, Row, Space, Table, Tag, Typography, message } from "antd";
import moment from "moment";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { AxiosError, AxiosResponse } from "axios";

const { Text } = Typography;

export const BookInOtherGroup = (props: any) => {
    const { t } = useTranslation();
    const { showModal, setShowModal, reserved, lesson, lessonNum, student, cities, refetch } = props;
    const [selectedGroup, selectGroup] = useState({} as any);
    const [freeLessons, setFreeLessons] = useState([] as any);
    const [availableFromDate, setAvailableFromDate] = useState(null as null | string);
    const [error, setError] = useState("");

    const getFilteredGroups = async () => {
        await http.get(`/student-study-group-free-attendance/${student.id}/${lessonNum}`, {
            params: {
                category: student.category_id
            }
        }).then((response: any) => {
            const freeLessons = response.data.data.map((lesson: any) => {
                //console.log(lesson)
                return {
                    ...lesson,
                    cities: lesson?.cities?.map((id: any) => {
                        return cities.find((el: any) => el.id === id);
                    })
                }
            })
            setFreeLessons(freeLessons)
            setAvailableFromDate(response.data.date_available_from)
        })
    }

    useEffect(() => {
        if (showModal) {
            if (cities.length > 0) {
                getFilteredGroups();
            }
        }
    }, [showModal])

    const columns = [
        {
            title: t('common.date'),
            dataIndex: 'lesson_date',
            key: 'date',
        },
        {
            title: t('common.time_from'),
            dataIndex: 'time_from',
            key: 'time_from',
        },
        {
            title: t('common.time_to'),
            dataIndex: 'time_to',
            key: 'time_to',
        },
        {
            title: t('common.language'),
            dataIndex: 'language'
        },
        {
            title: t('common.city'),
            dataIndex: 'cities',
            key: 'cities',
            render: (records: any) => {
                return records?.map((record: any) => {
                    return <Tag key={record?.id}>{record?.value}</Tag>
                })
            },
        },
        {
            title: t('common.address'),
            dataIndex: 'address',
            key: 'address',
            render: (record: any) => {
                return <p style={{ whiteSpace: "normal", width: 200 }}>{record}</p>
            }
        },
        {
            title: t('theory.csdd'),
            dataIndex: 'csdd',
            key: 'csdd',
            align: 'center',
        },
        {
            title: t('theory.lecturers'),
            dataIndex: 'lecturers',
            key: 'lecturers',
            render: (records: any) => {
                return records?.map((record: any) => {
                    return <Tag key={record?.id}>{record?.first_name} {record?.last_name}</Tag>
                })
            }
        },
        {
            title: t('theory.type'),
            dataIndex: 'is_online',
            key: 'is_online',
            render: (record: any) => {
                return record ? t('theory.online') : t('theory.on_site')
            }
        },
    ] as any;

    const resetModal = () => {
        setShowModal(false)
    }

    const setGroup = (value: any) => {
        {
            props.disableBook || !props.canBook
                ? Modal.confirm({
                    title: t('theory.lesson_approvement'),
                    content: <div>
                        <Tag color="red" style={{ whiteSpace: "normal" }}>
                        {!props.canBook  ? t('e_learning.cant_book_if_prev_not') : t('e_learning.cant_book')}
                        </Tag>
                    </div>,
                })
                : Modal.confirm({
                    title: t('theory.lesson_approvement'),
                    content: <div>
                        <p>{t('theory.you_sure_lesson_is_correct')}</p>
                        <Tag color="red" style={{ whiteSpace: "normal" }}>
                            {t('notification.be_aware_48h_warning')}
                        </Tag>
                    </div>,
                    onOk: () => {
                        http.post(`/student-study-group-create-attendance/${student.id}`, { id: value }).then((response: any) => {
                            props.getNotifications();
                            refetch();
                        }).catch((err: AxiosError) => {
                            errorMessageResolver(err)
                            console.log(err.response?.data?.msg)
                            const { msg } = err.response?.data;
                            message.error("Kļūda! " + msg)
                        })
                    }
                })
        }

    }

    const saveGroup = () => {
        selectedGroup.hasOwnProperty('id') ? reserved(true) : reserved(false);
        selectGroup({});
        resetModal();
    }

    const errorMessageResolver = (err: any) => {
        const { data, status } = err.response;
        if (status === 403) {
            if (data?.length > 0) {
                setError(`${t(`errors.${data[0].message_type}`)} ${data[0].info} ${t(`errors.error_activities.${data[0].reason}`)}`);
            }
        } else {
            setError("")
        }
    }

    return (
        <Modal
            visible={showModal}
            onCancel={resetModal}
            onOk={saveGroup}
            title={`${t('theory.book')} ` + lessonNum + `. ${t('theory.lesson_in_other_group')}`}
            width="60%"
            footer={null}
        >
            {/* <Button onClick={filteredGroups}>LOG</Button> */}
            {
                availableFromDate
                && <b>{t('theory.nearest_allowed_date')}: {moment(availableFromDate).format("DD.MM.YYYY")}</b>
            }
            {
                error.length > 0
                && <div>
                    <Tag color="red" style={{ marginBottom: 10, marginTop: 10, whiteSpace: "normal", width: "100%", textAlign: "center" }}>
                        {error}
                    </Tag>
                </div>
            }
            <Table
                className="change-lesson-group"
                rowKey={record => record.id}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            setGroup(record.id);
                            resetModal();
                        }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { }, // mouse leave row
                    };
                }}
                rowClassName={(record: any) => {
                    return record.id === selectedGroup.id ? "selected-row" : "";
                }}
                dataSource={freeLessons}
                scroll={{ x: 800, }}
                columns={columns}
            />
        </Modal>
    )
}

const LessonItem = (props: any) => {
    const { t } = useTranslation();
    const {
        lesson,
        lesson_status,
        lessonNum,
        student,
        getNotifications,
        reserved,
        next,
        cities,
        refetch,
        haveElearning,
        foreign,
        canBook,
        refreshBalance,
        disableBook
    } = props;

    const [modalVisible, setModalVisible] = useState(false);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        //console.log(lesson_status, reserved, haveElearning, foreign)
    }, [])

    const style = () => {
        if (lesson_status === "attained") {
            return {
                backgroundColor: "#d9f7be"
            }
        } else if (lesson_status === 'foreign') {
            if (reserved?.paid && !reserved?.skipped) {
                return {
                    backgroundColor: "rgb(245 255 213)"
                }
            }
            if (!reserved?.paid && reserved) {
                return {
                    backgroundColor: "orange"
                }
            }
        } else if (lesson_status === "skipped") {
            if (haveElearning && !lesson?.foreign_lesson) {
                return {
                    backgroundColor: "#c4f3f7"
                }
            }
            if (reserved?.paid && reserved?.skipped && lesson?.foreign_lesson) {
                return {
                    backgroundColor: "#ffccc7"
                }
            }
            return {
                backgroundColor: "#ffccc7"
            }
        }
    }

    const status = () => {
        if (lesson_status === "attained") return t('theory.visited')
        else if (lesson_status === "foreign") {
            if (reserved?.paid && !reserved?.skipped) {
                return t('theory.booked_in_outher_group')
            }
            if (!reserved?.paid && reserved) {
                return t('theory.need_confirmation')
            }
        }
        else if (lesson_status === "skipped") {
            return t('theory.not_visited')
        }
    }

    const changeGroup = async () => {
        setModalVisible(true);
    }

    const cancelLesson = () => {
        const timeFrom = lesson.time_from !== undefined ? lesson.time_from : ""
        const date = lesson.lesson_date !== undefined ? lesson.lesson_date : ""
        const dateTime = `${date} ${timeFrom}`
        const expensiveCancel = moment(`${dateTime}`, "YYYY-MM-DD HH:mm:ss").subtract("48", "hours").isBefore(moment())
        Modal.confirm({
            title: t("driving.cancel_lesson"),
            content: <div>
                <p>{t('driving.you_sure_want_cancel_lesson')}</p>
                {
                    expensiveCancel
                    && <Tag color="red" style={{ whiteSpace: "normal" }}>
                        {t('notification.be_aware_48h_warning')}
                    </Tag>
                }
            </div>,
            onOk: () => {
                http.post(`student-study-group-cancel-attendance`, { id: lesson.theory_lesson_id }).then((res: AxiosResponse) => {
                    refetch();
                    refreshBalance();
                })
            },
            cancelText: "Aizvērt",
        })
    }

    return (
        <Card bodyStyle={{ padding: "30px" }} style={{ ...style(), marginBottom: 4, cursor: "default", borderColor: next ? "rgb(24, 144, 255)" : "" }} hoverable>
            {/* {foreign ? "FOREIGN!!!" : ''} */}
            <Row gutter={[8, 8]}>
                {lesson.is_online ? <Tag style={{ marginLeft: 5, position: "absolute", top: 4, left: 0, fontSize: 10 }} color="blue">{t('theory.online')}</Tag> : ""}
                <Col lg={4} md={12} span={24}>
                    <Text>Nr: {lessonNum}, {lesson.csdd_nr}</Text>
                </Col>
                <Col lg={4} md={12} span={24}>
                    <Text>{lesson.name}</Text>
                </Col>
                <Col lg={4} md={12} span={24}>
                    {(!haveElearning || foreign) && <Text>{lesson.lesson_date}</Text>}
                </Col>
                <Col lg={4} md={12} span={24}>
                    {(!haveElearning || foreign) && <>
                        <Text>{lesson.time_from} - </Text>
                        <Text>{lesson.time_to}</Text>
                    </>}
                </Col>
                <Col lg={3} md={12} span={24}>
                    <Text>{haveElearning && !foreign && (lesson_status !== "attained") ? t('theory.not_booked') : status()}</Text>
                    <BookInOtherGroup
                        showModal={modalVisible}
                        setShowModal={setModalVisible}
                        lesson={lesson}
                        lessonNum={lessonNum}
                        student={student}
                        getNotifications={getNotifications}
                        cities={cities}
                        refetch={refetch}
                        disableBook={disableBook}
                        canBook={canBook}
                    />
                </Col>
                <Col lg={5}>
                    <Row justify="end">
                        <Space>
                            {
                                status() === t('theory.not_visited') || (haveElearning && !foreign && (lesson_status !== "attained"))
                                    ? <Button onClick={changeGroup}>{t('theory.book')}</Button>
                                    : <></>
                            }
                            {
                                status() === t('theory.booked_in_outher_group')
                                && <Button
                                    danger
                                    onClick={cancelLesson}
                                >
                                    Atcelt
                                </Button>
                            }
                            <Button onClick={() => setExpanded(!expanded)}>{expanded ? "-" : "+"}</Button>
                        </Space>
                    </Row>
                </Col>
                <Col>

                </Col>
                {
                    expanded
                        ? <Col span={24}>
                            {
                                lesson.is_online
                                    ? <div>
                                        <b>{t('theory.online')}</b><br />
                                        {
                                            lesson.zoom_link
                                                ? <><b>Zoom:</b> <a href={lesson.zoom_link} target="_blank" rel='noopener noreferrer'>{lesson.zoom_link}</a></>
                                                : ""
                                        }
                                    </div>
                                    : <div>
                                        <b>{t('theory.on_site')}</b><br />
                                        {
                                            lesson.address
                                                ? <><b>{t('common.address')}:</b> <p>{lesson.address}</p></>
                                                : ""
                                        }
                                    </div>
                            }
                            {
                                lesson.lecturers.length > 0
                                    ? <div>
                                        <b>{t('theory.lecturers')}:</b>&nbsp;
                                        {
                                            lesson.lecturers.map((lecturer: any, index: any, self: any) => {
                                                return lecturer.first_name + " " + lecturer.last_name + (index !== self.length - 1 ? ", " : "")
                                            })
                                        }
                                    </div>
                                    : <></>
                            }
                            {
                                lesson[`comment_${i18n.language}`]
                                    ? <div>
                                        <b>{t('common.comments')}</b><br />
                                        <p>{lesson?.[`comment_${i18n.language}`]}</p>
                                    </div>
                                    : <></>
                            }
                        </Col>
                        : <></>
                }
            </Row>
        </Card>
    )
}

export const LessonsList = (props: any) => {
    const {
        studentLessons,
        foreignLessons,
        student,
        getNotifications,
        nextTheoryLesson,
        refetch,
        haveElearning,
        refreshBalance,
        disableBook
    } = props;

    const [cities, setCities] = useState([] as any);

    const getCities = async () => {
        await http.get('/cities').then((response: any) => {
            setCities(response.data.data)
        })
    }

    useEffect(() => {
        getCities();
    }, []);

    return (
        <>
            {studentLessons?.map((lesson: any, index: any) => {
                let foreign = undefined;
                let lessonBeforeIsForeign = false;
                if (lesson.skipped) {
                    foreign = foreignLessons?.find((el: any) => {
                        return el.lesson_counter === lesson.lesson_counter
                        // return el.lesson_counter === index + 1
                    });
                    if (foreign) {
                        lesson = foreign;
                    }
                }
                if (index > 0) {
                    lessonBeforeIsForeign = foreignLessons?.some((el: any) => {
                        return el.lesson_counter === studentLessons[index - 1].lesson_counter
                    });
                }
                const lessonForeign = !lesson.attained && !lesson.skipped && lesson.foreign_lesson //pure foreign
                const lessonSkipped = lesson.skipped
                const lessonAttained = lesson.attained
                const status = lessonSkipped
                    ? "skipped"
                    : lessonForeign
                        ? "foreign"
                        : lessonAttained
                            ? "attained"
                            : ""
                const next = nextTheoryLesson
                    ? nextTheoryLesson.name === lesson.name
                    : false;
                console.log(studentLessons[index - 1], !!studentLessons[index - 1]?.foreign_lesson, status)
                return <LessonItem
                    key={lesson.id}
                    lesson={lesson}
                    lessonNum={lesson?.lesson_counter}
                    lesson_status={status}
                    student={student}
                    getNotifications={getNotifications}
                    reserved={foreign}
                    next={next}
                    cities={cities}
                    refetch={refetch}
                    haveElearning={haveElearning}
                    foreign={lessonForeign}
                    canBook={
                        !haveElearning
                            ? true
                            : index === 0
                                ? true
                                : lessonBeforeIsForeign
                                    ? true
                                    : studentLessons[index - 1].attained
                                        ? true
                                        : false
                    }
                    refreshBalance={refreshBalance}
                    disableBook={disableBook}
                />
            })}
        </>
    )
}
