import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { ExamSlot } from "./ExamCalendar";

export const MobileSlotList = (props: any) => {
    const { examSlots, updateSlots, price, pricesPolicy } = props;
    const [groupedSlots, setGroupedSlots] = useState({} as any);

    const groupSlotsByDate = () => {
        let groupByDate = {} as any;
        examSlots.forEach((slot: any) => {
            const hasDate = Object.keys(groupByDate).some((el: any) => el === slot.exam_date);
            if (hasDate) {
                //console.log(groupByDate, slot.exam_date)
                groupByDate[slot.exam_date] = [
                    ...groupByDate[slot.exam_date],
                    slot
                ]
            } else {
                groupByDate = {
                    ...groupByDate,
                    [slot.exam_date]: [slot]
                }
            }

        })
        return groupByDate;
    }

    useEffect(() => {
        if (examSlots.length > 0) {
            setGroupedSlots(groupSlotsByDate());
        }
    }, [examSlots])

    return <Collapse>
        {
            Object.keys(groupedSlots).map((key: any) => {
                return <Collapse.Panel key={key} header={key}>
                    {
                        groupedSlots[key].map((slot: any, index: any) => {
                            return <ExamSlot
                                updateSlots={(slot: any) => updateSlots(slot)}
                                key={index}
                                slot={slot}
                                price={price}
                                pricesPolicy={pricesPolicy}
                            />
                        })
                    }
                </Collapse.Panel>
            })
        }
    </Collapse>
}