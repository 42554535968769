import { Button, Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as IcBookmark } from '../../img/ic-cc-1.svg';
import { useHistory } from "react-router-dom";
import { AddBookmark } from "./AddBookmark";
import { useTranslation } from "react-i18next";

export const BookmarkTitleCard = (props: any) => {
    const { t } = useTranslation();
    const { bookmark, self } = props;

    const history = useHistory();

    const [bookmarkForm, setBookmarkForm] = useState({
        id: undefined as any,
        time: "00:00:00" as String,
        show: false,
        comment: "",
        video_id: null as any
    });
    const [mobile, setMobile] = useState(false);

    const editBookmark = () => {
        setBookmarkForm({
            id: bookmark.id,
            time: bookmark.bookmark,
            show: true,
            comment: bookmark.comment,
            video_id: bookmark.e_chapter_language_video_id
        });
    };

    const jumpToTimeHandler = () => {
        props.jumpTo(bookmark.bookmark);
        setTimeout(() => props.jumpTo(undefined), 2000)
    }

    useEffect(() => {
        setMobile(window.matchMedia("(max-width: 767px)").matches)

        window.addEventListener("resize", () => {
            setMobile(window.matchMedia("(max-width: 767px)").matches)
        })
    }, []);

    return <Card bodyStyle={{ backgroundColor: "#F0F2F5" }} style={{ marginBottom: "2px" }}>
        {
            bookmarkForm.show
                ? <AddBookmark
                    fullscreen
                    id={bookmarkForm.id}
                    time={bookmarkForm.time}
                    videoID={bookmarkForm.video_id}
                    comment={bookmarkForm.comment}
                    refresh={props.refreshBookmarks}
                    setShow={(show: Boolean) => setBookmarkForm((prev: any) => {
                        return {
                            ...prev,
                            show: show
                        }
                    })}
                />
                : <></>
        }
        <Row justify={mobile ? `center` : `space-between`}>
            <Col md={12}>
                <Row align="middle" justify={mobile ? `center` : `start`}>
                    <IcBookmark style={{ marginBottom: mobile ? 8 : 0}}/>
                    <Col style={{ marginBottom: mobile ? 8 : 0}}>
                        <small style={{ margin: "0 14px" }}>
                            {bookmark.chapter.module_ind + 1}. {t('e_learning.lesson')} {t('e_learning.topic')}: {bookmark.chapter.title}
                        </small>
                        <h4 style={{ margin: "0 14px" }}>"{bookmark.comment}"</h4>
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
                <Row justify="end" align="middle">
                    <Button
                        style={{ marginRight: "6px" }}
                        onClick={editBookmark}
                    >
                        {t('common.edit')}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            //console.log(props.inLesson)
                            !props.inLesson
                            ? history.push(
                                `/e-learning/${self.category_id}/${bookmark.chapter.module_hash_id}/${bookmark.chapter.hash_id}`,
                                { time: bookmark.bookmark }
                            )
                            : jumpToTimeHandler()
                        }}
                    >
                        Atvērt
                    </Button>
                </Row>
            </Col>
        </Row>
    </Card>
}