import { Card, Row, Button, Col, Divider, Space, Tag, Modal } from "antd";
import {
    CalendarOutlined,
    EnvironmentOutlined,
    UserOutlined,
    UserSwitchOutlined,
    PhoneOutlined,
    CarOutlined
} from '@ant-design/icons';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";

const drivingOptions = {
    field: "Laukums",
    city: "Satiksme",
} as any

export const TopInfoCard = (props: any) => {
    const { t } = useTranslation();
    const { lastLesson, slot, examSlot, city, canRetryExam } = props;
    const [loading, setLoading] = useState(false);

    const notEmpty = slot || examSlot

    const deleteExam = () => {
        console.log(examSlot)
        Modal.confirm({
            title: "Atcelt nodarbību",
            content: "Apstiprinot izvelētā nodarbība tiks atcelta!",
            onOk: () => {
                setLoading(true);
                http.patch(`lessons/${examSlot.lesson_id ?? examSlot.id}`).then((response: any) => {
                    setLoading(false);
                    window.location.reload();
                }).catch(() => {
                    setLoading(false);
                });
            }
        })
    }

    useEffect(() => {
        //console.log(examSlot, slot, props.exam, canRetryExam)
        // console.log(moment().subtract(30, 'minutes').isSameOrAfter(moment(`${slot.date} ${slot.time_from}`)))
        // console.log(moment().subtract(30, 'minutes'), moment(`${slot.date} ${slot.time_from}`))
    }, [])

    return <Card title={
        <Row justify="space-between">
            {t('driving.top_info_card.practical_training')}
            {
                (props.exam.active) && !examSlot && canRetryExam < 3
                    ? <Link to={{ pathname: "/planner/calendar/exam", state: { city_id: city } }}>
                        <Button className="btn-green" type="primary">{t('driving.top_info_card.apply_to_exam')}</Button>
                    </Link>
                    : <></>
            }
        </Row>
    }
        style={{ marginBottom: 20 }}
    >
        <Row gutter={20}>
            <Col span={24}>
                {examSlot
                    ? <Card className="exam-card" style={{ marginBottom: 10 }} title={
                        <Row justify="space-between">
                            <div>{t('driving.top_info_card.school_driving_exam')}</div>
                            <Button loading={loading} onClick={deleteExam} danger>Atteikties</Button>
                        </Row>
                    }>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col style={{ display: "flex", alignItems: "center" }}>
                                        <CalendarOutlined style={{ fontSize: 26, marginRight: 20 }} />
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <span>{moment(examSlot.date).format("dddd, D. MMMM")}<br />{examSlot.time_from} - {examSlot.time_to}</span>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col style={{ display: "flex", alignItems: "center" }}>
                                        <EnvironmentOutlined style={{ fontSize: 26, marginRight: 20 }} />
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <span>{examSlot.meeting_place}<br />{examSlot.city}</span>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <h3 style={{ margin: 0 }}>{t('driving.top_info_card.inspector')}:</h3>
                            </Col>
                            <Col span={12}>
                                <Space>
                                    <UserOutlined style={{ fontSize: 26 }} />
                                    <h3 style={{ margin: 0 }}>{examSlot.instructor_name}</h3>
                                </Space>
                            </Col>
                            <Col span={12}>
                                <Space>
                                    <PhoneOutlined style={{ fontSize: 26 }} />
                                    <a href={`tel:${examSlot.phone_number}`}><h3 style={{ margin: 0 }}>{examSlot.phone_number}</h3></a>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space>
                                    <CarOutlined style={{ fontSize: 26 }} />
                                    <h3 style={{ margin: 0 }}>{examSlot.vehicle}, {examSlot.licence_plate}</h3>
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                    : !notEmpty
                        ? props.exam.failed || props.exam.passed
                            ? <Col>
                                <div style={{ marginBottom: 10 }}>{t('driving.top_info_card.school_driving_exam')} {props.exam.passed ? t('driving.top_info_card.is_passed') : t('driving.top_info_card.isnt_passed')}</div>
                                {
                                    props.exam.sent_csdd
                                        ? <Tag style={{ whiteSpace: "normal" }} color="green">{t('driving.top_info_card.your_are_applied_to_csdd_exam')}</Tag>
                                        : props.exam.extra_lessons
                                            ? <>
                                                {/* <Tag color="green">Jums ir atļautas papildus braukšanas</Tag> */}
                                                <Button onClick={props.toCalendar} type="link">{t('driving.top_info_card.schedule_additional_lesson')}</Button>
                                            </>
                                            : ""
                                }
                            </Col>
                            : <Col style={{ marginBottom: 20 }}>{t('driving.top_info_card.no_scheduled_lessons')}</Col>
                        : <><Col span={24}>
                            <Card className="next-lesson" style={{ marginBottom: 10 }}>
                                <Row>
                                    <Col style={{ display: "flex", alignItems: "center" }}>
                                        <CalendarOutlined style={{ fontSize: 26, marginRight: 20 }} />
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <span>{moment(slot.date).format("dddd, D. MMMM")}<br />{slot.time_from} - {slot.time_to}</span>
                                                <div>{ }</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                            <Col span={24}>
                                <Card className="next-lesson" style={{ marginBottom: 10 }}>
                                    <Row>
                                        {/* <Col style={{ display: "flex", alignItems: "center" }}>
                                            
                                        </Col> */}
                                        {/* <Col>
                                            <Row> */}
                                        <Col sm={10} md={24} xxl={10} style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                                            <EnvironmentOutlined style={{ fontSize: 26, marginRight: 20 }} />
                                            <span>{slot.meeting_place}<br />{slot.city}</span>
                                        </Col>
                                        <Col sm={10} md={24} xxl={10} style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                                            <CarOutlined style={{ fontSize: 26, marginRight: 20 }} />
                                            <p>{slot?.vehicle}, {slot?.licence_plate}</p><br />
                                        </Col>
                                        {
                                            slot.driving_option
                                            && <Col span={24}>
                                                <p>Papildus infromācija: <b>{slot.driving_option ? drivingOptions[slot.driving_option] : ""}</b></p>
                                            </Col>
                                        }
                                        {
                                            moment().isSameOrAfter(moment(`${slot.date} ${slot.time_from}`).subtract(30, "minutes"))
                                                ? <Col sm={10} md={24} xxl={10} style={{ display: "flex", alignItems: "center" }}>
                                                    <PhoneOutlined style={{ fontSize: 26, marginRight: 20 }} />
                                                    <a href={`tel:${slot.phone_number}`}><h3 style={{ margin: 0 }}>{slot.phone_number}</h3></a>
                                                </Col>
                                                : <></>
                                        }
                                        {/* </Row>
                                        </Col> */}
                                    </Row>
                                </Card>
                            </Col>
                        </>}
            </Col>
        </Row>
        {
            props.exam.active || props.exam.failed || props.exam.passed
                ? <></>
                : <Row justify="space-between">
                    {/* <Button onClick={() => props.activeTab("2")} type="link">Pārskatīt visas ieplānotās braukšanas</Button> */}
                    {
                        lastLesson
                            ? <Button onClick={props.toCalendar} type="link">{t('driving.top_info_card.schedule_lesson')}</Button>
                            : <Link to="/planner/select-instructor">
                                <Button
                                    icon={<UserSwitchOutlined />}
                                    type="primary"
                                >
                                    {t('driving.select_instructor')}
                                </Button>
                            </Link>
                    }
                </Row>
        }
    </Card >;
}