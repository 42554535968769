import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import IcSuccess from "../../../img/ic-small-check-xs.svg";
import IcError from "../../../img/ic-error.svg";
import IcPlanned from "../../../img/ic-nav-1.svg";
import IcWarning from "../../../img/ic-warning.svg";
import { http } from "../../../helpers/http";
import { useTranslation } from "react-i18next";

const StatsBadge = (props: any) => {
    const {
        text,
        data,
        icon,
        isMobile
    } = props;

    return <Row
        style={{
            border: isMobile ? "1px solid #E1E6EB" : "none",
            padding: isMobile ? 10 : 0
        }}
    >
        <div
            style={{
                width: 40,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#F0F2F5",
                borderRadius: "50%",
            }}
        >
            <img src={icon} />
        </div>
        <div style={{ marginLeft: 10 }}>
            <small
                style={{
                    marginBottom: 9,
                    display: "block",
                    fontSize: 11,
                    lineHeight: "12px"
                }}
            >
                {text}
            </small>
            <span
                style={{
                    fontWeight: "bold",
                    fontSize: 24,
                    display: "block",
                    lineHeight: "18px"
                }}
            >
                {data}
            </span>
        </div>
    </Row>
}

export const LearningStatsCard = (props: any) => {
    const { t } = useTranslation();
    const {
        isMobile,
        student
    } = props;
    const [counters, setCounters] = useState({} as any);

    const getStudentCounters = () => {
        http.get(`get-student-driving-info/${student.id}/${student?.category?.id}`).then((res: any) => {
            console.log(res)
            setCounters(res.data.data[0])
        })
    };

    useEffect(() => {
        getStudentCounters();
    }, []);

    return <Card style={{ height: "100%" }}>
        <h3 style={{ marginBottom: 20 }}><b>{t('driving.eval.lessons_statistics')}</b></h3> {/*Tulkojums*/}
        <Row gutter={isMobile ? [12, 12] : 0}>
            <Col span={12} xl={6}>
                <StatsBadge
                    text={t('driving.eval.visited')}
                    data={counters?.done}
                    icon={IcSuccess}
                    isMobile={isMobile}
                />
            </Col>
            <Col span={12} xl={6}>
                <StatsBadge
                    text={t('driving.eval.not_visited')}
                    data={counters?.student_didnt_come}
                    icon={IcError}
                    isMobile={isMobile}
                />
            </Col>
            <Col span={12} xl={6}>
                <StatsBadge
                    text={t('driving.eval.planned')}
                    data={counters?.new}
                    icon={IcPlanned}
                    isMobile={isMobile}
                />
            </Col>
            <Col span={12} xl={6}>
                <StatsBadge
                    text={t('driving.eval.min_lesson_count')}
                    data={student?.category?.min_driving_lessons ?? t('driving.eval.not')}
                    icon={IcWarning}
                    isMobile={isMobile}
                />
            </Col>
        </Row>
    </Card>
} 