import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

export const ColoredBadge = (props: any) => {
    const { t } = useTranslation();
    const {
        type,
        number
    } = props;

    const active = number > 0;

    const translation = {
        exam_worthy: t(`driving.eval.good`).split(" ").map((letter: string) => letter[0].toUpperCase()).join(""),
        medium: t(`driving.eval.almost_good`).split(" ").map((letter: string) => letter[0].toUpperCase()).join(""),
        beginner: t(`driving.eval.poor`).split(" ").map((letter: string) => letter[0].toUpperCase()).join(""),
        none: t(`driving.eval.not_valuated`).split(" ").map((letter: string) => letter[0].toUpperCase()).join("")
    } as any;

    const typeStyles = {
        exam_worthy: {
            backgroundColor: active ? "#1A804D" : "rgba(26, 128, 77, 0.1)",
            color: active ? "#FFFFFF" : "#1A804D"
        },
        medium: {
            backgroundColor: active ? "#E6812E" : "rgba(230, 129, 46, 0.1)",
            color: active ? "#FFFFFF" : "#E6812E"
        },
        beginner: {
            backgroundColor: active ? "#C02227" : "rgba(192, 34, 39, 0.1)",
            color: active ? "#FFFFFF" : "#C02227"
        },
        none: {
            backgroundColor: active ? "#525C66" : "rgba(82, 92, 102, 0.1)",
            color: active ? "#FFFFFF" : "#525C66"
        }
    } as any;

    return <div
        style={{
            ...{
                width: "50px",
                height: "22px",
                borderRadius: 4,
                marginLeft: 6,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 11,
                fontWeight: "bold"
            }, ...typeStyles[type]
        }}
    >
        {translation[type]}:{number}
    </div>
}