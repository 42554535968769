import { Card, Col, Collapse, Popover, Row, Space, Table, Tag } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const MyExamsCard = (props: any) => {
    const { t } = useTranslation();
    const { theoryExams, doomClock, self } = props;
    const [doneExams, setDoneExams] = useState([] as any);

    const badge = {
        passed: <Tag color="#2C9C3B">{t('theory_exams.passed')}</Tag>,
        failed: <Tag color="#E85D26">{t('theory_exams.exam_is_failed')}</Tag>,
        not_allowed: <Tag color="grey">{t('theory_exams.exam_is_not_allowed')}</Tag>,
        remaining_time: (time: number = 5, key: any) => {
            return <Tag key={key} color="#C02227">{t('theory_exams.time_remaining_csdd_exam', { time: time })}</Tag>
        }
    }

    const setCompletedExams = (exams: any) => {
        const copyExams = [...exams];
        const doneExams = copyExams.filter((exam: any) => {
            return exam.status.includes('exam') || exam.status.includes('cancelled');
        });
        //console.log(doneExams, copyExams);
        setDoneExams(doneExams);
    };

    useEffect(() => {
        //console.log(doomClock)
    }, [doomClock])

    useEffect(() => {
        setCompletedExams(theoryExams);
    }, [theoryExams]);

    const TheoryExamCollapsibleInfoCard = (props: any) => {
        const { title, badge, exams } = props;

        const status = exams.length > 0
            ? exams.some((el: any) => el.status.includes("passed")) ? ['passed'] : ['failed']
            : ['']

        const columns = [
            {
                title: t('theory_exams.exam_date'),
                dataIndex: "exam_date",
                key: "exam_date"

            },
            {
                title: t('theory_exams.try'),
                render: (record: any, all: any, rowIndex: any) => {
                    return rowIndex + 1;
                },
                key: "exam_try"
            },
            {
                title: t('theory_exams.exam_price'),
                dataIndex: "price",
                key: "price"
            },
            {
                title: t('theory_exams.exam_result'),
                render: (all: any) => {
                    return <div>
                        <b>
                            {
                                all.results.reduce((prev: any, current: any) => {
                                    const currentCount = current.correct_count === null ? 0 : current.correct_count
                                    return prev + currentCount
                                }, 0)
                            }/
                            {
                                all.results.reduce((prev: any, current: any) => {
                                    const questionCount = current.question_count === null ? 0 : current.question_count
                                    return prev + questionCount
                                }, 0)
                            }
                        </b>
                        <Popover
                            content={
                                all.results.map((result: any, index: any) => {
                                    return <div
                                        style={{
                                            padding: 20,
                                            border: "1px solid #c0c0c0",
                                            borderRadius: 4,
                                            marginBottom: 4
                                        }}
                                    >
                                        <Row key={index} gutter={24}>
                                            <Col>
                                                <p style={{ marginBottom: 8 }}>Mēģinājums:</p>&nbsp;
                                                <b>{index + 1}</b>
                                            </Col>
                                            <Col>
                                                <p style={{ marginBottom: 8 }}>Rezultāts:</p>&nbsp;
                                                <b>
                                                    {result.correct_count !== null ? result.correct_count : 0}/
                                                    {result.question_count !== null ? result.question_count : 0}
                                                </b>
                                            </Col>
                                            <Col>
                                                <p style={{ marginBottom: 8 }}>Kļūdas:</p>&nbsp;
                                                <b style={{ width: "100%", whiteSpace: "normal" }}>{result.mistakes}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                })
                            }
                            trigger="hover"
                            placement="right"
                        >
                            <InfoCircleOutlined style={{ marginLeft: 8 }} />
                        </Popover>
                    </div>
                },
                key: "exam_result"
            },
            // {
            //     title: t('theory_exams.comment'),

            // },
            {
                title: t('theory_exams.exam_result'),
                dataIndex: "status",
                render: (status: any) => {
                    return status.includes("failed")
                        ? <b style={{ color: "red" }}>{t('theory_exams.failed')}</b>
                        : status.includes("cancelled")
                            ? <b style={{ color: "red" }}>{t(`theory_exams.${status}`)}</b>
                            : <b style={{ color: "green" }}>{t('theory_exams.passed')}</b>
                },
                key: "exam_result_final"
            },
        ] as any

        return <Collapse className="theory-exam-collapse" expandIconPosition="right">
            <Collapse.Panel
                key="1"
                header=
                {
                    <Space>
                        <b>{title}</b>
                        {
                            status?.map((item: any) => {
                                if (item === "remaining_time") {
                                    return <div key={item}>{badge[item](1, item)}</div>
                                }
                                return <div key={item}>{badge[item]}</div>
                            })
                        }
                    </Space>
                }
            >
                <Table
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={exams}
                />
            </Collapse.Panel>
        </Collapse>
    }

    const ExamInfoCard = (props: any) => {
        const { title, badge, status, days } = props;
        return <div
            style={{
                border: "1px solid #D4DADE",
                borderRadius: 4,
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                padding: "10px 20px",
                boxShadow: "0 2px 0 0 rgba(0,0,0,0.01)",
                marginBottom: 10
            }}
        >
            <Space>
                <b>{title}</b>
                {
                    status?.map((item: any, index: any) => {
                        if (item === "remaining_time") {
                            return <div key={item}>{badge[item](days, item)}</div>
                        }
                        return <div key={item}>{badge[item]}</div>
                    })
                }
            </Space>
        </div>
    }

    return <Card
        title={t('theory_exams.my_exams')}
    >
        <TheoryExamCollapsibleInfoCard
            title={t('theory_exams.school_theory_exam')}
            // status={["passed"]}
            badge={badge}
            exams={doneExams}
        />
        <ExamInfoCard
            title={t('theory_exams.csdd_theory_exam')}
            status={[
                !!self.got_csdd_theory_finished
                    ? "passed"
                    : doomClock === null
                        ? "not_allowed"
                        : "remaining_time"
            ]}
            badge={badge}
            days={moment(doomClock, "YYYY-MM-DD").diff(moment(), 'days') + 1}
        />
        {/* <ExamInfoCard
            title={t('theory_exams.school_driving_exam')}
            status={["failed"]}
            badge={badge}
        />
        <ExamInfoCard
            title={t('theory_exams.csdd_driving_exam')}
            status={["not_allowed"]}
            badge={badge}
        /> */}
    </Card>
}