import { Row, Col, Button } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { http } from "../../helpers/http";

export const DrivingNotification = (props: any) => {
    const { t } = useTranslation();
    const { notification, refetch } = props;
    const style = {
        marginBottom: 20,
        padding: 30,
        backgroundColor: '#ffeece',
        border: "1px solid orange"
    }

    useEffect(() => {
        //console.log(notification)
    }, []);

    const read = () => {
        http.post(`/notification-read`, { id: notification.id }).then((response: any) => {
            //console.log(response)
            refetch();
        })
    };

    return <div style={style}>
        <Row justify="space-between">
            <Col span={20}>
                {notification.data.message}
            </Col>
            <Button onClick={read}>{t('common.understood')}</Button>
        </Row>

    </div>
}