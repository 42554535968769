import React, { useEffect, useState } from "react";
import { ReactComponent as IconCheck } from "../../../img/ic-small-check-xs.svg";
import { ReactComponent as IconOnSite } from "../../../img/ic-small-xs-klatiene.svg";
import i18n from 'i18next';

const bubble = {
    position: "relative",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
} as any

const upcoming = {
    backgroundColor: "#F0F2F5",
    color: "#8C8C8C"
} as any

const active = {
    border: "2px solid #079DC1",
    color: "#181A1F"
} as any

const done = {
    border: "1px solid #E0E6EC",
} as any

export const ProgressReel = (props: any) => {
    const { modules, passedModules, theoryLessons, foreignLessons } = props;
    const [activeModule, setActiveModule] = useState({} as any);
    const [trueLessonList, setTrueLessonList] = useState([] as any);
    const getActiveModule = () => {
        if (modules.length > 0 && passedModules.length === modules.length) return;
        setActiveModule(modules[passedModules.length])
        return modules[passedModules.length]
    }

    useEffect(() => {
        setActiveModule(getActiveModule());
    }, [modules, passedModules]);

    useEffect(() => {
        //console.log(theoryLessons, foreignLessons)
        setTrueLessonList((prev: any) => {
            const lessonArray = theoryLessons?.map((lesson: any) => {
                if (foreignLessons?.some((el: any) => el.lesson_counter === lesson.lesson_counter)) {
                    return foreignLessons?.find((el: any) => el.lesson_counter === lesson.lesson_counter);
                }
                return lesson;
            });
            //console.log(lessonArray)
            if (lessonArray) return [...lessonArray]

        })
    }, [theoryLessons, foreignLessons])

    return <div style={{ display: "flex", flexWrap: "wrap" }}>
        {
            modules?.map((module: any, index: any) => {

                //console.log(module)
                const isActive = activeModule?.id === module.id
                const isDone = passedModules.find((el: any) => el.e_module_id === module.id)

                const style = isActive
                    ? active
                    : isDone !== undefined
                        ? done
                        : upcoming
                return <div
                    key={index}
                    style={{
                        marginRight: 24,
                        marginBottom: 5,
                        ...bubble,
                        ...style
                    }}
                    title={module?.[`title_${i18n.language}`]}
                >
                    <IconCheck style={{ display: isDone ? "block" : "none", position: "absolute", top: 0, right: 0 }} />
                    {index + 1}
                </div>
            })
        }
        {
            trueLessonList?.map((lesson: any, index: any) => {
                const style = lesson?.paid && !lesson?.skipped
                    ? lesson?.attained
                        ? { ...done }
                        : { ...active }
                    : lesson?.paid && lesson?.skipped && lesson?.foreign_lesson
                        ? { backgroundColor: "red", color: "#FFFFFF" }
                        : { ...upcoming }
                //console.log(style)
                return <div
                    key={index}
                    style={{
                        marginRight: 24,
                        marginBottom: 5,
                        ...bubble,
                        ...style
                    }}
                    title={lesson?.name}
                >
                    {
                        lesson?.attained
                            ? <IconCheck style={{ position: "absolute", top: 0, right: 0 }} />
                            : <IconOnSite style={{ position: "absolute", top: 0, right: 0 }} />
                    }
                    {modules?.length + index + 1}
                </div>
            })
        }
    </div>
}