import { Button, Card, Form, Input, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { http } from '../../helpers/http';

export const NewPassword = withRouter((props: any) => {
    const { t } = useTranslation();
    const params = useParams() as any;
    const history = useHistory();

    const submitForm = async (values: any) => {
        if (values.password !== values.verify_password) {
            message.error(t('login.new_password.passwords_doesnt_match'))
        } else {
            http.post(`/change-password/${params.hash}`, {
                password: values.password,
                password_confirmation: values.verify_password
            }).then((response: any) => {
                message.success(t('login.new_password.password_changed_successfuly'))
                setTimeout(() => history.push('/'), 5000)
            }).catch(() => {
                message.error(t('errors.error'))
            })
        }
    }

    return (
        <Card title={<b>{t('login.new_password.new_password')}</b>} className='login-form'>
            <Form name="login_form" onFinish={submitForm} layout="vertical">
                <Form.Item name="password" label={t('login.new_password.new_password')}>
                    <Input.Password />
                </Form.Item>
                <Form.Item name="verify_password" label={t('login.new_password.repeat_password')}>
                    <Input.Password />
                </Form.Item>
                <small>{t('login.new_password.min_password_length')}</small><br />
                <Button style={{ marginTop: 10 }} htmlType='submit' type="primary">{t('login.new_password.confirm')}</Button>
            </Form>
        </Card>
    )
})
