import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const ThirdStep = (props: any) => {
    const { t } = useTranslation();
    const baseUrl = process.env.REACT_APP_API_URL;

    return <div>
        <h1>{t('tutorial.step_three.services')}</h1>
        <p>{t('tutorial.step_three.confirm_services')}</p>
        <p>{t('tutorial.step_three.press_accept_btn')}</p>
        <div style={{ width: "100%" }}>
            <img src={baseUrl + "storage/student-tutorial/accept-service-payment-tutorial.png"} style={{ width: "100%" }} />
        </div>
    </div>
}