import { Row, Button, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import React from "react";
import { ColoredBadge } from "../../components/ColoredBadge";
import { ReactComponent as IcError } from "../../../img/ic-error.svg";
import { ReactComponent as IcWarning } from "../../../img/ic-warning.svg";
import { useTranslation } from "react-i18next";

export const LessonStatus = (props: any) => {
    const { t } = useTranslation();
    const {
        lesson,
    } = props;

    const warningStyle = {
        display: "flex",
        padding: "6px 16px 6px 6px",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        fontSize: 12,
    }

    if (lesson.status) {
        if (lesson?.status?.includes("student_cancelled")) {
            return <div style={{ ...warningStyle, color: "#C02227" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <IcError />
                    <b style={{ marginLeft: 6 }}>{t('driving.eval.student_cancelled_lesson')}</b>
                </div>
            </div>
        } else if (lesson?.status?.includes("student_didnt_come")) {
            return <div style={{ ...warningStyle, color: "#C02227" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <IcError />
                    <b style={{ marginLeft: 6 }}>{t('driving.student_didnt_come')}</b>
                </div>
            </div>
        } else if (lesson?.status?.includes("instructor_cancelled")) {
            return <div style={{ ...warningStyle, color: "#E6812E" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <IcWarning />
                    <b style={{ marginLeft: 6 }}>{t('driving.eval.instructor_cancelled_lesson')}</b>
                </div>
            </div>
        }
    }

    return lesson.full_eval
        ? <Row style={{ flexWrap: "nowrap" }} align="middle">
            <ColoredBadge
                type="exam_worthy"
                number={lesson.grade_gradation.exam_worthy}
            />
            <ColoredBadge
                type="medium"
                number={lesson.grade_gradation.medium}
            />
            <ColoredBadge
                type="beginner"
                number={lesson.grade_gradation.beginner}
            />
        </Row>
        : lesson.feedback !== undefined || lesson.feedback
            ? !lesson.feedback?.includes('undefined')
                ? <>
                    <Tooltip placement="top" title={lesson.feedback}>
                        <InfoCircleFilled style={{ color: "#CED4D8", fontSize: 20 }} />
                    </Tooltip>
                </>
                : <></>
            : <></>
}