import { Button, Card, Col, Layout, Row } from "antd";
import { ReactComponent as GoBackArrow } from '../../img/ic-goback.svg';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import { ServiceCard } from "./ServiceCard";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

export const ExtraServicesMain = (props: any) => {
    const { t } = useTranslation();
    const { self, refreshBalance } = props;
    const history = useHistory();
    const [services, setServices] = useState([] as any);
    const [availableServices, setAvailableServices] = useState({} as any);
    const [omniva, setOmniva] = useState(undefined as any);
    const activeGroup = self.only_study_group.find((group: any) => group.category_id === self.category_id)

    const fetchServices = () => {
        http.get(`/student-study-group-services/get-services/${activeGroup.id}`).then((response: any) => {
            setServices(response.data.data)
            http.get(`get-student-common-services/${self.id}`).then((res: AxiosResponse) => {
                setAvailableServices(groupServices(activeGroup.services, res.data))
                setOmniva(mergeOmniva(response.data.data, activeGroup.services))
            })

        })
    }

    const mergeOmniva = (studentServices: any, groupServices: any) => {
        const groupOmniva = groupServices.find((service: any) => !!service.show_omniva);
        const studentOmniva = studentServices.find((service: any) => !!service.show_omniva);
        if (groupOmniva === undefined) return undefined
        return studentOmniva !== undefined
            ? studentOmniva
            : groupOmniva
    }

    const groupServices = (data: any, commonServices: any) => {
        // const omniva = mergeWithCommonServices(data.filter((service: any) => !!service.show_omniva), commonServices);

        // console.log(omniva)
        return {
            pmp: mergeWithCommonServices(data.filter((service: any) => !!service.show_medical_groups), commonServices),
            medical: mergeWithCommonServices(data.filter((service: any) => !!service.medical_check), commonServices),
            book: mergeWithCommonServices(data.filter((service: any) => !!service.book).map((service: any) => { return service }), commonServices),
        }
    };

    const mergeWithCommonServices = (services: any, commonServices: any) => {
        const reverseCmServices = commonServices.reverse();
        return services.map((service: any) => {
            const commonService = reverseCmServices.find((cmService: any) => {
                return service.study_group_service_type_id === cmService.study_group_service_type_id
            });
            console.log(service, commonService)
            if (commonService !== undefined) {
                return {
                    ...service,
                    pivot: commonService
                }
            } else {
                return service
            }
        })
    }

    useEffect(() => {
        fetchServices();
    }, []);

    useEffect(() => {
        console.log(availableServices)
    }, [availableServices])

    return <Layout style={{ minHeight: "100vh" }} className="main-content">
        <Row
            className='header-row'
            style={{
                background: "#FFF",
                borderBottom: "2px solid #e7e7e7",
                flexWrap: "wrap"
            }}
        >
            <Button
                onClick={() => history.goBack()}
                style={{ fontWeight: "bold", marginRight: 29 }}
                icon={<GoBackArrow />}
            >
            </Button>
            <b style={{ padding: "5px 29px", borderLeft: "1px solid #D9D9D9" }}>{t('navigation.extra_services')}</b>

        </Row>
        <Card>
            <Row gutter={[16, 16]}>
                {
                    Object.keys(availableServices)?.map((key: any) => {
                        if (key === "medical") {
                            return <Col key={key} sm={12} md={8} lg={6} span={24} >
                                <ServiceCard
                                    groupService={availableServices[key]}
                                    studentServices={services}
                                    self={self}
                                    type={key}
                                    refresh={() => fetchServices()}
                                    refreshBalance={() => refreshBalance()}
                                    omniva={omniva}
                                    activeGroup={activeGroup}
                                />
                            </Col>
                        }
                        return availableServices[key].map((service: any) => {
                            return <Col key={service.id} sm={12} md={8} lg={6} span={24} >
                                <ServiceCard
                                    groupService={service}
                                    studentServices={services}
                                    self={self}
                                    type={key}
                                    refresh={() => fetchServices()}
                                    refreshBalance={() => refreshBalance()}
                                    omniva={omniva}
                                    activeGroup={activeGroup}
                                />
                            </Col>
                        })
                    })
                }
            </Row>
        </Card>
    </Layout>
}