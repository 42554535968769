import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { RoundButton } from "../components/RoundButton";
import { FifthStep } from "./Steps/FifthStep";
import { EighthStep } from "./Steps/EighthStep";
import { FirstStep } from "./Steps/FirstStep";
import { FourthStep } from "./Steps/FourthStep";
import { SecondStep } from "./Steps/SecondStep";
import { SeventhStep } from "./Steps/SeventhStep";
import { SixthStep } from "./Steps/SixthStep";
import { ThirdStep } from "./Steps/ThirdStep";
import { FinalStep } from "./Steps/FinalStep";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";

export const TutorialModal = (props: any) => {
    const { t } = useTranslation();
    const { read } = props;
    const [visible, setVisible] = useState(false);
    const [steps, setSteps] = useState({
        1: {
            active: true,
            view: <FirstStep setActive={(num: any) => navigateToStep(num)} />
        },
        2: {
            active: false,
            view: <SecondStep />
        },
        3: {
            active: false,
            view: <ThirdStep />
        },
        4: {
            active: false,
            view: <FourthStep />
        },
        5: {
            active: false,
            view: <FifthStep />
        },
        6: {
            active: false,
            view: <SixthStep />
        },
        7: {
            active: false,
            view: <SeventhStep />
        },
        8: {
            active: false,
            view: <EighthStep />
        },
        9: {
            active: false,
            view: <FinalStep />
        }
    } as any);
    const [activeStep, setActiveStep] = useState(1);

    const resetModal = () => {
        setVisible(false);
    };

    const navigateToStep = (num: number) => {
        setActiveStep(Number(num))
        setSteps((prev: any) => {
            const newObj = {} as any;
            Object.keys(prev).forEach((key: any) => {
                console.log(num, key)
                newObj[key] = { ...prev[key], active: Number(key) === Number(num) }
            })
            console.log(newObj)
            return newObj
        })
    };

    useEffect(() => {
        setVisible(!read);
    }, [read])

    return <Modal
        visible={visible}
        onOk={resetModal}
        onCancel={resetModal}
        width={600}
        footer={<Row gutter={24}>
            <Col span={24} md={12}>
                <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                        if (activeStep === 1) {
                            http.post(`set-read-help`, { read_help: 1 })
                            resetModal();
                        } else {
                            navigateToStep(activeStep - 1)
                        }
                    }}
                >
                    {
                        activeStep === 1
                            ? t('tutorial.skip_tutorial')
                            : t('common.back')
                    }
                </Button>
            </Col>
            <Col span={24} md={12}>
                <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                        if (activeStep === Number(Object.keys(steps)[Object.keys(steps).length - 1])) {
                            http.post(`set-read-help`, { read_help: 1 })
                            resetModal();
                        } else {
                            navigateToStep(activeStep + 1)
                        }
                    }}
                >
                    {
                        activeStep === Number(Object.keys(steps)[Object.keys(steps).length - 1])
                            ? t('common.finish')
                            : t('common.continue')
                    }
                </Button>
            </Col>
        </Row>}
    >
        <Row gutter={14} style={{ marginBottom: 30 }}>
            {
                Object.keys(steps).map((key: any) => {
                    return <Col key={key}>
                        <RoundButton key={key} num={key} active={steps[key].active} setActive={(num: any) => navigateToStep(num)} />
                    </Col>
                })
            }
        </Row>
        <Row>
            {
                steps[activeStep].view
            }
        </Row>
    </Modal>
}