import { Button, Card, DatePicker, Layout, PageHeader, Row, Space } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { http } from '../../helpers/http';
import { ExamCalendar } from './ExamCalendar';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const TheoryExamsPlanner = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [freeSlots, setFreeSlots] = useState([] as any);
    const [price, setPrice] = useState("");
    const [studentPricesPolicy, setStudentPricesPolicy] = useState(null as any);
    const [studentExams, setStudentExams] = useState([] as any);
    const [cancelHours, setCancelHours] = useState(0);

    const getStudentTheoryExams = () => {
        http.get(`get-theory-exam-results`).then((response: any) => {
            //console.log(response)
            const filteredExams = response.data.filter((exam: any) => {
                return !exam.status.includes('admin_cancelled')
            })
            setStudentExams(filteredExams);
        })
    };

    const getCancelHours = () => {
        http.get(`get-exam-cancel-hours`).then((response: any) => {
            setCancelHours(response.data.data);
            //console.log(response)
        })
    }

    const fetchExams = () => {
        setLoading(true);
        http.get(`/get-theory-free-slots`, {
            params: {
                only_new: 1
            }
        }).then((response: any) => {
            const { slots } = response.data.data;
            const mergedSlots = new Array();
            slots.forEach((item: any) => {
                const hasSlot = mergedSlots.some((el: any) => {
                    return el.exam_date === item.exam_date
                        && el.time_from === item.time_from
                        && el.time_to === item.time_to
                });
                if (hasSlot) return;
                else mergedSlots.push(item);
            })
            const filteredSlots = mergedSlots.filter((slot: any) => {
                const date = moment(slot.exam_date, "YYYY-MM-DD");
                const time = moment(slot.time_from, "HH:mm:ss");
                if (date.isSame(moment(), "day")) {
                    //console.log(date.isSame(moment(), "day"), time.isAfter(moment("HH:mm:ss"), "hour"))
                    return time.isAfter(moment(), "minute");
                } else {
                    return date.isAfter(moment(), "day");
                }
            })
            setPrice(response.data.data.price);
            setFreeSlots(filteredSlots);
            setLoading(false);
        });
    };

    const fetchExamPrices = () => {
        http.get(`get-theory-exam-prices`).then((response: any) => {
            //console.log(response)
            setStudentPricesPolicy(response.data)
        })
    }

    useEffect(() => {
        fetchExams();
        fetchExamPrices();
        getStudentTheoryExams();
        getCancelHours();
    }, [])

    return <Layout
        className='main-content'
    >
        <PageHeader
            title={t('theory_exams.theory_exams_planner_title')}
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Card
            loading={loading}
            title={<Row justify="space-between">
                <Space>
                    <h3>{t('theory_exams.choose_exam_time')}</h3>
                    <Button
                        type="text"
                        onClick={() => {
                            fetchExamPrices();
                            fetchExams();
                        }}
                        style={{ height: "unset", width: "unset", padding: "5px 5px 0" }}
                        icon={<ReloadOutlined style={{ fontSize: 24 }} spin={loading} />}
                    />
                </Space>
            </Row>}
            style={{ marginTop: 8 }}
        >
            <ExamCalendar
                // setSelectedDay={(date: any) => setSelectedDay(date)}
                // selectedDay={selectedDay}
                studentExams={studentExams}
                examSlots={freeSlots}
                price={price}
                pricesPolicy={studentPricesPolicy}
                cancelHours={cancelHours}
                loading={loading}
            />
        </Card>
    </Layout>
}