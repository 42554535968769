import React from "react";
import { Point } from "./Point";

export const MapPoints = (props: any) => {
    const { points } = props;
    return <div
        style={{
            borderTop: "1px solid #F0F2F5",
            paddingTop: 20
        }}
    >
        {
            points.map((point: any, index: any) => {
                return <Point key={index} point={point} ind={index + 1} />
            })
        }
    </div>
}