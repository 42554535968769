import { Col, Divider, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { http } from "../../../helpers/http";
import i18n from 'i18next';

export const TheoryExamQuestionList = (props: any) => {
    const { t } = useTranslation();
    const { lesson } = props;
    const [result, setResult] = useState(null as any);

    const fetchTheoryQuestionResult = () => {
        http.get(`get-lesson-first-stage-answers/${lesson}`).then((res: any) => {
            console.log(res)
            setResult(res.data.data[0])
        })
    }

    useEffect(() => {
        fetchTheoryQuestionResult();
    }, [])

    return result
        ? <div>
            <h3><b>{t('driving.eval.theory_exam_question')}</b></h3>
            <Row style={{ flexWrap: "nowrap" }} gutter={20}>
                <Col>
                    <p style={{ margin: 0 }}>
                        <b>{result.question?.[`content_${i18n.language}`] ? result.question?.[`content_${i18n.language}`] : result.question.content_lv}</b>
                    </p>
                </Col>
                <Col>
                    <Tag style={{ fontSize: 14 }} color={result.passed ? "green" : "red"}>{result.passed ? t('driving.eval.passed') : t('driving.eval.failed')}</Tag>
                </Col>
            </Row>
            <Divider />
        </div>
        : <></>
}