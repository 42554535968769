import { Col, DatePicker, Radio, Row, Select, Spin, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { BonusAdjustForm } from "../Payment/BonusAdjustForm";

type PmpFormProps = {
    service: any;
    setDisabled: (state: boolean) => void;
    submit: boolean;
    destroyModal: () => void;
    refresh: () => void;
    refreshBalance: () => void;
    student: any;
    groupID: number;
    finalPrice: number;
    setBonus: (state: string) => void;
}

export const PmpForm = ({ service, setDisabled, submit, destroyModal, refresh, refreshBalance, student, groupID, finalPrice, setBonus }: PmpFormProps) => {
    const { t } = useTranslation();
    const [cities, setCities] = useState([] as any);
    const [languages, setLanguages] = useState([] as any);
    const [filters, setFilters] = useState({
        city: null as string | null,
        language: null as string | null,
    });
    const [groups, setGroups] = useState([] as any);
    const [group, setGroup] = useState(null as any);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [bonusAmount, setBonusAmount] = useState("0");

    const getCities = () => {
        http.get(`cities`).then((res: AxiosResponse) => {
            setCities(res.data.data)
        })
    };

    const getLanguages = () => {
        http.get(`languages`).then((res: AxiosResponse) => {
            setLanguages(res.data.data)
        })
    };

    const fetchGroups = () => {
        setGroup(null);
        setLoading(true);
        http.get(`get-student-medical-groups`, {
            params: {
                no_paginate: 1,
                availableOnly: 1,
                fe: 1
            }
        }).then((res: AxiosResponse) => {
            console.log(res)
            setLoading(false);
            setGroups(res.data.data)
        }).catch((err: AxiosError) => {
            setLoading(false);
        })
    };

    const submitPmp = async () => {
        const isExtra = service.hasOwnProperty("pivot")
            ? !!service.pivot.paid
                ? 1
                : 0
            : 0
        console.log(service.id, group)
        http.post(`add-service-for-self`, {
            services: [
                {
                    service_id: service.id,
                    group: group,
                    extra: isExtra,
                    bonus_amount: bonusAmount
                }
            ],
        }).then(async (res: AxiosResponse) => {
            if (!!!isExtra) {
                await http.post(`medical-groups-attach/${student.id}/${groupID}`, {
                    medical_group: group,
                    main: !!isExtra ? 0 : 1
                }).catch((err: AxiosError) => {
                    console.log(err.response?.data?.msg)
                    const { msg } = err.response?.data;
                    message.error("Kļūda! " + msg)
                })
            }
            refresh();
            refreshBalance();
            message.success(t('notification.service_bought_sucessfully'))
        }).catch((err: AxiosError) => {
            errorMessageResolver(err);
            const msg = err.response?.data.msg
            message.error(msg)
        })
        return true
    }

    const errorMessageResolver = (err: any) => {
        const { data, status } = err.response;
        if (status === 403) {
            if (data?.length > 0) {
                setError(`${t(`errors.${data[0].message_type}`)} ${data[0].info} ${t(`errors.error_activities.${data[0].reason}`)}`);
            }
        } else {
            setError("")
        }
    }

    useEffect(() => {
        getCities();
        getLanguages();
        fetchGroups();
    }, []);

    useEffect(() => {
        if (submit) {
            submitPmp().then(() => { destroyModal() });
        }
    }, [submit]);

    useEffect(() => {
        if (
            group === null || group === undefined
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [group])

    // useEffect(() => {
    //     console.log(filters)
    //     if (filters.city && filters.language) {
    //         fetchGroups();
    //     }
    // }, [filters])

    useEffect(() => {
        setBonus(bonusAmount)
    }, [bonusAmount])

    return <Row gutter={[20, 30]}>
        {/* <Col span={12}>
            <p style={{ marginBottom: 10 }}>{t('common.city')}</p>
            <Select
                style={{ width: 200, marginTop: 5 }}
                onChange={(value: any) => {
                    setFilters((prev: any) => {
                        return {
                            ...prev,
                            city: value
                        }
                    })
                }}
                onClick={e => e.stopPropagation()}
            >
                {
                    cities.map((city: any) => {
                        return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                    })
                }
            </Select>
        </Col>
        <Col span={12}>
            <p style={{ marginBottom: 10 }}>{t('common.language')}</p>
            <Select
                style={{ width: 200, marginTop: 5 }}
                onChange={(value: any) => {
                    setFilters((prev: any) => {
                        return {
                            ...prev,
                            language: value
                        }
                    })
                }}
                onClick={e => e.stopPropagation()}
            >
                {
                    languages.map((language: any) => {
                        return <Select.Option key={language.id} value={language.id}>{language.value}</Select.Option>
                    })
                }
            </Select>
        </Col> */}
        <Col span={24}>
            <p style={{ marginBottom: 10 }}>{t('navigation.study_groups')}</p>
            {
                loading
                    ? <Row justify="center"><Spin /></Row>
                    : <Select
                        value={group ? group : undefined}
                        style={{ width: "100%", marginTop: 5 }}
                        onChange={(value: any) => {
                            setGroup(value)
                        }}
                        onClick={e => e.stopPropagation()}
                        loading={loading}
                        optionLabelProp="label"
                    >
                        {
                            groups.map((group: any) => {
                                return <Select.Option key={group.id} value={group.id} label={<b>{group.name} {group.online_date}, {group.medical_date}</b>}>
                                    <div style={{ fontStyle: "bold", padding: 4, border: "1px solid #f0f0f0" }}>
                                        <p style={{ marginBottom: 2 }}>{group.name}</p>
                                        <p style={{ marginBottom: 2 }}>1. nod. Tiešsaistē: {group.online_date} {moment(group.online_from, "HH:mm:ss").format("HH:mm")} {moment(group.online_to, "HH:mm:ss").format("HH:mm")}</p>
                                        <p style={{ marginBottom: 2 }}>2. nod. Klātienē: {group.medical_date} {moment(group.medical_from, "HH:mm:ss").format("HH:mm")} {moment(group.medical_to, "HH:mm:ss").format("HH:mm")}</p>
                                    </div>
                                </Select.Option>
                            })
                        }
                    </Select>
            }
            <BonusAdjustForm
                amount={finalPrice}
                type="bonus_pmp"
                student={student}
                setBonusAmount={setBonusAmount}
                bonusAmount={bonusAmount}
            />
        </Col>
        {
            error.length > 0
            && <Tag color="red" style={{ marginBottom: 10, marginTop: 10, whiteSpace: "normal", width: "100%", textAlign: "center" }}>
                {error}
            </Tag>
        }
    </Row>
}