import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Input, Modal, Row, Skeleton, Space, Tabs } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { http } from "../../helpers/http";
//@ts-ignore
import ReactHtmlParser from "react-html-parser";
import i18n, { t } from "i18next";

const { confirm } = Modal;

export const Agreements = (props: any) => {
    const { student, agreement } = props;

    const [agreed, setAgreed] = useState(false);
    const [cancelComment, setComment] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [group, setGroup] = useState({} as any);

    const manipulateAgreement = (action: "agree" | "cancel") => {
        if (action === "agree") {
            setButtonLoading(true);
            http.post(`/manipulate-agreement/${student.id}`, {
                from_web: student.web_app_ref ? 1 : 0,
                approved: 1,
                group: group?.id
            }).then((response: any) => {
                setButtonLoading(false);
                window.location.href = "/";
            });
        } else {
            http.post(`/manipulate-agreement/${student.id}`, {
                from_web: student.web_app_ref ? 1 : 0,
                cancelled: 1,
                comment: cancelComment,
                group: group?.id
            }).then((response: any) => {
                window.location.href = "/";
            });
        }
    }

    const cancelHandler = () => {
        confirm({
            title: t(`login.agreement.declining`),
            icon: <ExclamationCircleOutlined />,
            content: <div>
                <b>{t('login.agreement.please_specify_decline_reason')}:</b>
                <Input.TextArea
                    onChange={(e: any) => setComment(e.target.value)}
                    autoSize={{ maxRows: 4, minRows: 4 }}
                />
            </div>,
            okText: 'Ok',
            okType: 'danger',
            cancelText: t('login.agreement.return'),
            onOk() {
                manipulateAgreement("cancel")
            },
        });
    }

    useEffect(() => {
        if (Object.keys(student).length > 0) {
            setGroup(student.only_study_group?.find((group: any) => group.category_id === student.category_id))
        }
    }, [student]);

    return (
        <Card
            style={{ width: "100%" }}
            title={<Space>
                <b>{t('login.agreement.agreements')}</b>
            </Space>}
        >
            {
                agreement === null
                    ? <Skeleton />
                    : agreement.agreements !== null
                        ? <>
                            <Tabs defaultActiveKey={i18n.language || student.language.iso}>
                                {
                                    agreement.agreements.map((agreement: any, index: any) => {
                                        const key = Object.keys(agreement)[0];
                                        const language = key.split("_");
                                        return <Tabs.TabPane key={language[language.length - 1]} tab={language[language.length - 1].toUpperCase()}>
                                            <div className="agrement-wrap-box">
                                                {ReactHtmlParser(agreement[key])}
                                            </div>
                                        </Tabs.TabPane>
                                    })
                                }
                            </Tabs>
                            {
                                !props.noControls
                                    ? <Row justify="space-between">
                                        <div>
                                            <Checkbox
                                                style={{ marginRight: 8 }}
                                                value={agreed}
                                                onChange={() => setAgreed(!agreed)}
                                            />
                                            <p style={{ display: "inline-block" }}>{t('login.agreement.read_and_agreed')}</p>
                                        </div>
                                        <Space>
                                            <Button
                                                onClick={() => manipulateAgreement("agree")}
                                                type="primary"
                                                disabled={!agreed}
                                                loading={buttonLoading}
                                            >
                                                {t('login.agreement.confirm')}
                                            </Button>
                                            <Button type="primary" onClick={cancelHandler} danger>{t('login.agreement.decline')}</Button>
                                        </Space>
                                    </Row>
                                    : <></>
                            }

                        </>
                        : agreement.cancelled
                            ? <Space style={{ marginRight: 50 }}>
                                <b>{t('login.agreement.agreement_was_declined')}</b>
                            </Space>
                            : <Space style={{ marginRight: 50 }}>
                                <b>{t('login.agreement.agreement_is_not_ready')}</b>
                            </Space>
            }
            {
                !props.noControls
                    ? <Button type="primary" onClick={props.logout}>{t('common.exit')}</Button>
                    : <></>
            }
        </Card>
    )
}
