import { Button, Card, Form, Input, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { http } from '../../helpers/http';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';

type AffiliateFormProps = {
    hasId?: boolean;
}

export const AffiliateForm = ({ ...props }: AffiliateFormProps) => {
    const { hash, id } = useParams<{ hash: string, id?: string }>();
    const { t } = useTranslation();
    const [canSend, setCanSend] = useState(process.env.REACT_APP_API_URL?.includes("eindiz"));
    const [secret, setSecret] = useState(null);
    const [form] = Form.useForm();
    const captchaAPI = process.env.REACT_APP_RECAPTCHA ? process.env.REACT_APP_RECAPTCHA : ""
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [testLink, setTestLink] = useState('' as any);

    const submitHandler = () => {
        form.validateFields().then(async (values: any) => {
            setLoading(true);
            await http.post(`/make-affiliate-request/${hash}`, { email: values.email, secret: secret }).then((response: any) => {
                if (response.data.status === "error") {
                    message.error(t('errors.error'))
                } else {
                    setDone(true);
                    console.log(response)
                    setTestLink("http://eindiz.webplace.lv:3002/ref/" + response.data.data.email_content.split(" ")[1])
                    message.success("Epasta adrese veiksmīgi pievienota")
                }
                setLoading(false);
            }).catch(() => {
                message.error(t('errors.error'))
                setLoading(false);
            })
        });
    }

    const autoSubmit = () => {
        http.post(`/monetize-affiliate/${hash}/${id}`).then((response: any) => {
            if (response.data.status === "error") {
                setError(true);
                message.error(t('errors.error'))
            } else {
                setDone(true);
                message.success("Epasta adrese veiksmīgi pievienota")
            }
        }).catch(() => {
            setError(true);
            message.error(t('errors.error'))
        });
    }

    const captchaHandler = (e: any) => {
        setSecret(e)
        if (e !== null) {
            setCanSend(true);
        } else {
            setCanSend(false);
        }
    }

    useEffect(() => {
        if (props.hasId) {
            autoSubmit();
        }
    }, [])

    return (
        <Card title={props.hasId || done ? "" : <b>Lūdzu ievadiet savu epasta adresi</b>} className='login-form'>
            {error
                ? <div style={{ textAlign: 'center' }}>
                    <h2>Kļūda!</h2>
                    <p>Pārlādējiet lapu vai sazinieties ar Einšteins administrāciju</p>
                </div>
                : done
                    ? props.hasId
                        ? <div style={{ textAlign: 'center' }}>
                            <h2>Apsveicam, tu esi veiksmīgi reģistrējis savu epasta adresi!</h2>
                            <p>Lai pieteiktos apmācībai, aizpildi pieteikumu <a href='https://einsteins.lv'>einsteins.lv</a> mājaslapā.</p>
                        </div>
                        : <div style={{ textAlign: 'center' }}>
                            <h2>Uz tavu Epasta adresi tika nosūtīta vēstule ar apstiprināšanas saiti.</h2>
                            <a href={testLink}>{testLink}</a>
                        </div>
                    : props.hasId
                        ? <div style={{ textAlign: 'center' }}>
                            <Spin size="large" />
                        </div>
                        : <Form form={form} name="login_form" onFinish={submitHandler} layout="vertical">
                            <Form.Item
                                name="email"
                                label={t('login.e_mail')}
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: t('login.new_password.please_type_right_email'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <ReCAPTCHA
                                sitekey={captchaAPI}
                                onChange={captchaHandler}
                            />
                            <Button loading={loading} disabled={!canSend} style={{ marginTop: 15 }} htmlType='submit' type="primary">{t('login.new_password.confirm')}</Button>
                        </Form>
            }
        </Card>
    )
}
