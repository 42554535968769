import { Avatar, Checkbox, Form, Layout, Row, Col, Space, Badge, Dropdown, Menu, Card, Select, Radio, Button, Input, Divider, Tag } from 'antd';
import React from 'react';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import {
    CaretDownOutlined,
} from '@ant-design/icons';
import contractText from './contractText';
import { NewPassword } from './NewPassword';
import { RenewPassword } from './RenewPassword';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import Flag from "react-flags";
import i18n from 'i18next';
import { SlotOportunity } from './SlotOportunity';
import { LoginForm } from './LoginForm';
import { AffiliateForm } from './AffiliateForm';

export const LoginPage = withRouter((props: any) => {
    const { t } = useTranslation();

    return (
        <div className='login-wrapper'>
            <img src="/image-login.jpg" alt="" />
            <div className="overlay"></div>
            <Layout className='login-layout'>
                <Row className='login-screen-row'>
                    <Col md={1}></Col>
                    <Col md={11} className='centering-col'>
                        <img className='logo' src="/logo-login.svg" alt="" />
                        <div className='flags'>
                            {
                                ['lv', 'en', 'ru'].map((lang: any) => {
                                    const iso = lang === "en" ? "gb" : lang;
                                    return <button
                                        onClick={() => {
                                            //console.log(lang)
                                            i18n.changeLanguage(lang);
                                        }}
                                    >
                                        <Flag
                                            key={lang}
                                            basePath={`${process.env.REACT_APP_API_URL}storage/flags`}
                                            format="svg"
                                            name={iso}
                                            className={i18n.language === lang ? 'active' : ''}
                                        />
                                    </button>
                                })
                            }
                        </div>
                    </Col>
                    <Col md={12} className='centering-col'>
                        <Switch>
                            {/* <Route path="/signup">
                                <Layout.Header style={{ padding: "0 16px", background: "rgb(240, 242, 245)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}>
                                    <Row gutter={20}>
                                        <Col span={6}><strong>EINŠTEINS</strong></Col>
                                        <Col span={10}>
                                            <Space>
                                                <Space>
                                                    <Switch>
                                                        <Route exact path="/signup/1">
                                                            <Badge style={{ backgroundColor: 'rgb(24, 144, 255)' }} count={1} />
                                                        </Route>
                                                        <Route path="*">
                                                            <Badge style={{ backgroundColor: '#aaa' }} count={1} />
                                                        </Route>
                                                    </Switch>
                                                    <span style={{ fontWeight: "bold" }}>Mācību&nbsp;informācija</span>
                                                </Space>
                                                <span></span>
                                                <span></span>
                                                <Space>
                                                    <Switch>
                                                        <Route exact path="/signup/2">
                                                            <Badge style={{ backgroundColor: 'rgb(24, 144, 255)' }} count={2} />
                                                        </Route>
                                                        <Route path="*">
                                                            <Badge style={{ backgroundColor: '#aaa' }} count={2} />
                                                        </Route>
                                                    </Switch>
                                                    <span style={{ fontWeight: "bold" }}>Personīgā&nbsp;informācija</span>
                                                </Space>
                                                <span></span>
                                                <span></span>
                                                <Space>
                                                    <Switch>
                                                        <Route exact path="/signup/3">
                                                            <Badge style={{ backgroundColor: 'rgb(24, 144, 255)' }} count={3} />
                                                        </Route>
                                                        <Route path="*">
                                                            <Badge style={{ backgroundColor: '#aaa' }} count={3} />
                                                        </Route>
                                                    </Switch>
                                                    <span style={{ fontWeight: "bold" }}>Mācību&nbsp;līgums</span>
                                                </Space>
                                            </Space>
                                        </Col>
                                        <Col span={8}>
                                            <Space style={{ float: 'right' }}>
                                                <Avatar style={{ backgroundColor: "orange", verticalAlign: 'middle' }} size="large">
                                                    AB
                                                </Avatar>
                                                <span></span>
                                                <Dropdown overlay={
                                                    <Menu>
                                                        <Menu.Item><Link to="/">Iziet</Link></Menu.Item>
                                                    </Menu>
                                                } placement="bottomLeft">
                                                    <Space style={{ cursor: "normal" }}>
                                                        <span>Andris Bērziņš</span> <CaretDownOutlined />
                                                    </Space>
                                                </Dropdown>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Layout.Header>
                                <Row style={{ marginTop: 40, marginBottom: 20 }}>
                                    <Col span={6}></Col>
                                    <Col span={12}>
                                        <Switch>
                                            <Route exact path="/signup/1">
                                                <Card title="Izvēlaties mācību grupu">
                                                    <Form name="signup_step1" onFinish={() => { }} layout="vertical">
                                                        <Row gutter={20}>
                                                            <Col span={12}>
                                                                <Form.Item name="category_id" label=`${t('common.category')}`>
                                                                    <Select defaultValue={1}>
                                                                        <Select.Option value={1}>B kategorija</Select.Option>
                                                                        <Select.Option value={2}>A kategorija</Select.Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name="environment" label="Apmācību vide">
                                                                    <Radio.Group defaultValue={"onsite"}>
                                                                        <Radio.Button value={"onsite"}>Klātiene</Radio.Button>
                                                                        <Radio.Button value={"online"}>E-apmācība</Radio.Button>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={20}>
                                                            <Col span={12}>
                                                                <Form.Item name="language_id" label="Valoda">
                                                                    <Radio.Group defaultValue={1}>
                                                                        <Radio.Button value={1}>Latviešu</Radio.Button>
                                                                        <Radio.Button value={2}>Krievu</Radio.Button>
                                                                        <Radio.Button value={3}>Angļu</Radio.Button>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name="city_id" label="Pilsēta">
                                                                    <Select defaultValue={1}>
                                                                        <Select.Option value={1}>Rīga</Select.Option>
                                                                        <Select.Option value={2}>Valmiera</Select.Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Form.Item>
                                                            <Space>
                                                                <Link to="/signup/2"><Button htmlType="submit" type="primary">Tālāk</Button></Link>
                                                            </Space>
                                                        </Form.Item>
                                                    </Form>
                                                </Card>
                                            </Route>
                                            <Route exact path="/signup/2">
                                                <Card title="Norādiet personīgo informāciju">
                                                    <div style={{ marginBottom: 20 }}>
                                                        Šī informācija mums nepieciešama, lai reģistrētu Jūs CSDD sistēmā
                                                    </div>
                                                    <Form name="signup_step2" onFinish={() => { }} layout="vertical">
                                                        <Row gutter={20}>
                                                            <Col span={12}>
                                                                <Form.Item name="personal_identity_number" label="Personas kods">
                                                                    <Input placeholder="XXXXXX-XXXXX" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name="passport_or_id_number" label="Apmācību vide">
                                                                    <Input placeholder="4000000000000" />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={20} style={{ marginBottom: 10 }}>
                                                            <Col span={24}>
                                                                Lai kļūtu par autoskolas "Einšteins" studentu, Jums jānorāda Latvijā deklarēta dzīvesvieta, kurā esat uzturējies pēdējos 10 mēnešus
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={20}>
                                                            <Col span={24}>
                                                                <Form.Item name="declared_address" label="Deklarētā adrese">
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Form.Item>
                                                            <Space>
                                                                <Link to="/signup/3"><Button htmlType="submit" type="primary">Tālāk</Button></Link>
                                                            </Space>
                                                        </Form.Item>
                                                    </Form>
                                                </Card>
                                            </Route>
                                            <Route exact path="/signup/3">
                                                <Card style={{ marginBottom: 20 }} title="Apstipriniet mācību līgumu">
                                                    <Form name="signup_step1" onFinish={() => { }} layout="vertical">
                                                        <Form.Item label="Līguma teksts">
                                                            <Input.TextArea value={contractText} rows={12} />
                                                        </Form.Item>
                                                        <Form.Item style={{ marginTop: 20, borderTop: "1px solid #ddd", paddingTop: 5, }} name="accept_contract">
                                                            <Space>
                                                                <Checkbox />
                                                                <div style={{ padding: 5, fontSize: 12 }}>
                                                                    Spiežot "Tālāk" jūs piekrītat lietošanas noteikumiem un privātuma politikai
                                                                </div>
                                                            </Space>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Space>
                                                                <Link to="/signup/done"><Button htmlType="submit" type="primary">Tālāk</Button></Link>
                                                            </Space>
                                                        </Form.Item>
                                                    </Form>
                                                </Card>
                                            </Route>
                                        </Switch>
                                    </Col>
                                    <Col span={6}></Col>
                                </Row>
                            </Route>
                            <Route path="/register">
                                <Card className='login-form' title="Reģistrācija">
                                    <div style={{ marginBottom: 20 }}>Lai turpinātu pieteikšanos norādiet informāciju par sevi</div>
                                    <Form name="registration_form" onFinish={() => { }} layout="vertical">
                                        <Form.Item name="first_name" label="Vārds">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="last_name" label="Uzvārds">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="phone_number" label="Telefons">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="email" label="E-pasts">
                                            <Input type="email" />
                                        </Form.Item>
                                        <Form.Item name="agreement">
                                            <Space>
                                                <Checkbox />
                                                <div style={{ padding: 5, fontSize: 12 }}>
                                                    Spiežot "Tālāk" jūs piekrītat lietošanas noteikumiem un privātuma politikai
                                                </div>
                                            </Space>
                                        </Form.Item>
                                        <Form.Item>
                                            <Space>
                                                <Link to="/signup/1"><Button htmlType="submit" type="primary">Tālāk</Button></Link>
                                            </Space>
                                        </Form.Item>
                                    </Form>
                                </Card>
                            </Route> */}
                            <Route path="/ref/:hash/:id">
                                <AffiliateForm hasId={true}/>
                            </Route>
                            <Route path="/ref/:hash">
                                <AffiliateForm />
                            </Route>
                            <Route path="/slot-opportunity/:token">
                                <SlotOportunity />
                            </Route>
                            <Route exact path="/new-password/:hash">
                                <NewPassword />
                            </Route>
                            <Route exact path="/renew-password">
                                <RenewPassword />
                            </Route>
                            <Route path="/">
                                <LoginForm error={props.error} onLogin={(values: any) => props.onLogin(values)} />
                            </Route>
                        </Switch>
                    </Col>
                </Row>
            </Layout>
        </div>

    )
})
