import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { ReactComponent as IcCalendar } from "../../../img/ic-calendar.svg";
import { ReactComponent as IcLevel } from "../../../img/ic-level.svg";
import { ReactComponent as IcError } from "../../../img/ic-error.svg";
import { ReactComponent as IcWarning } from "../../../img/ic-warning.svg";
import { InfoBadge } from "../../components/InfoBadge";
import moment from "moment";
import { ColoredBadge } from "../../components/ColoredBadge";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Warning = (props: any) => {
    const { t } = useTranslation();
    const { status } = props;

    const warningStyle = {
        display: "flex",
        padding: "6px 16px 6px 6px",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        fontSize: 12
    }

    if (status.includes("student_cancelled")) {
        return <div style={{ ...warningStyle, color: "#C02227", backgroundColor: "rgba(192, 34, 39, .1)" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <IcError />
                <b style={{ marginLeft: 6 }}>{t('driving.eval.student_cancelled_lesson')}</b>
            </div>
        </div>
    } else if (status.includes("student_didnt_come")) {
        return <div style={{ ...warningStyle, color: "#C02227", backgroundColor: "rgba(192, 34, 39, .1)" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <IcError />
                <b style={{ marginLeft: 6 }}>{t('driving.student_didnt_come')}</b>
            </div>
        </div>
    } else if (status.includes("instructor_cancelled")) {
        return <div style={{ ...warningStyle, color: "#E6812E", backgroundColor: "rgba(230, 129, 46, .1)" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <IcWarning />
                <b style={{ marginLeft: 6 }}>{t('driving.eval.instructor_cancelled_lesson')}</b>
            </div>
        </div>
    } else {
        return <></>
    }
}

export const MobileHistoryLessons = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const {
        lesson,
        translation,
        exam
    } = props;

    return <Row
        gutter={[10, 10]}
        justify="space-between"
        style={{ border: !!!lesson.full_eval ? "1px solid #F0F0F0" : "none", marginBottom: 4 }}
    >
        <Col>
            <InfoBadge
                Icon={IcCalendar}
                text={t('common.date')}
                data={lesson.lesson_date}
            />
            {
                !!lesson.full_eval
                && <InfoBadge
                    Icon={IcLevel}
                    text={t('driving.eval.lesson_lvl')}
                    data={exam ? t('driving.exam') : translation[lesson.grade]}
                />
            }

        </Col>
        {
            !exam && !!lesson.full_eval
            && <Col>
                <div style={{ marginBottom: 4 }}>
                    <ColoredBadge
                        type="exam_worthy"
                        number={lesson.grade_gradation.exam_worthy}
                    />
                </div>
                <div style={{ marginBottom: 4 }}>
                    <ColoredBadge
                        type="medium"
                        number={lesson.grade_gradation.medium}
                    />
                </div>
                <div style={{ marginBottom: 4 }}>
                    <ColoredBadge
                        type="beginner"
                        number={lesson.grade_gradation.beginner}
                    />
                </div>
            </Col>
        }

        <Warning status={lesson.status} />
        {
            !!lesson.full_eval
            && <Col span={24}>
                <Button
                    style={{ width: "100%" }}
                    onClick={() => history.push(`/driving/stats/${lesson.student_lesson_id}${exam ? "/exam" : ""}`)}
                >
                    {t('common.open')}
                </Button>
            </Col>
        }
    </Row>
}