import { Button, Card, Empty, Layout, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Notification } from '../PlannerPage/Notification';
import { http } from "../../helpers/http";
import { useHistory } from "react-router-dom";

export const NotificationsLayout = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [notifications, setNotifications] = useState([] as any);
    const [customNotifications, setCustomNotifications] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [dataState, setDataState] = useState("new" as "new" | "history");

    const fetchNotifications = async () => {
        //API need
        setLoading(true);
        http.get('notifications-unread', {
            params: dataState === "history" ? { historic: 1 } : {}
        }).then((response) => {
            setNotifications(response.data)
            if (response.data.length > 0) {
                window.scrollTo({ top: 5, left: 0, behavior: 'smooth' });
            }
        })
        await http.get('get-custom-notifications', {
            params: dataState === "history" ? { historic: 1 } : {}
        }).then((response) => {
            //console.log(response.data)
            setCustomNotifications(response.data.data);
        })
        setLoading(false);
    }

    const deleteNotification = (id: any, custom: boolean = false) => {
        if (custom) {
            setCustomNotifications((data: any) => {
                const newList = data.filter((item: any) => {
                    return item.id !== id;
                })
                return newList;
            })
            return;
        }
        setNotifications((data: any) => {
            const newList = data.filter((item: any) => {
                return item.id !== id;
            })
            return newList;
        })
    }

    useEffect(() => {
        fetchNotifications();
    }, [dataState])

    return <Layout
        className='main-content'
        style={{ padding: 0 }}
    >
        <PageHeader
            style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap', marginBottom: 10 }}
            onBack={() => { history.goBack(); }}
            title={
                <div style={{ display: "flex", alignItems: "center" }}>
                    <h4 style={{ margin: 0 }}><b>Paziņojumi</b></h4>
                    <Button
                        style={{ marginLeft: 10 }}
                        onClick={() => setDataState(dataState === "new" ? "history" : "new")}
                        loading={loading}
                    >
                        {dataState === "new" ? t('common.show_history') : t('common.show_new')}
                    </Button>
                </div>
            }
        />

        {
            notifications.length > 0 || customNotifications.length > 0
                ? <Card loading={loading} style={{ width: "100%", marginBottom: 20 }} title={dataState === "new" ? t('common.notifications') : t('common.history')}>
                    {
                        customNotifications?.map((notific: any) => {
                            return <Notification
                                key={notific.id}
                                deleteFromList={deleteNotification}
                                notification={notific}
                                refetch={() => { }}
                                refreshBalance={() => props.refreshBalance()}
                                custom
                                history={dataState === "history"}
                                student={props.student}
                            />
                        })
                    }
                    {notifications?.map((item: any) => {
                        return <Notification
                            key={item.id}
                            deleteFromList={deleteNotification}
                            notification={item}
                            refetch={() => { }}
                            refreshBalance={() => props.refreshBalance()}
                            history={dataState === "history"}
                            student={props.student}
                        />
                    })}
                </Card>
                : <Empty description="Nav paziņojumu" />
        }
    </Layout>
}