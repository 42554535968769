import { Button, Col, Divider, Input, Modal, Row, Select, Skeleton, Spin, Tag, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import CoinImage from "../../img/einstein-coin.png";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { BonusAdjustForm } from "./BonusAdjustForm";
import { useTranslation } from "react-i18next";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";

type ServicePaymentModalProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    type: 'bonus_registration' | 'bonus_books' | 'bonus_theory' | 'bonus_pmp' | 'bonus_school_exam' | 'bonus_extra_theory' | 'bonus_full_training' | 'bonus_omniva'
    amount: number;
    student: any;
    pay: (bonusAmount: number, group: number | undefined) => void;
}

const styles = {
    amountText: {
        fontSize: 17,
        fontWeight: 700,
    },
    numberInputBtn: {
        width: 38,
        height: 38,
        fontSize: 22,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#079DC1",
    }
}

export const ServicePaymentModal = ({ ...props }: ServicePaymentModalProps) => {
    const { t } = useTranslation();
    const [availableBonus, setAvailableBonus] = useState(undefined as any);
    const [bonusAmount, setBonusAmount] = useState("0");
    const [groups, setGroups] = useState([] as any);
    const [group, setGroup] = useState(undefined as any);
    const [loading, setLoading] = useState(false);

    const fetchGroups = () => {
        setGroup(undefined);
        setLoading(true);
        http.get(`get-student-medical-groups`, {
            params: {
                no_paginate: 1,
                availableOnly: 1,
                fe: 1
            }
        }).then((res: AxiosResponse) => {
            console.log(res)
            setLoading(false);
            setGroups(res.data.data)
        }).catch((err: AxiosError) => {
            setLoading(false);
        })
    };

    const payHandler = () => {
        props.pay(Number(bonusAmount), group);
        props.setVisible(false);
    }

    useEffect(() => {
        if (props.visible && props.type === 'bonus_pmp') {
            fetchGroups();
        }
    }, [props.visible])

    return <Modal
        visible={props.visible}
        onCancel={() => props.setVisible(false)}
        onOk={() => payHandler()}
        footer={<Row justify="end">
            <Button
                onClick={() => props.setVisible(false)}
                style={{ marginRight: 15 }}
            >
                {t('common.cancel')}
            </Button>
            <Button
                type="primary"
                onClick={() => payHandler()}
                disabled={Number(bonusAmount) > availableBonus?.maxDiscount}
                style={{ backgroundColor: "#079DC1" }}
            >
                {t('common.pay')}
            </Button>
        </Row>}
    >
        <h2>{t('payments.payment')}</h2>
        <p>{t('common.service')}:</p>
        <h3>{t(`bonus.${[props.type]}`)}</h3>
        <Divider />
        {
            props.type === 'bonus_pmp'
            && <div style={{ width: "100%" }}>
                <p style={{ marginBottom: 10 }}>{t('navigation.study_groups')}</p>
                {
                    loading
                        ? <Row justify="center"><Spin /></Row>
                        : <Select
                            value={group ? group : undefined}
                            style={{ width: "100%", marginTop: 5 }}
                            onChange={(value: any) => {
                                setGroup(value)
                            }}
                            onClick={e => e.stopPropagation()}
                            loading={loading}
                            optionLabelProp="label"
                        >
                            {
                                groups.map((group: any) => {
                                    return <Select.Option key={group.id} value={group.id} label={<b>{group.name} {group.online_date}, {group.medical_date}</b>}>
                                        <div style={{ fontStyle: "bold", padding: 4, border: "1px solid #f0f0f0" }}>
                                            <p style={{ marginBottom: 2 }}>{group.name}</p>
                                            <p style={{ marginBottom: 2 }}>1. nod. Tiešsaistē: {group.online_date} {moment(group.online_from, "HH:mm:ss").format("HH:mm")} {moment(group.online_to, "HH:mm:ss").format("HH:mm")}</p>
                                            <p style={{ marginBottom: 2 }}>2. nod. Klātienē: {group.medical_date} {moment(group.medical_from, "HH:mm:ss").format("HH:mm")} {moment(group.medical_to, "HH:mm:ss").format("HH:mm")}</p>
                                        </div>
                                    </Select.Option>
                                })
                            }
                        </Select>
                }
                <Divider />
            </div>
        }
        <p>{t('payments.payment_for_service')}:</p>
        <h3>
            {
                Number(bonusAmount) > 0
                && <s style={{ ...styles.amountText, marginRight: 10 }}>{Number(props.amount).toFixed(2)}</s>
            }
            <span style={{ ...styles.amountText, color: "#079DC1" }}>{(props.amount - Number(bonusAmount)).toFixed(2)}</span>
        </h3>
        <BonusAdjustForm
            amount={props.amount}
            type={props.type}
            student={props.student}
            setBonusAmount={(bonus: string) => setBonusAmount(
                Number(props.amount) - Number(bonus) >= 0
                    ? bonus
                    : "0"
            )}
            bonusAmount={bonusAmount}
        />
    </Modal>
}