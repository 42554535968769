import { AutoComplete, Button, Calendar, Card, Col, Layout, Modal, Row, Select, Skeleton, Space, Statistic } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { http } from '../../helpers/http';
import { PaymentHelp } from '../PaymentsPage/PaymentHelp';
import { InstructorCard } from './InstructorCard';
import { ReactComponent as GoBackArrow } from '../../img/ic-goback.svg';
import { useTranslation } from 'react-i18next';

export const SelectInstructorView = withRouter((props: any) => {
    const { t } = useTranslation();
    const { isFirst, history } = props;
    const [availableInstructors, setAvailableInstructors] = useState([] as any);
    const [loaded, setLoaded] = useState(false);
    const [loadingInstructors, setLoadingInstructors] = useState(true);
    const [self, setSelf] = useState({} as any);
    const [classifiers, setClassifiers] = useState({
        languages: [],
        cities: [],
        transmission: [
            {
                name: t('common.transmission.manual'),
                value: "manual"
            },
            {
                name: t('common.transmission.auto'),
                value: "auto"
            },
        ]
    })
    const [filters, setFilters] = useState(
        {
            transmission: "",
            language: "",
            city_id: "",
        }
    );
    const [balance, setBalance] = useState(null as any);

    const fetchClassifiers = async () => {
        const languages = await http.get("/languages");
        const cities = await http.get("/cities");
        setClassifiers((data: any) => {
            return {
                ...data,
                languages: languages.data.data,
                cities: cities.data.data
            }
        })
    }

    const fetchSelf = async () => [
        await http.get('/self').then((response: any) => {
            setSelf(response.data.data);
        })
    ]


    async function getAvailableInstructors() {
        setLoadingInstructors(true);
        const instructors = await http.get(`available_instructors?transmission=${filters.transmission}&language=${filters.language}&city=${filters.city_id}`).then((response: any) => {
            //console.log(response)
            if (response.data.data !== null) {
                return Object.keys(response.data.data).map((key: any) => {
                    return response.data.data[key]
                })
            }
            return [];
        });
        const i = instructors.sort((a: any, b: any) => {
            const aCounter = a?.free_slot_count;
            const bCounter = b?.free_slot_count;
            return bCounter - aCounter;
        });
        const bResult = await http.get("balance");
        const balance = bResult.data.data;
        setBalance(balance);
        return i;
    }

    useEffect(() => {
        fetchClassifiers();
        fetchSelf();
    }, []);

    useEffect(() => {
        if (Object.keys(self).length > 0) {
            setFilters((data: any) => {
                return {
                    ...data,
                    transmission: self.transmission || '',
                    city_id: self.city?.id || '',
                    language: self.language?.id || ''
                }
            })
        }
    }, [self])

    useEffect(() => {
        //console.log(classifiers)
    }, [classifiers])

    useEffect(() => {
        if (Object.keys(self).length > 0) {
            getAvailableInstructors().then(setAvailableInstructors).then(() => {
                setLoaded(true);
            });
        }
    }, [filters])

    function goToCalendar(id: any, city_id: any) {
        history.push(`/planner/calendar/${id}`, { city_id });
    }

    const citySelectHandler = (value: any) => {
        setFilters((data: any) => {
            return {
                ...data,
                city_id: value,
            }
        })
    }

    useEffect(() => {
        setLoadingInstructors(false)
        //console.log("loaded")
    }, [availableInstructors])

    const languageSelectHandler = (value: any) => {
        setFilters((data: any) => {
            return {
                ...data,
                language: value,
            }
        })
    }

    const transmissionSelectHandler = (value: any) => {
        setFilters((data: any) => {
            return {
                ...data,
                transmission: value,
            }
        })
    }

    const eraseFilter = () => {
        setFilters((data: any) => {
            return {
                ...data,
                transmission: self.transmission,
                city_id: self.city.id,
                language: self.language.id
            }
        })
    }

    const normalLayout = (
        <Layout className="main-content">
            <Row
                className='select-instructor-header-row'
                style={
                    {
                        background: "#FFFFFF",
                        borderBottom: "2px solid #e7e7e7",
                        // flexWrap: 'w',
                        height: "unset"
                    }
                }
            >
                <Col span={24} style={{ boxShadow: "0 1px 4px 0 rgba(0,21,41,0.08), 0 1px 0 0 #E2E7EA" }}>
                    <Row justify="space-between" align="middle" style={{ backgroundColor: "#FFFFFF" }}>
                        <Col sm={14}>
                            {
                                isFirst
                                    ? <b>{t('driving.select_instructor')}</b>
                                    : <div>
                                        <Link to="/planner">
                                            <Button
                                                style={{ fontWeight: "bold", marginRight: 29 }}
                                                icon={<GoBackArrow />}
                                            >
                                            </Button>
                                        </Link>
                                        <b style={{ padding: "5px 29px", borderLeft: "1px solid #D9D9D9" }}>{t('driving.select_your_instructor')}</b>
                                    </div>
                            }
                        </Col>
                        {/* <Col sm={10} style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                            <Statistic title={"Konta atlikums"} value={balance} precision={2} />
                            <Link style={{ marginLeft: 5 }} to={`/planner/payment-help`}><Button type="primary">Papildināt</Button></Link>
                        </Col> */}
                    </Row>
                </Col>
                <Col span={24} style={{ paddingLeft: 24 }}>
                    <Row className='select-instructor-filter-row' align="middle" justify='space-between' style={{ padding: "0 10px" }}>
                        <Col xs={24} lg={21}>
                            <Row>
                                <b style={{ marginRight: 30 }}>{t('driving.filter_by')}:</b>
                                <Select
                                    className='filter-select'
                                    value={filters.transmission === "" ? undefined : filters.transmission}
                                    style={{ width: "150px", marginLeft: 10 }}
                                    onChange={transmissionSelectHandler}
                                    placeholder={t('common.transmission.transmission')}
                                >
                                    {classifiers.transmission.map((trans: any) => {
                                        return <Select.Option key={trans.value} value={trans.value}>{trans.name}</Select.Option>
                                    })}
                                </Select>
                                <Select
                                    className='filter-select'
                                    value={filters.language === "" ? undefined : filters.language}
                                    style={{ width: "150px", marginLeft: 10 }}
                                    onChange={languageSelectHandler}
                                    placeholder={t('common.language')}
                                >
                                    {classifiers.languages.map((lang: any) => {
                                        return <Select.Option key={lang.id} value={lang.id}>{lang.value}</Select.Option>
                                    })}
                                </Select>
                                <Select
                                    className='filter-select'
                                    value={filters.city_id === "" ? undefined : filters.city_id}
                                    style={{ width: "150px", marginLeft: 10 }}
                                    onChange={citySelectHandler}
                                    placeholder={t('common.city')}
                                >
                                    {classifiers.cities.map((city: any) => {
                                        return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                                    })}
                                </Select>
                            </Row>
                        </Col>
                        <Button className='reset-btn' onClick={eraseFilter}>{t('common.undo')}</Button>
                    </Row>
                </Col>
            </Row>
            <Layout.Content style={{ padding: "16px" }}>
                <Row gutter={[24, 24]}>
                    {
                        availableInstructors.length > 0
                            ? availableInstructors.map((instructor: any) => {
                                return <Col xxl={6} xl={8} lg={12} xs={24}>
                                    <InstructorCard
                                        callback={goToCalendar}
                                        key={`instructor-card-${instructor.id}`}
                                        instructor={instructor}
                                        filter={filters}
                                        type="card"
                                        loading={loadingInstructors}
                                    />
                                </Col>
                            })
                            : t('driving.not_found_instructor_by_criteria')
                    }
                </Row>
            </Layout.Content>
        </Layout>
    );

    const paymentHelpLayout = (
        <PaymentHelp />
    );

    return loaded
        ? normalLayout
        : <Layout className='skeleton-main-layout' style={{ height: "100vh" }}><Skeleton /></Layout>;
})