import React from "react";

export const RoundButton = (props: any) => {
    const { num, active, setActive } = props;

    return <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 32,
            height: 32,
            borderRadius: "50%",
            backgroundColor: active ? "#079DC1" : "#F0F2F5",
            color: active ? "#FFFFFF" : "#667380",
            cursor: "pointer"
        }}
        onClick={() => setActive(num)}
    >
        {num}
    </div>
}