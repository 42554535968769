import { Col, Row, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { ColoredBadge } from "../../components/ColoredBadge";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';



export const TopicHeader = (props: any) => {
    const { t } = useTranslation();
    const {
        isMobile,
        number,
        active,
        topic,
        description,
        questions,
        subjectHistory
    } = props;
    const [data, setData] = useState({} as any);
    const [completeCount, setCompleteCount] = useState({
        exam_worthy: 0,
        medium: 0,
        beginner: 0,
        none: 0
    } as any)

    useEffect(() => {
        const newSubjects = topic.subjects?.map((subject: any) => {
            return {
                ...subject,
                results: subjectHistory[subject.id]
            }
        });
        console.log(newSubjects)
        let counters = {
            exam_worthy: 0,
            medium: 0,
            beginner: 0,
            none: 0
        } as any;
        newSubjects.forEach((subject: any) => {
            if (subject.results === undefined) {
                counters.none++;
                return;
            }
            counters[JSON.parse(JSON.stringify(subject.results.grades)).reverse()[0]]++;
        })
        setCompleteCount(counters);
        setData(() => {
            return {
                ...topic,
                subjects: [
                    ...newSubjects
                ]
            }
        })
    }, [topic, subjectHistory]);

    useEffect(() => {
        // console.log(data)
    }, [data])

    return <Row justify="space-between">
        <Col>
            <Row style={{ flexWrap: "nowrap" }}>
                <div
                    style={{
                        backgroundColor: active ? "#059DC1" : "#F0F2F5",
                        borderRadius: "50%",
                        width: 32,
                        height: 32,
                        color: active ? "#FFFFFF" : "#525C66",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 10
                    }}
                >
                    {number}
                </div>
                <Col>
                    <b style={{ marginRight: 10 }}>{topic?.[`theme_${i18n.language}`] ? topic?.[`theme_${i18n.language}`] : topic?.theme_lv}</b>
                    {
                        description
                        && <>
                            <Tooltip placement="top" title={description}>
                                <InfoCircleFilled style={{ color: "#CED4D8", fontSize: 20 }} />
                            </Tooltip><br />
                        </>
                    }
                    <small>{questions.length} {t('driving.eval.topics')}</small>
                </Col>
            </Row>
        </Col>
        <Col span={24} lg={8}>
            <Row style={{ flexWrap: "nowrap" }} align="middle" justify="end">
                {
                    Object.keys(completeCount).map((key: any, index: any) => {
                        return <ColoredBadge
                            key={index}
                            type={key}
                            number={completeCount[key]}
                            isMobile={isMobile}
                        />
                    })
                }
            </Row>
        </Col>
    </Row>
}

