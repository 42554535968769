import { Card, Col, Layout, Row, Typography, Button, Collapse, Form, InputNumber, Input, Space, Radio, Divider, Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { CloseCircleTwoTone } from "@ant-design/icons"
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

var thePath = document.location.pathname;
var result = thePath.split('/');
var token = result[result.length - 1];
//this should return student by token, shoots only once per token! 
//after that need to change student_login_tokens set to null consumed_at current token in db to continue testing
if (token.length > 40) http.get('tokenizer/' + token)
    .then((result: any) => {
        //console.log(result);
        if (typeof result.data.data.api_token !== "undefined") {
            sessionStorage.setItem("bearer_token", result.data.data.api_token);
            window.location.reload()
        }
    });

export const PaymentError = withRouter((props: any) => {
    const { t } = useTranslation();
    const [deposit, setDeposit] = useState(0);
    const [balance, setBalance] = useState(null as any);
    const [student, setStudent] = useState(null as any);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const p1 = http.get('self')
            .then((result: any) => { setStudent(result.data.data) });

        const p2 = http.get('balance')
            .then((result: any) => { setBalance(result.data.data.balance); })

        Promise.all([p1, p2])
            .then(() => { setLoaded(true) });
    }, []);

    return (
        <Layout className="main-content">
            <Card style={{ marginBottom: 5 }}>
                <Row gutter={20} justify="space-between">
                    <Col>
                        <Title level={3} style={{ marginLeft: 30, marginBottom: 0 }}>
                            {t('payments.payment')}
                        </Title>
                    </Col>
                    <Col>
                        <Link to={`/planner`}><Button type="primary">{t('payments.return')}</Button></Link>
                    </Col>
                </Row>
            </Card>
            {loaded ?
                <Row>
                    <Col className="payment-col" xl={16}>
                        <Card style={{ margin: 24 }} title={<Title level={5}>{t('payments.account_replenishment')}</Title>}>
                            {/* <Button onClick={() => console.log()}>!</Button> */}
                            <Row>
                                <Col span={24} style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "44px 24px 24px" }}>
                                    <CloseCircleTwoTone twoToneColor="#c10707" style={{ fontSize: 42, marginBottom: 20 }} />
                                    <Title style={{ textAlign: "center" }} level={5}>{t('payments.payment_failed')}.</Title>
                                    <Text style={{ fontWeight: "normal", color: "#575757", lineHeight: "22px", fontSize: 14, textAlign: "center", marginBottom: 20 }}>
                                        {t('payments.error_payment_info_text')}
                                    </Text>
                                    <Link to="/planner/payment-help">
                                        <Button
                                            className="custom-btn"
                                            style={{ marginBottom: 44 }}
                                            type="primary">
                                            {t('payments.try_again')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="deposit-info-col" xl={8}>
                        <Card style={{ borderTop: "2px solid #079DC1" }}>
                            <Row>
                                <Col>
                                    <Title level={5}>{t('payments.hi')}, {student?.first_name} {student?.last_name}</Title>
                                    <small>{t('payments.your_account_balance')}</small>
                                    <Title level={3}>{balance} EUR</Title>
                                    {/* <Button onClick={() => console.log(props)}>!</Button> */}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                : <><Skeleton /></>}
        </Layout>
    );
});
