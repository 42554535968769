import { Row, Col, Tooltip, Button, Input, Tag, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { InfoCircleFilled } from "@ant-design/icons";
import CoinImage from "../../img/einstein-coin.png";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";

type BonusAdjustFormProps = {
    type: 'bonus_registration' | 'bonus_books' | 'bonus_theory' | 'bonus_pmp' | 'bonus_school_exam' | 'bonus_extra_theory' | 'bonus_driving' | 'bonus_full_training' | 'bonus_omniva';
    amount: number;
    student: any;
    setBonusAmount: (amount: string) => void;
    bonusAmount: string;
    externalSetting?: any;
}

const styles = {
    amountText: {
        fontSize: 17,
        fontWeight: 700,
    },
    numberInputBtn: {
        width: 38,
        height: 38,
        fontSize: 22,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#079DC1",
    }
}

export const BonusAdjustForm = ({ ...props }: BonusAdjustFormProps) => {
    const { t } = useTranslation();
    const [availableBonus, setAvailableBonus] = useState(undefined as any);
    // const [bonusAmount, setBonusAmount] = useState("0");
    const [loading, setLoading] = useState(false);
    const [minimalBonusAmount, setMinimalBonusAmount] = useState(props.bonusAmount);
    const [bonusBalance, setBonusBalance] = useState(props.student?.bonus_balance);

    const checkStudentBonus = () => {
        setLoading(true);
        http.post('check-student-bonus', {
            bonus_type: props.type,
            amount: props.amount > 0 ? props.amount : null,
        }).then(res => {
            if (res.data.success === 1) {
                setAvailableBonus({
                    maxPercentage: res.data.data.bonus.discount_max_amount,
                    maxDiscount: res.data.data.bonus.discount,
                    hasDiscount: res.data.data.bonus.has_discount,
                });
            }
            setLoading(false);
            console.log(res);
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        if (availableBonus === undefined && props.type !== "bonus_driving") {
            checkStudentBonus();
        } else if (availableBonus === undefined && props.type === "bonus_driving") {
            setAvailableBonus(props.externalSetting);
        }
    }, [props.amount]);

    useEffect(() => {
        http.get('balance').then((response: any) => {
            setBonusBalance(response.data.data.bonus_balance);
        })
    }, [])

    return loading
        ? <Skeleton />
        : props.amount > 0
            ? <>
                <Row gutter={[15, 15]}>
                    <Col span={24} md={12}>
                        <Row style={{ padding: "14px 0", flexWrap: "nowrap" }} align="middle">
                            <img style={{ width: 20, height: 20 }} src={CoinImage} alt="Einstein coins" />
                            <Col style={{ paddingLeft: 12 }}>
                                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                                    <span style={{ whiteSpace: "nowrap" }}>{t('bonus.pay_with_einsteins')}: </span>
                                    {/* <Tooltip placement="top" title={"comment"}>
                                    <InfoCircleFilled style={{ color: "#A1ABB3", marginLeft: 4 }} />
                                </Tooltip> */}
                                </div>

                                <h4 style={{ whiteSpace: "nowrap" }}>{t('common.in_your_account')} <span style={{ fontWeight: "bold" }}>{bonusBalance}</span> Einštein{props.student?.bonus_balance === 1 ? "s" : "u"} </h4>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} md={12}>
                        <Row style={{ flexWrap: "nowrap", padding: "14px 0" }}>
                            <Button
                                type="primary"
                                onClick={() => props.setBonusAmount(
                                    Number(props.bonusAmount) - 1 < Number(minimalBonusAmount)
                                        ? String(minimalBonusAmount)
                                        : String((Number(props.bonusAmount) - 1).toFixed(2))
                                )}
                                style={{ ...styles.numberInputBtn }}
                            >
                                -
                            </Button>
                            <Input
                                value={props.bonusAmount}
                                type="number"
                                min={minimalBonusAmount}
                                max={availableBonus?.maxDiscount}
                                onChange={(e) => {
                                    if (Number(e.target.value) > availableBonus?.maxDiscount) {
                                        props.setBonusAmount(String(availableBonus?.maxDiscount));
                                    } else if (Number(e.target.value) < Number(minimalBonusAmount)) {
                                        props.setBonusAmount(minimalBonusAmount);
                                    } else {
                                        props.setBonusAmount(e.target.value);
                                    }
                                }}
                                style={{ textAlign: "center", margin: "0 10px", width: 116 }}
                            />
                            <Button
                                type="primary"
                                onClick={() => props.setBonusAmount(
                                    (Number(props.bonusAmount) + 1 > availableBonus?.maxDiscount)
                                        ? String(Number(availableBonus?.maxDiscount).toFixed(2))
                                        : String(Number(props.bonusAmount) + 1)
                                )}
                                style={{ ...styles.numberInputBtn }}
                            >
                                +
                            </Button>
                        </Row>
                    </Col >
                </Row >
                <Tag color="rgba(230, 129, 46, 0.10)" style={{ marginTop: 10, marginBottom: 10, whiteSpace: "normal", color: "#E6812E", borderColor: "#E6812E" }}><InfoCircleFilled style={{ color: "#E6812E", marginRight: 4 }} />{t('bonus.percentage_could_be_used', { percentage: availableBonus?.maxPercentage })}</Tag>
            </>
            : <></>
}