import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const SeventhStep = (props: any) => {
    const { t } = useTranslation();
    const baseUrl = process.env.REACT_APP_API_URL;

    return <div>
        <h1>{t('tutorial.step_seven.school_theory_exam')}</h1>
        <p>{t('tutorial.step_seven.theory_exam_platform')}</p>
        <p>{t('tutorial.step_seven.school_theory_exam_explanation')}</p>
        <div style={{ width: "100%" }}>
            <img src={baseUrl + "storage/student-tutorial/theory-exam-view-tutorial.png"} style={{ width: "100%" }} />
        </div>
    </div>
}