import { Card, Collapse, Layout, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { http } from "../../helpers/http";
import { BookmarkTitleCard } from "./BookmarkTitleCard";
import { ElearningHeader } from "./components/ElearningHeader";

export const BookmarksView = (props: any) => {
    const { t } = useTranslation();
    const { self } = props;

    const [bookmarks, setBookmarks] = useState(undefined as any);
    const [loading, setLoading] = useState(true);

    const fetchBookmarks = async () => {
        await http.get(`/bookmarks/${self.id}`).then((response: any) => {
            const bookmarks = Object.keys(response.data.data).map((key: any) => {
                return response.data.data[key]
            })
            //console.log(bookmarks)
            setBookmarks(bookmarks)
        })
    };

    useEffect(() => {
        fetchBookmarks();
    }, []);

    useEffect(() => {
        if (bookmarks !== undefined) {
            setLoading(false)
        }
    }, [bookmarks])

    return loading
        ? <Skeleton />
        : <Layout className="main-content">
            <ElearningHeader />
            <h2>{t('e_learning.my_bookmarks')}</h2>
            <Card className="e-learning-card">
                {
                    bookmarks.map((bookmark: any, index: any) => {
                        return <BookmarkTitleCard
                            self={self}
                            bookmark={bookmark}
                            key={index}
                            refreshBookmarks={fetchBookmarks}
                        />
                    })
                }
            </Card>
        </Layout>
}