import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";

export const ExamEndCard = (props: any) => {
    const { t } = useTranslation();
    const { questions, examData, reviewAnswers, module, endTime, mobile } = props;

    const history = useHistory();

    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [correctStats, setCorrectStats] = useState({
        percentage: undefined as any,
        mistakes: undefined as any,
    });
    const [nextUrl, setNextUrl] = useState("" as string);

    const setModulePassed = async () => {
        await http.post(`/set-passed-module/${module.hash_id}`).then((response: any) => {
            const { data } = response;
            //console.log(data[0])
            // setNextUrl(`/e-learning/${module.category_id}/${data[0]?.next_module}/${data[0]?.next_chapter}`)
            setNextUrl(`/e-learning`)
        })
    }

    const resetModule = () => {
        http.post(`/reset-module/${module.hash_id}`)
    }

    useEffect(() => {
        //console.log(nextUrl)
    }, [nextUrl])

    useEffect(() => {
        setCorrectAnswers(() => {
            const correctCount = questions.filter((question: any) => {
                return question.answer_id === question.correct_id;
            });
            return correctCount.length
        })
    }, [questions]);

    useEffect(() => {
        //console.log(correctAnswers, questions.length, module, correctAnswers)
        setCorrectStats({
            percentage: (((questions.length - module.exam_max_errors) / questions.length) * 100).toFixed(0) + "%",
            mistakes: questions.length - correctAnswers
        });
    }, [correctAnswers, questions]);

    useEffect(() => {
        if (examData.passed === 1) {
            setModulePassed();
        }
        if (examData.passed === 0) {
            if (module?.attempts_left - 1 === 0) {
                resetModule();
            }
        }
    }, [examData])

    return <Row className="exam-end-card" justify="center">
        <Col lg={8}>
            <h1>{t('e_learning.exam_is_ended')}</h1>
            <p>{t('e_learning.exam_time_left')}: {endTime}</p>
            <Row style={{ borderBottom: "1px solid #F0F2F5", padding: "34px 0 48px", marginBottom: 40 }}>
                <Col span={24} sm={12}>
                    <div className="questions-count-wrap" style={{ marginRight: mobile ? 0 : 12 }}>
                        <p>{t('e_learning.questions_count')}</p>
                        <b>{questions.length}</b>
                    </div>
                </Col>
                <Col span={24} sm={12}>
                    <div className="questions-count-wrap" style={{ marginLeft: mobile ? 0 : 12 }}>
                        <p>{t('e_learning.correct_answers')}</p>
                        <b>{correctAnswers}</b>
                    </div>
                </Col>
            </Row>
            <h2 className={`big-text ${examData.passed ? 'green-text' : 'orange-text'}`}>
                {t('e_learning.test')} {examData.passed ? t('e_learning.successfully') : t('e_learning.not')} {t('e_learning.passed')}
            </h2>
            <p>{t('e_learning.to_success_test_you_need')} {correctStats.percentage} {t('e_learning.questions_2')}. {t('e_learning.in_this_test_you_have_errors')} {correctStats.mistakes} {correctStats.mistakes === 1 ? t('errors.error') : t('errors.errors')}.</p>
            {
                examData.passed
                    ? <></>
                    : <p>{t('e_learning.attempts_left')} <b>{module?.attempts_left - 1}</b> {t('e_learning.from')} <b>{module?.exam_attempts}</b></p>
            }
            {/* {
                module.attempts_left - 1 === 0
                && <Button>{t('e_learning.start_module_from_begining')}</Button>
            } */}
            {
                questions.length === correctAnswers
                    ? <></>
                    : <button className="review-link" onClick={reviewAnswers}>{t('e_learning.check_incorrect_answers')}</button>
            }
            <Row>
                {
                    examData.passed
                        ? <Col span={24}>
                            <div onClick={() => history.push(nextUrl)} className="btn-submit">{t('e_learning.go_to_next_module')}</div>
                        </Col>
                        : <>
                            <Col span={module?.attempts_left - 1 === 0 ? 24 : 12}>
                                <div onClick={() => history.push(`/e-learning`)} className="btn-submit" style={{ marginRight: module?.attempts_left - 1 === 0 ? 0 : 12 }}>{t('e_learning.repeat_module')}</div>
                            </Col>
                            {
                                module?.attempts_left - 1 > 0
                                    ? <Col span={12}>
                                        <div onClick={() => history.push(`/e-learning/exam/${module.hash_id}`)} className="btn-submit" style={{ marginLeft: 12 }}>{t('e_learning.retry_test')}</div>
                                    </Col>
                                    : <></>
                            }

                        </>
                }
            </Row>
        </Col>
    </Row>
}